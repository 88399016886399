<template>
  <GenericModal :outside-click='handleCancel' :open='open' :handleClose='handleCancel' :handleSave='handleConfirm'>
    <template v-slot:title>{{ $ct('title') }}</template>
    <template v-slot:subtitle>
      <v-card-subtitle>{{ $ct('subtitle') }}</v-card-subtitle>
    </template>
    <template v-slot:content>
      <v-text-field
        :label='$ct("runtitle")'
        type='text'
        v-model='title'
      />
      <v-select
        :items='projects'
        item-text='title'
        item-value='id'
        :label='$ct("projectLabel")'
        :placeholder='$ct("projectPlaceholder")'
        :rules='[inputRules.required]'
        v-model='selected'
      ></v-select>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/Modals/GenericModal.vue';
import inputRules from '@/libs/inputRules';

export default {
  name: 'ProjectSelector',
  components: { GenericModal },
  props: {
    projects: {
      type: Array,
      required: true
    },
    open: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    inputRules: inputRules(this),
    title: '',
    selected: null
  }),
  methods: {
    handleConfirm() {
      this.$emit('confirm', { title: this.title, projectId: this.selected })
      this.selected = null
    },
    handleCancel() {
      this.title = '';
      this.selected = null;
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>
