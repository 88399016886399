// eslint-disable-next-line import/no-extraneous-dependencies
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

// gray #f8f9fa

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
      },
    theme: {
        options: {
          customProperties: true,
        },
        themes: {
          light: {
            primary: '#d4082c',
            secondary: '#b0bec5',
            anchor: '#d4082c',
            text: {
              base: '#000',
              lighten5: '#fff'
            }
          },
        },
      },
});
