const ComponentTranslate = {
  install(Vue) {
    Vue.mixin({});
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$ct = function ( key, params = undefined ) {
      return this.$i18n.t(`${this.$options.name}.${key}`, params);
    }
  }
}

export default ComponentTranslate
