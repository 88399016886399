<template>
  <v-row>
    <v-col cols='12'>
      <v-select
        :label='$ct("chartView")'
        :items='[
                {
                  text: $t("chartResult.pie"),
                  value: "pie"
                },
                {
                  text: $t("chartResult.radar"),
                  value: "radar"
                }
              ]'
        :value='chartView'
        @change='(val) => chartView = val'/>
    </v-col>
    <v-col cols='12'>
      <PieChart v-if='chartView === "pie"' :images='images' :results='results.results' :isImageResult='isImageResult'/>
      <RadarChart v-else :results='results.results' :isImageResult='isImageResult'/>
    </v-col>
    <v-col cols='12'>
      <FlagChart :results='results' :images='images' :isImageResult='isImageResult'/>
    </v-col>
  </v-row>
</template>

<script>
import RadarChart from '@/components/Graphs/RadarChart.vue';
import PieChart from '@/components/Graphs/PieChart.vue';
import FlagChart from '@/components/Results/FlagChart.vue';
import { pairs } from '@/libs/pairsImageLibrary';

export default {
  name: 'RiasecResultsCharts',
  components: {
    FlagChart,
    PieChart,
    RadarChart
  },
  props: {
    results: {
      required: true
    },
    images: {
      required: true
    },
    isImageResult: {
      required: true
    }
  },
  data: () => ({
    chartView: 'radar'
  }),
  methods: { }
}
</script>

<style scoped>

</style>
