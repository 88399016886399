<!-- Try again and do not subgroup -->
<template>
  <div style='height: 100vh'>
    <LoginClaim/>
    <v-parallax dark src="/kim_splash_login.jpg" height='800'>
      <div class="kipat-login-background-blur"></div>
      <v-container class="kipat-login-container">
        <v-row align="center" align-content="center" justify="center">
          <v-card width='600'>
            <v-tabs
              :value='selectedTab'
              @change='(val) => selectedTab = val'
              centered
            >
              <v-tab v-for='item in tabItems' :key='item'>{{ item }}</v-tab>
            </v-tabs>
            <v-tabs-items :value='selectedTab'>
              <v-tab-item key='0'>
                <v-card flat>
                  <v-form v-model='codeValid' @submit.prevent v-if='selectedTab === 0'>
                    <div v-if='requirement === "none"'>
                      <v-card-title class="justify-center">{{$ct('runCodeTitle')}}</v-card-title>
                      <v-card-text class="px-10"><p>
                        {{$ct('runCodeDescription')}}
                      </p></v-card-text>
                      <v-card-text align="center">
                        <v-text-field
                          v-model="code.Token"
                          :counter="getCounter()"
                          :rules="[(v) => !!v || $t('Rules.required'), (v) => (v && v.length >= 10 && v.length <= 12) || $t('Rules.codeLength')]"
                          :label="$ct('runCode')"
                          required
                          @keyup.enter='codeValid && (recaptchaValue || !disableRecaptcha) ? submit() : ""'
                        ></v-text-field>
                      </v-card-text>
                    </div>
                    <div v-else-if='requirement==="runPassword"'>
                      <v-card-title class='justify-center'>{{$ct('runPasswordTitle')}}</v-card-title>
                      <v-card-text class='px-10'><p>{{$ct('runPasswordDescription')}}</p></v-card-text>
                      <v-card-text align='center'>
                        <v-text-field
                          :type='runPasswordShow ? "text" : "password"'
                          v-model='requirementForm.password'
                          :rules='[inputRules.required]'
                          :label='$ct("runPassword")'
                          prepend-icon='mdi-lock'
                          :append-icon='runPasswordShow ? "mdi-eye-outlined" : "mdi-eye-off-outline"'
                          @click:append='runPasswordShow = !runPasswordShow'
                          required
                          @keyup.enter.prevent='submit()'></v-text-field>
                      </v-card-text>
                    </div>
                    <div v-else-if='requirement==="userLogin"'>
                      <v-card-title class='justify-center'>{{$ct('userLoginTitle')}}</v-card-title>
                      <v-card-text class='px-10'>
                        <p>{{$ct('userLoginDescription')}}</p>
                      </v-card-text>
                      <v-card-text align='center'>
                        <v-text-field
                          :label='$ct("userEmail")'
                          v-model='requirementForm.email'
                          :rules='[inputRules.required, inputRules.email]'
                          prepent-icon='mdi-email'
                        />
                        <v-text-field
                          :type='userPasswordShow ? "text" : "password"'
                          :label='$ct("userPassword")'
                          :value='requirementForm.password'
                          @change='val => $emit("onChange", {type: "requirementForm", password: val})'
                          :rules='[inputRules.required]'
                          prepend-icon='mdi-lock'
                          :append-icon='userPasswordShow ? "mdi-eye-outlined" : "mdi-eye-off-outline"'
                          @click:append='userPasswordShow = !userPasswordShow'
                          @keyup.enter.prevent='submit()'/>
                      </v-card-text>
                    </div>
                    <div v-else-if='requirement==="userToken"'>
                      <v-card-title class='justify-center'>{{$ct('userTokenTitle')}}</v-card-title>
                      <v-card-text class='px-10'><p>{{$ct('userTokenDescription')}}</p></v-card-text>
                      <v-card-text align='center'>
                        <v-text-field
                          v-model='requirementForm.token'
                          :rules='[inputRules.required]'
                          :label='$ct("userToken")'
                          required
                          @keyup.enter.prevent='submit()'></v-text-field>
                      </v-card-text>
                    </div>
                  </v-form>
                </v-card>
              </v-tab-item>
              <v-tab-item key='1'>
                <v-form v-model='loginValid' @submit.prevent v-if='selectedTab === 1'>
                  <div v-if='selectedTab === 1'>
                    <v-card-title class='justify-center'>{{$ct('loginTitle')}}</v-card-title>
                    <v-card-text align='center'>
                      <v-text-field
                        :label='$ct("email")'
                        v-model='login.Email'
                        :rules='[
          (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || $t("Rules.invalidEmail")
        ]'
                        prepend-icon='mdi-email'
                        @keyup.enter.prevent='goToPassword("loginPassword")'
                      />
                      <v-text-field
                        :type='show ? "text" : "password"'
                        :label='$ct("password")'
                        v-model='login.Password'
                        :rules='[inputRules.required]'
                        prepend-icon='mdi-lock'
                        :append-icon='show ? "mdi-eye-outline" : "mdi-eye-off-outline"'
                        @click:append='show = !show'
                        ref='loginPassword'
                        @keyup.enter='submit()'
                      />
                    </v-card-text>
                  </div>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
            <v-card-text align='center'>
              <Recaptcha :disableRecaptcha="disableRecaptcha" @recaptcha='recaptcha'></Recaptcha>
              <v-btn
                v-if="isCookiesAccepted"
                :disabled="(selectedTab === 0 ? !codeValid : !loginValid) || (!recaptchaValue && !disableRecaptcha)"
                color="primary"
                class="my-5"
                @click="submit"
              >
                {{$t('Generic.confirm')}}
              </v-btn>
              <v-btn v-else color="primary" class="my-5" @click="reload">
                {{$t('Generic.acceptCookies')}}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-parallax>
  </div>
</template>

<script>
import LoginComponent from '@/components/Login/LoginComponent.vue';
import { LoginBy, LoginPurpose } from '@/api/RestModels/login';
import ACTIONS from '@/store/storeActions';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import LoginCard from '@/components/Login/LoginCard.vue';
import LoginClaim from '@/components/Login/LoginClaim.vue';
import LoginCode from '@/components/Login/LoginCode.vue';
import Recaptcha from '@/components/Recaptcha.vue';
import LoginForm from '@/components/Login/LoginForm.vue';
import inputRules from '@/libs/inputRules';
import { EventBus, EVENTS } from '@/libs/eventBus';

export default {
  name: 'LoginPage',
  components: {
    LoginClaim, Recaptcha
    // LoginForm, Recaptcha, LoginCode,
    // LoginCard,
    // LoginComponent
  },
  data() {
    return {
      /** @type RunCodeRequestModel */
      code: {
        Token: process.env.VUE_APP_RUNTOKEN
      },
      /** @type LoginRequestModel */
      login: {
        Email: undefined,
        Password: undefined
      },
      show: false,
      userToken: '',
      requirementForm: {},
      recaptchaValue: undefined,
      selectedTab: 0,
      tabItems: ['Durchführungscode', 'Login'],
      loading: false,
      requirement: 'none',
      inputRules: inputRules(this),
      disableRecaptcha: process.env.VUE_APP_DISABLE_RECAPTCHA === 'true',
      loginValid: false,
      codeValid: false,
      requirementsValid: false,
      userPasswordShow: false,
      runPasswordShow: false,
      isCookiesAccepted: this.$cookies.get(process.env.VUE_APP_GDPR_KEY) === 'true',
    };
  },
  computed: {
      // isCookiesAccepted() {
      //   debugger;
      //   return this.$cookies.get(process.env.VUE_APP_GDPR_KEY) === 'true';
      // },
      getRequirementForm() {
      switch (this.requirement) {
        case 'userLogin': return {
          email: '',
          password: ''
        };
        case 'userToken': return {
          token: this.userToken || ''
        };
        case 'runPassword': return {
          password: ''
        };
        case 'none':
        default: return {}
      }
    }
  },
  watch: {
    requirement() {
      this.requirementForm = { ...this.getRequirementForm }
    }
  },
  methods: {
    getCounter() {
      if (!this.code.Code || ![12].includes(this.code.Code.length)) {
        return 12;
      }
      return this.code.Code.length
    },
    goToPassword(ref) {
      if (Array.isArray(this.$refs[ref])) {
        this.$refs[ref][0].focus();
        return;
      }
      this.$refs[ref].focus();
    },
    onChange(val) {
      switch (val.type) {
        case 'login':
          if (val.email) this.login.Email = val.email;
          if (val.password) this.login.Password = val.password;
          break;
        case 'code':
          if (val.token) this.code.Token = val.token;
          break;
        case 'requirementForm':
          if (val.token) this.requirementForm.token = val.token;
          if (val.email) this.requirementForm.email = val.email;
          if (val.password) this.requirementForm.password = val.password;
          break;
        default:
          break;
      }
    },
    reload() {
      // debugger;
      this.$router.go();
    },
    handleRedirectByPurpose(data) {
      const self = this;
      switch (data.loginPurpose) {
        case LoginPurpose.ADMINISTRATION:
          self.$router.push({ name: 'loader', params: { purpose: LoginPurpose.ADMINISTRATION, next: 'dashboard' } })
          break;
        case LoginPurpose.PARTICIPATION:
          if (data.isUserTokenRequired) {
            this.requirement = 'userToken';
            break;
          }
          if (data.isUserLoginRequired) {
            this.requirement = 'userLogin';
            break;
          }
          if (data.isRunPasswordRequired) {
            this.requirement = 'runPassword';
            break;
          }
          self.$router.push({ name: 'loader', params: { purpose: LoginPurpose.PARTICIPATION, next: 'choice' } })
          break;
        default:
          break;
      }
    },
    async handleLoginResponse(data, hasPurpose = false) {
      const self = this;
      if (data?.status && data.status !== 200) {
        // if requirement was set, reset to none
        if (self.requirement !== 'none') {
          self.requirement = 'none';
        }
        // since we restart the login process if an error occured, reset the captcha
        if (window.grecaptcha && window.grecaptcha.reset) {
          window.grecaptcha.reset();
        }
        self.loading = false;
        return;
      }
      if (data.runId) {
        await self.$store.dispatch(ACTIONS.SET_CURRENT_RUN_ID, data.runId)
      }
      self.handleRedirectByPurpose(data);
    },
    submit() {
      const self = this;
      this.loading = true;
      switch (this.requirement) {
        case 'userLogin':
          this.$api.login.userLogin(this.requirementForm)
            .then((data) => {
              self.handleLoginResponse(data);
            })
            .finally(() => {
              self.loading = false
            })
          break;
        case 'userToken':
          this.$api.login.userToken(this.requirementForm).then((data) => {
            self.handleLoginResponse(data)
          })
          .finally(() => {
            self.loading = false
          });
          break;
        case 'runPassword':
          this.$api.login.runPassword(this.requirementForm).then((data) => {
            self.handleLoginResponse(data);
          })
          .finally(() => {
            self.loading = false
          })
          break;
        case 'none':
        default:
          switch (this.selectedTab) {
            case 1:
              this.$api.login.login(LoginBy.PASSWORD, { ...this.login, Recaptcha: this.recaptchaValue || '' })
                .then((data) => {
                  self.handleLoginResponse(data);
                })
                .finally(() => {
                  self.loading = false
                })
              break;
            case 0:
            default:
              this.$api.login.login(LoginBy.RUNCODE, { ...this.code, Recaptcha: this.recaptchaValue || '' })
                .then(async (data) => {
                  self.handleLoginResponse(data);
                })
                .finally(() => {
                  self.loading = false
                })
          }
          break;
      }
    },
    updateTab(value) {
      this.selectedTab = value;
    },
    recaptcha(value) {
      this.recaptchaValue = value;
    }
  },
  mounted() {
    const self = this;
    if (self?.$route?.query?.code) {
      self.code.Token = self.$route.query.code;
    }
    if (self?.$route?.query?.user) {
      self.userToken = self.$route.query.user;
    }
    EventBus.$on(EVENTS.SET_COOKIES, () => {
      self.isCookiesAccepted = self.$cookies.get(process.env.VUE_APP_GDPR_KEY) === 'true';
    })
  },
};
</script>
