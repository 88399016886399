export default (self) => ({
  required: (value) => {
    const error = self.$t('Rules.required')
    switch (typeof value) {
      case 'number':
        return !Number.isNaN(value) || error
      default:
        return !!value || error
    }
  },
  email: (value) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || self.$t('Rules.invalidEmail')
  },
  passwordSafety: (value) => {
    const ucTest = /([A-Z])/gm;
    const lcTest = /([a-z])/gm;
    const numTest = /([0-9])/gm;
    const specialCharTest = /([^A-Za-z0-9])/gm;
    if (value?.length < 8) return self.$t('Rules.password_tooShort')
    if (!ucTest.test(value)) return self.$t('Rules.password_needsUpperCase')
    if (!lcTest.test(value)) return self.$t('Rules.password_needsLowerCase')
    if (!numTest.test(value)) return self.$t('Rules.password_needsNumber')
    if (!specialCharTest.test(value)) return self.$t('Rules.password_needsSpecialChar')
    if (new Set(value).size < 4) return self.$t('Rules.password_needsUniqueChars')
    return true
  },
  // validRunCode: (value) => (value && value.length >= 6 && value.length <= 10) || self.$t('Rules.codeLength')
})
