import LoadingState from '@/api/LoadingState';

const state = {
  loadingState: LoadingState.INITIAL,
  runs: [],
  current: {},
  currentRunId: undefined,
}

export default state
