import ProjectRestModel from '@/api/RestModels/project';
import ACTIONS from '@/store/storeActions';
import LoadingState from '@/api/LoadingState';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';

export const ProjectPayloadTypes = {
  PROJECT_LIST: 'PROJECT_LIST',
  SINGLE_PROJECT: 'SINGLE_PROJECT',
}
export default class Project {
  constructor(store, i18n) {
    this.$store = store;
    this.$i18n = i18n;
  }

  /**
   * @desc Returns either a List of Projects or a specific Project
   * @param {string=} id - If Supplied tries to get the Project for the Id
   * @returns {ProjectObj | ProjectObj[]}
   */
  async get(id ) {
    const payload = {
      type: isStringNullOrWhitespace(id) ? ProjectPayloadTypes.SINGLE_PROJECT : ProjectPayloadTypes.PROJECT_LIST,
      loadingState: LoadingState.INITIAL,
      payload: undefined
    }
    await this.$store.dispatch(ACTIONS.SET_PROJECTS, Object.assign(payload, { loadingState: LoadingState.LOADING }))
    if ( isStringNullOrWhitespace(id) ) {
      try {
        const projects = await ProjectRestModel.get();
        await this.$store.dispatch(ACTIONS.SET_PROJECTS, Object.assign(payload, { payload: projects.payload, loadingState: LoadingState.LOADED }))
        return projects.payload
      } catch (err) {
        await this.$store.dispatch(ACTIONS.SET_PROJECTS, Object.assign(payload, { loadingState: LoadingState.FAILED }));
        return err;
      }
    }
    try {
      const project = await ProjectRestModel.get(id);
      await this.$store.dispatch(ACTIONS.SET_PROJECTS, Object.assign(payload, { payload: project.payload, loadingState: LoadingState.LOADED }))
      return project.payload;
    } catch ( err ) {
      await this.$store.dispatch(ACTIONS.SET_PROJECTS, Object.assign(payload, { loadingState: LoadingState.FAILED }));
      return err;
    }
  }

  /**
   * @desc Creation function for new Projects
   * @param {ProjectObj} payload
   * @returns {ProjectObj | Error}
   */
  async create(payload) {
    await this.$store.dispatch(ACTIONS.CREATE_PROJECT, { loadingState: LoadingState.SAVING })
    try {
      const result = await ProjectRestModel.create(payload);
      await this.$store.dispatch(ACTIONS.CREATE_PROJECT, { loadingState: LoadingState.LOADED, payload: result.payload })
      await this.$store.dispatch(ACTIONS.SET_SNACKBAR, { text: `${this.$i18n.t('Generic.success')}`, color: 'success' })
      return result.payload
    } catch (err) {
      await this.$store.dispatch(ACTIONS.CREATE_PROJECT, { loadingState: LoadingState.FAILED })
      console.error(err);
      return err
    }
  }

  /**
   * @desc Update function for existing Projects
   * @param {ProjectObj} payload
   * @returns {ProjectObj | Error}
   */
  async update(payload) {
    await this.$store.dispatch(ACTIONS.UPDATE_PROJECT, { loadingState: LoadingState.SAVING })
    try {
      const result = await ProjectRestModel.update(payload);
      await this.$store.dispatch(ACTIONS.UPDATE_PROJECT, { loadingState: LoadingState.LOADED, payload: result.payload })
      await this.$store.dispatch(ACTIONS.SET_SNACKBAR, { text: `${this.$i18n.t('Generic.success')}`, color: 'success' })
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.UPDATE_PROJECT, { loadingState: LoadingState.FAILED })
      console.error(err);
      return err;
    }
  }

  async updatePassword(payload) {
    await this.$store.dispatch(ACTIONS.UPDATE_PROJECT, { loadingState: LoadingState.SAVING })
    try {
      const result = await ProjectRestModel.updatePassword(payload);
      await this.$store.dispatch(ACTIONS.UPDATE_PROJECT_PASSWORD, { loadingState: LoadingState.LOADED, payload: result.payload })
      await this.$store.dispatch(ACTIONS.SET_SNACKBAR, { text: `${this.$i18n.t('Generic.success')}`, color: 'success' })
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.UPDATE_PROJECT, { loadingState: LoadingState.FAILED })
      return err;
    }
  }

  async updateStatus(itemId, newStatus) {
    await this.$store.dispatch(ACTIONS.UPDATE_PROJECT, { loadingState: LoadingState.SAVING })
    try {
      const result = await ProjectRestModel.updateStatus(itemId, newStatus);
      await this.$store.dispatch(ACTIONS.UPDATE_PROJECT, { loadingState: LoadingState.LOADED, payload: result.payload })
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.UPDATE_PROJECT, { loadingState: LoadingState.FAILED })
      return err;
    }
  }

  async triggerDelete(itemId) {
    await this.$store.dispatch(ACTIONS.DELETE_PROJECT, { loadingState: LoadingState.UPDATING })
    try {
      const result = await ProjectRestModel.triggerDelete(itemId);
      await this.get()
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.DELETE_PROJECT, { loadingState: LoadingState.FAILED })
      return err;
    }
  }
}
