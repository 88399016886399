<template>
  <v-container>
    <v-skeleton-loader v-if='typeof run !== "object" && !loaded'></v-skeleton-loader>
    <template v-else>
      <v-row >
        <v-col cols='12'>
          <v-card>
            <v-card-title>{{ $ct('currentRunData') }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols='6' >
                  <v-data-table
                    hide-default-header
                    hide-default-footer
                    :items-per-page='-1'
                    :headers='generalInformationHeader()'
                    :items='generalInformation(0)'
                    :locale='$i18n.locale'
                  >
                    <template v-slot:item.description='{item}'>
                      <span class='font-weight-bold'>{{ item.description }}</span>
                    </template>

                  </v-data-table>
                </v-col>
                <v-col cols='6'>
                  <v-data-table
                    hide-default-header
                    hide-default-footer
                    :items-per-page='-1'
                    :headers='generalInformationHeader()'
                    :items='generalInformation(1)'
                    :locale='$i18n.locale'
                  >
                    <template v-slot:item.description='{item}'>
                      <span class='font-weight-bold'>{{ item.description }}</span>
                    </template>

                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
            <template v-if='run.project?.participantType === "TOKEN"'>
              <v-card-title>
                <v-row justify='space-between' align='center'>
                  <v-col cols='8'>{{ $ct('existingParticipantTokens') }}</v-col>
                  <v-col class='flex-grow-0'>
                    <v-btn @click='addToken()'>
                      <v-icon>mdi-plus</v-icon>{{$t('Generic.addToList')}}</v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols='12'>
                    <v-data-table
                      :hide-default-footer='run.participantTokens.length <= 10'
                      :headers='[
                      {
                        text: $ct("actions.title"),
                        align: "start",
                        value: "actions",
                        width: "200",
                        filterable: false,
                        sortable: false
                      },{
                        text: $ct("token"),
                        align: "start",
                        value:"token"
                      },{
                        text: $ct("enabled"),
                        align: "start",
                        value:"enabled"
                      }, {
                        text: $ct("instanceStatus"),
                        align: "start",
                        value:"completed"
                      }
                    ]'
                      :items='run?.participantTokens'
                    >
                      <template v-slot:item.enabled='{item}'>
                        <boolean-icon-table-field :enabled='item.enabled'/>
                      </template>
                      <template v-slot:item.actions='{item}'>
                        <template v-if='item.enabled'>
                          <v-tooltip top>
                            <template v-slot:activator='{on, attrs}'>
                              <v-icon v-bind='attrs' v-on='on' class='mr-2' @click.prevent.stop='renewToken(item.token)'>mdi-autorenew</v-icon>
                            </template>
                            <span>{{ $ct('actions.renew') }}</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator='{on, attrs}'>
                              <v-icon v-bind='attrs' v-on='on' @click.prevent.stop='disableToken(item.token)'>mdi-circle-off-outline</v-icon>
                            </template>
                            <span>{{ $ct('actions.disable') }}</span>
                          </v-tooltip>
                        </template>
                        <v-tooltip top v-if='!item.enabled'>
                          <template v-slot:activator='{on, attrs}'>
                            <v-icon v-bind='attrs' v-on='on' @click.prevent.stop='reactivateToken(item.token)'>mdi-backup-restore</v-icon>
                          </template>
                          <span>{{ $ct('actions.reactivate') }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <!--                        todo - Add a Modal to Confirm the removal of the Token, dont delete directly -->
                          <template v-slot:activator='{on, attrs}'>
                            <v-icon v-bind='attrs' v-on='on' class='ml-2' @click.prevent.stop='deleteToken(item.token)'>mdi-delete</v-icon>
                          </template>
                          <span>{{ $ct('actions.delete') }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.completed='{item}'>
                        <boolean-icon-table-field :enabled='item.completed'/>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
            <template v-if='run.project?.participantType === "USER"'>
              <v-card-title>
                {{ $ct('existingUsers') }}
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols='12'>

                  </v-col>
                </v-row>
              </v-card-text>
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if='run.project.participantType === "USER"'>
        <v-col cols='12'>
          <v-card>
            <v-card-text>
              <!-- Allow Participant Invite -->
              <v-row>
                <v-col cols='12'>
                  {{$ct("addNewUser")}}
                </v-col>
                <v-col cols='12'>
                  <v-form v-model="userValid" ref='userForm' :disabled='runUsers.length === run.project.participants'>
                    <v-row>
                      <v-col cols='4'>
                        <v-text-field
                          :label='$ct("firstName")'
                          type='text'
                          v-model='user.firstName'
                          :rules='[inputRules.required]'
                          @keyup.enter='$refs.lastName.focus()'
                        />
                      </v-col>
                      <v-col cols='4'>
                        <v-text-field
                          :label='$ct("lastName")'
                          type='text'
                          ref='lastName'
                          v-model='user.lastName'
                          :rules='[inputRules.required]'
                          @keyup.enter='$refs.email.focus()'
                        />
                      </v-col>
                      <v-col cols='4'>
                        <v-text-field
                          :label='$ct("email")'
                          type='email'
                          ref='email'
                          v-model='user.email'
                          :rules='[inputRules.required, inputRules.email]'
                          @keyup.enter='addToUsers()'
                        />
                      </v-col>
                      <v-col cols='12' class='d-flex justify-end'>
                        <v-btn @click.prevent='refresh()'>{{$t('Generic.reset')}}</v-btn>
                        <v-btn @click.prevent='addToUsers()'>{{$t('Generic.addToList')}}</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
                <v-col cols='12'>
                  {{$ct("userList")}}
                </v-col>
                <v-col cols='12'>
                  <v-data-table
                    :headers='[
                    {text: $ct("actions.title"), value: "actions", align: "start", filterable: false, sortable: false},
                    {text: $ct("firstName"), value: "firstName", align: "start"},
                    {text: $ct("lastName"), value: "lastName", align: "start"},
                    {text: $ct("email"), value: "email", align: "start"}
                  ]'
                    hide-default-footer
                    :items='runUsers'>
                    <template v-slot:item.actions='{item}'>
                      <v-tooltip top>
                        <template v-slot:activator='{on, attrs}'>
                          <v-icon v-bind='attrs' v-on='on' @click.prevent.stop='removeUser(item)'>mdi-delete-outline</v-icon>
                        </template>
                        <span>{{ $ct('actions.remove') }}</span>
                      </v-tooltip>
                      <v-tooltip top v-if='item.id'>
                        <template v-slot:activator='{on, attrs}'>
                          <v-icon v-bind='attrs' v-on='on' @click.prevent.stop='reinvite(item.id)'>mdi-email-sync-outline</v-icon>
                        </template>
                      </v-tooltip>
                      {{ item.actions }}
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols='12' class='d-flex justify-end'>
                  <v-btn @click.prevent='save()'>{{$t('Generic.save')}}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import inputRules from '@/libs/inputRules';
import BooleanIconTableField from '@/components/Generics/BooleanIconTableField.vue';
import { TokenAction } from '@/libs/constants';
import { mapGetters, mapState } from 'vuex';
import LoadingState from '@/api/LoadingState';

export default {
  name: 'RunEditor',
  components: { BooleanIconTableField },
  computed: {
    ...mapState({
      run: (state) => state.runs.current,
      loading: (state) => state.runs.loadingState === LoadingState.LOADING
    }),
    defaultUser() {
      return {
        firstName: '',
        lastName: '',
        email: ''
      }
    }
  },
  data() {
    return {
      inputRules: inputRules(this),
      additionalRules: {
        passwordIdentical: (value) => value === this.password?.new || this.$t('Rules.password_notIdentical')
      },
      runUsers: [],
      setOrChangePassword: false,
      passwordValid: true,
      password: {
        old: '',
        new: '',
        confirm: ''
      },
      loaded: false,
      userValid: true,
      user: {
        firstName: '',
        lastName: '',
        email: '',
      }
    }
  },
  methods: {
    generalInformationHeader() {
      return [
        {
          text: 'description', align: 'start', value: 'description'
        },
        {
          text: 'information', align: 'start', value: 'info'
        }
      ]
    },
    generalInformation(partial) {
      const format = this.$t('Generic.dateformat').toString()
      const info = [
        {
          description: this.$ct('runToken'),
          info: this.run.runToken,
        }, {
          description: this.$ct('runStatus'),
          info: this.$t(`Run.${this.run.status}`),
        }, {
          description: this.$ct('created'),
          info: dayjs(this.run.createdOn).format(format),
        }, {
          description: this.$ct('lastUpdate'),
          info: dayjs(this.run.updatedOn).format(format),
        },
        {
          description: this.$ct('projectVersion'),
          info: this.run.project.version
        },
        {
          description: this.$ct('passwordProtected'),
          info: !isStringNullOrWhitespace(this.run.project?.password) ? this.$t('Generic.Yes') : this.$t('Generic.No')
        },
      ];
      if (this.run?.project?.participantType) {
        info.push(
          {
            description: this.$ct('participantType'),
            info: this.$t(`ProjectSettings.participantTypeOptions.${this.run.project?.participantType}`)
          }
        )
        if (this.run.project?.participantType !== 'ANONYMOUS') {
          info.push({
            description: this.$ct('participantCount'),
            info: `( ${this.run.project.participants} | ${this.run.project.participantType === 'TOKEN' ? this.run.participantTokens.length : this.run.participantList.length} )`
          })
          if (this.run.project.participantType === 'TOKEN') {
            info.push({
              description: this.$ct('instances-not-started'),
              info: `${this.run?.participantTokens.length - (this.run?.completedInstances || 0) - (this.run?.openInstances || 0)}`
            })
          } else {
            info.push({
              description: this.$ct('instances-not-started'),
              info: `${this.run?.participantList.length - (this.run?.completedInstances || 0) - (this.run?.openInstances || 0)}`
            })
          }
          info.push(...[
            {
              description: this.$ct('instances-open'),
              info: `${this.run.openInstances || 0}`
            },
            {
              description: this.$ct('instances-completed'),
              info: `${this.run.completedInstances || 0}`
            }
          ]);
        }
        if (this.run.project?.participantType === 'ANONYMOUS') {
          info.push({
            description: this.$ct('instances-completed'),
            info: `${this.run?.completedInstances || 0}`
          })
        }
      }
      // if info is odd return more on partial 0 than on partial 1
      if (partial === 0) return info.slice(0, Math.round(info.length / 2));
      return info.slice(Math.round(info.length / 2), info.length)
    },
    participantTypeOptions() {
      return [
        {
          text: this.$t('ProjectSettings.participantTypeOptions.ANONYMOUS'),
          value: 'ANONYMOUS'
        },
        {
          text: this.$t('ProjectSettings.participantTypeOptions.TOKEN'),
          value: 'TOKEN'
        },
        {
          text: this.$t('ProjectSettings.participantTypeOptions.USER'),
          value: 'USER'
        }
      ]
    },
    async renewToken(token) {
      const self = this;
      await self.$api.run.changeTokenStatus({ token, runId: this.run.id, action: TokenAction.RENEW })
    },
    async disableToken(token) {
      const self = this;
      await self.$api.run.changeTokenStatus({ token, runId: this.run.id, action: TokenAction.DISABLE })
    },
    async reactivateToken(token) {
      const self = this;
      await self.$api.run.changeTokenStatus({ token, runId: this.run.id, action: TokenAction.REACTIVATE })
    },
    async addToken() {
      const self = this;
      await self.$api.run.addToken({ runId: this.run.id })
    },
    async deleteToken(token) {
      const self = this;
      await self.$api.run.deleteToken({ token, runId: this.run.id })
    },
    addToUsers() {
      if (this.$refs.userForm.validate()) {
        this.runUsers.push(this.user)
        this.user = { ...this.defaultUser };
        this.$refs.userForm.reset();
      }
    },
    refresh() {
      this.$refs.userForm.reset();
    },
    removeUser(user) {
      const userIndex = this.runUsers.findIndex((x) => x.email === user.email)
      if (userIndex > -1) this.runUsers.splice(userIndex, 1);
    },
    async reinvite(userId) {
      const self = this;
      await self.$api.run.reinviteUser(userId);
    },
    async save() {
      const self = this;
      const update = {
        id: this.run.id,
        runUsers: this.runUsers
      }
      await self.$api.run.update(update)
    },
  },
}
</script>

<style scoped>

</style>
