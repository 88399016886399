<template>
  <v-list>
    <template v-for='item in navigation'>
      <NavigationList v-if='item.items' :key='item.key' :value='item'/>
      <NavigationItem v-else :key='item.key' :value='item'/>
    </template>
  </v-list>

</template>

<script>
import navigation from '@/components/Navigation/_navigation';
import NavigationList from '@/components/Navigation/NavigationList.vue';
import NavigationItem from '@/components/Navigation/NavigationItem.vue';

export default {
  name: 'NavigationMenu',
  methods: { },
  components: {
    NavigationItem,
    NavigationList,
  },
  data() {
    return {
      navigation
    }
  },
}
</script>

<style scoped>

</style>
