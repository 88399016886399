<template>
  <div>
    <div ref='plot'></div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import Plotly from 'plotly.js-dist';
// eslint-disable-next-line import/no-extraneous-dependencies
import plotlyLocaleDE from 'plotly.js-locales/de';
import BusinessLogicResults from '@/libs/businessLogicResults';

Plotly.register(plotlyLocaleDE);
export default {
  name: 'BarChart',
  components: {},
  computed: {
  },
  props: {
    values: Array,
    results: Array,
  },
  data: () => ({
      horizontal: true
  }),
  watch: {},
  methods: {
    generatePlot() {
      const self = this;
      const data = [];
      const { result, min, max } = this.logic.mapResults(this.results);
      data.push({
        x: result.map((x) => x.result),
        y: result.map((x) => (x.choice || x.choiceKey)),
        type: 'bar',
        orientation: 'h'
      })
      Plotly.newPlot(
        this.$refs.plot,
        data,
        {
          //title: 'Paarvergleichsergebnis',
          yaxis: {
            automargin: true
          }
        },
        {
          displayModeBar: false
        }
      )
    }
  },
  created() {
    Plotly.setPlotConfig({ locale: this.$i18n.locale })
  },
  mounted() {
    this.logic = new BusinessLogicResults(this);
    this.generatePlot()
  }

}
</script>

<style scoped>

</style>
