<template>
  <svg version="1.1" id="kibit-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 190 65.08" enable-background="new 0 0 190 65.08" xml:space="preserve">
    <path d="M137.252024,23.0982231 C138.611846,22.8675222 141.104541,22.6349457 143.372162,22.6349457 C146.318756,22.6349457 148.132478,22.8656466 149.71925,23.7903258 C151.306022,24.484304 152.211945,26.1029615 152.211945,27.9523198 C152.211945,29.8016782 151.079072,31.6510365 148.811451,32.5775913 L148.811451,32.5775913 C151.304146,33.2715696 153.117868,35.1209279 153.117868,37.8949654 C153.117868,39.9769003 152.211945,41.3629812 150.852122,42.5202369 C149.26535,43.675617 146.772655,44.3695953 142.466239,44.3695953 C140.200494,44.3695953 138.386772,44.1388944 137.253899,44.1388944 L137.253899,23.0982231 L137.252024,23.0982231 Z M142.010464,31.4222113 L143.597236,31.4222113 C146.089931,31.4222113 147.449753,30.2668312 147.449753,28.6481737 C147.449753,27.0295163 146.316881,26.1048371 144.04926,26.1048371 C142.916387,26.1048371 142.462488,26.1048371 142.008588,26.335538 L142.008588,31.4222113 L142.010464,31.4222113 Z M142.010464,41.134156 C142.464363,41.134156 143.143337,41.134156 143.824186,41.134156 C146.089931,41.134156 147.903653,40.2094768 147.903653,37.8968411 C147.903653,35.8149062 146.089931,34.890227 143.597236,34.890227 L142.010464,34.890227 L142.010464,41.134156 L142.010464,41.134156 Z" id="Shape" fill="#1A1B19"></path>
    <polygon id="Path" fill="#1A1B19" points="165.2231 22.6349457 165.2231 44.3695953 159.918855 44.3695953 159.918855 22.6349457"></polygon>
    <polygon id="Path" fill="#1A1B19" points="114.105035 22.6349457 114.105035 44.3695953 109.031491 44.3695953 109.031491 22.6349457"></polygon>
    <path d="M83.7538006,22.5805528 L88.5403751,22.5805528 L88.5403751,32.0074038 L88.5403751,32.0074038 C88.99615,31.0883514 89.451925,30.3981244 90.1365252,29.7078973 L94.9230997,22.5805528 L100.850049,22.5805528 L93.7846002,31.7785785 L101.078875,44.1951629 L95.3807502,44.1951629 L90.1384008,34.9971372 L88.3153011,37.2966436 L88.3153011,44.4239882 L83.5287266,44.4239882 L83.5287266,22.5805528 L83.7538006,22.5805528 Z" id="Path" fill="#1A1B19"></path>
    <path d="M124.48845,41.6199408 C124.48845,40.0913129 125.756367,38.5626851 127.339388,38.5626851 C128.922409,38.5626851 130.190326,39.7855874 130.190326,41.6199408 C130.190326,43.1485686 128.922409,44.3714709 127.339388,44.3714709 C125.756367,44.3714709 124.48845,43.1485686 124.48845,41.6199408 Z" id="Path" fill="#1A1B19"></path>
    <g id="Group">
                <path d="M32.5944719,0 C14.5941757,0 0,14.5491609 0,32.4988154 C0,50.4447187 14.5941757,64.9938796 32.5944719,64.9938796 C50.5928924,64.9938796 65.1851925,50.442843 65.1851925,32.4988154 C65.1851925,14.5491609 50.5928924,0 32.5944719,0 M12.7823297,39.3823297 L25.3095755,9.65755183 L30.6475814,22.2504442 L18.11846,51.9695953 L12.7823297,39.3823297 Z M32.5925962,51.9695953 L27.2564659,39.3823297 L39.7837117,9.65755183 L45.1217177,22.2504442 L32.5925962,51.9695953 Z M47.0686081,51.9695953 L41.7324778,39.3823297 L47.0686081,26.7875617 L52.4047384,39.3823297 L47.0686081,51.9695953 Z" id="Shape" fill="#BBCF0F"></path>
      <polygon id="Path" fill="#1A1B19" points="178.283021 26.7969398 172.134748 26.7969398 172.134748 22.6349457 189.842448 22.6349457 189.842448 26.7969398 183.44847 26.7969398 183.44847 44.3695953 178.283021 44.3695953"></polygon>
            </g>
  </svg>
</template>

<script>
export default {
  name: 'KiBitLogo'
}
</script>
