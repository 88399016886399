<template>
  <div>
    <v-row>
      <v-col cols='12'>
        <h4>{{scaleText}}</h4>
      </v-col>
    </v-row>
    <v-radio-group class='v-radio' :value='value' row @change='(val) => $emit("rating", val)' active-class='isHighlighted'>
      <v-row align='center' justify='center' class='v-row--custom'>
        <v-col
          v-for='scale in scaleValues'
          class='v-radio-group-col--custom'
          :key='`${scale.value}_${scale.label}`'
        >
          <span class='d-none'>{{scale}}</span>
          <v-radio
            :label='scale.label'
            :value='scale.value'
            class='justify-center v-radio-item--custom'>
            <template v-slot:label>
              <div v-if="scale.label === '1' && !nonDefaultRunType" class='v-radio-label--custom'>=</div>
              <div v-else class='v-radio-label--custom'>{{scale.label }}</div>
            </template>
          </v-radio>
        </v-col>
      </v-row>
    </v-radio-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'RatingSelector',
  props: ['value', 'selectedChoice', 'currentChoiceOptions', 'scaleText', 'nonDefaultRunType'],
  data: () => ({
    scaleValues: [
      {
        value: 1,
        label: '1'
      },
      {
        value: 2,
        label: '2'
      },
      {
        value: 3,
        label: '3'
      },
      {
        value: 4,
        label: '4'
      },
      {
        value: 5,
        label: '5'
      },
      {
        value: 6,
        label: '6'
      },
      {
        value: 7,
        label: '7'
      }
    ]
  }),
  computed: {
  }
}
</script>

<style scoped lang='scss'>
  ::v-deep .v-input--selection-controls__input {
    display: none !important;
  }
  .v-radio-group-col--custom {
    display: flex;
    min-height: 4rem;
    padding: 0;
    flex: 1 1 auto;
  }
  .v-radio-item--custom {
    width: 100%;
    border: .1rem solid #ccc;
    background-color: #fff;
    margin: 0!important;
    &.isHighlighted {
      background-color: inherit
    }
  }
  .v-radio-label--custom {
    margin: 0 auto;
  }
  .no-margin {
    margin: 0 !important
  }
  .v-row--custom {
    flex-wrap: nowrap;
    background: linear-gradient(90deg, rgba(222,55,0,1) 0%, rgba(245,139,0,1) 25%, rgba(225,255,0,1) 50%, rgba(146,239,0,1) 75%, rgba(42,161,15,1) 100%);
    //padding: 0 1rem;
  }

</style>
