import ACTIONS from '@/store/storeActions';

const actions = {
  [ACTIONS.SET_PROJECTS]: (context, payload) => context.commit(ACTIONS.SET_PROJECTS, payload),
  [ACTIONS.SET_CURRENT_PROJECT]: (context, payload) => context.commit(ACTIONS.SET_CURRENT_PROJECT, payload),
  [ACTIONS.CREATE_PROJECT]: (context, payload) => { context.commit(ACTIONS.CREATE_PROJECT, payload) },
  [ACTIONS.UPDATE_PROJECT]: (context, payload) => { context.commit(ACTIONS.UPDATE_PROJECT, payload) },
  [ACTIONS.UPDATE_PROJECT_PASSWORD]: (context, payload) => { context.commit(ACTIONS.UPDATE_PROJECT_PASSWORD, payload) },
  [ACTIONS.DELETE_PROJECT]: (context, payload) => { context.commit(ACTIONS.DELETE_PROJECT, payload) },

}

export default actions
