var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":_vm.$t("chartResult.chartView"),"items":[
        {
          text: _vm.$t("chartResult.bar"),
          value: "bar"
        },
        {
          text: _vm.$t("chartResult.radar"),
          value: "radar"
        }
      ],"value":_vm.chartView},on:{"change":(val) => _vm.chartView = val}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.chartView === "radar" && _vm.radarData && _vm.radarData.length > 0)?_c('RadarChart',{attrs:{"multi":true,"data":_vm.radarData,"show-legend":true}}):_vm._e(),(_vm.chartView === "bar")?_c('ResultsChart',{attrs:{"results":_vm.results}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }