import LoginRestModel, { TokenPurpose } from '@/api/RestModels/login';
import ACTIONS from '@/store/storeActions';
import LoadingState from '@/api/LoadingState';

export default class Login {
  constructor(store, i18n) {
    this.$store = store;
    this.$i18n = i18n;
  }

  async login(by, payload) {
    await this.$store.dispatch(ACTIONS.LOGIN, { loadingState: LoadingState.LOADING })
    try {
      const { payload: Payload } = await LoginRestModel.login(by, payload);
      await this.$store.dispatch(ACTIONS.LOGIN, { payload: Payload, loadingState: LoadingState.LOADED });
      return Payload
    } catch (err) {
      await this.$store.dispatch(ACTIONS.LOGIN, { loadingState: LoadingState.FAILED });
      return err;
    }
  }

  async userToken(payload) {
    await this.$store.dispatch(ACTIONS.LOGIN, { loadingState: LoadingState.LOADING })
    try {
      const { payload: Payload } = await LoginRestModel.userTokenLogin({ ...payload, purpose: TokenPurpose.PARTICIPANT });
      await this.$store.dispatch(ACTIONS.LOGIN, { payload: Payload, loadingState: LoadingState.LOADED });
      return Payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.LOGIN, { loadingState: LoadingState.FAILED });
      return err;
    }
  }

  async userLogin(payload) {
    await this.$store.dispatch(ACTIONS.LOGIN, { loadingState: LoadingState.LOADING });
    try {
      const { payload: Payload } = await LoginRestModel.userLogin(payload);
      await this.$store.dispatch(ACTIONS.LOGIN, { payload: Payload, loadingState: LoadingState.LOADED });
      return Payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.LOGIN, { loadingState: LoadingState.FAILED });
      return err
    }
  }

  async runPassword(payload) {
    await this.$store.dispatch(ACTIONS.LOGIN, { loadingState: LoadingState.LOADING });
    try {
      const { payload: Payload } = await LoginRestModel.runPassword(payload);
      await this.$store.dispatch(ACTIONS.LOGIN, { payload: Payload, loadingState: LoadingState.LOADED });
      return Payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.LOGIN, { loadingState: LoadingState.FAILED });
      return err;
    }
  }
}
