<template>
  <div>
    <v-card-title class='justify-center'>{{$ct('loginTitle')}}</v-card-title>
    <v-card-text align='center'>
      <v-text-field
        :label='$ct("email")'
        :value='login.Email'
        @change='val => $emit("onChange", {type: "login", email: val})'
        :rules='[
          (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || $t("Rules.invalidEmail")
        ]'
        prepend-icon='mdi-email'
        @keyup.enter='goToPassword()'
      />
      <v-text-field
        :type='show ? "text" : "password"'
        :label='$ct("password")'
        :value='login.Password'
        @change='val => $emit("onChange", {type: "login", password: val} )'
        :rules='[inputRules.required]'
        prepend-icon='mdi-lock'
        :append-icon='show ? "mdi-eye-outline" : "mdi-eye-off-outline"'
        @click:append='show = !show'
        ref='password'
        @keyup.enter='submit()'
      />
    </v-card-text>
  </div>
</template>

<script>
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import PasswordField from '@/components/Generics/PasswordField.vue';
import inputRules from '@/libs/inputRules';

export default {
  name: 'LoginForm',
  components: { /*PasswordField*/ },
  props: {
    /** @type LoginRequestModel */
    login: Object,
    cookiesAccepted: {
      type: Boolean,
      required: true,
      default: false
    },
    recaptchaValue: {
      type: String
    },
    disableRecaptcha: {
      type: Boolean
    },
    valid: Boolean
  },
  data() {
    return {
      inputRules: inputRules(this),
      show: false,
    }
  },
  methods: {
    goToPassword() {
      this.$refs.password.$refs.password.focus();
    },
    submit() {
      this.$emit('submit')
    }
  }
}
</script>

<style scoped>

</style>
