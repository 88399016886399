<template>
<!--  <div class='flex-container'>
    <div class='flex-img-container' v-for='image in images' :key='image.id'>
      <h6 v-if='displayChoice'>{{ image.choice }}</h6>
      <img class='flex-img' :src='image.reference' :alt='image.text'/>
      <h6 v-if='displayChoice'>{{ image.choice }}</h6>
    </div>
  </div>-->
  <v-container>
    <v-row justify='center'>
      <v-col cols='12' align='center'>
        <h2>{{ $ct('imageSelection') }}</h2>
      </v-col>
    </v-row>
    <v-row justify='center' class='py-4 flex-container'>
      <v-col class='flex-img-container' v-for='image in images' :key='image.id' align='center'>
        <!--      <v-img class='flex-img' :src='image.reference' contain/>-->
        <img class='flex-img' :src='image.reference' :alt='image.choice'/>
        <h6 v-if='displayChoice'>{{ image.choice }}</h6>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ImageGalery',
  props: {
    images: {
      type: Array,
      default: () => []
    },
    displayChoice: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang='scss' scoped>
.flex-container {
  display: flex;
  flex-flow: row nowrap;
  gap: 2rem;
}
.flex-img-container {
  max-width: 200px;
  max-height: 200px;
}
.flex-img{
  max-width: 100%;
  max-height: 100%;
}
//.imageContainer {
//  max-height: 200px;
//  max-width: 200px;
//}
.img {
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
