// eslint-disable-next-line import/prefer-default-export
const navigation = () => {
  const nav = [
    {
      icon: 'mdi-view-dashboard-outline',
      key: 'adminDashboard',
      route: 'dashboard'
    },
    {
      icon: 'mdi-folder-cog-outline',
      key: 'adminProject',
      route: 'projectHome'
    },
    {
      icon: 'mdi-play-box-outline',
      key: 'adminRun',
      route: 'runHome'
    },
  ];
  if (process.env.VUE_APP_FEATURE_USERADMINISTRATION === 'true' || process.env.VUE_APP_FEATURE_THEMECONFIGURATOR === 'true') {
    nav.push({
      icon: 'mdi-cog-outline',
      key: 'adminSettings',
      items: []
    })
    if (process.env.VUE_APP_FEATURE_USERADMINISTRATION === 'true') {
      nav[nav.length - 1].items.push({
        icon: 'mdi-account-group-outline',
        key: 'adminUserEditor',
        route: 'users'
      })
    }
    if (process.env.VUE_APP_FEATURE_THEMECONFIGURATOR === 'true') {
      nav[nav.length - 1].items.push({
        icon: 'mdi-palette-outline',
        key: 'adminThemeEditor',
        route: 'themeEditor'
      })
    }
  }
  return nav
}

export default navigation();
