<template>
  <v-container>
    <EditorHeader
      :title='$route.params.id ? $ct("EditHeader") : $ct("CreateHeader")'
      class='pb-4'>
      <template v-slot:actions>
        <v-btn @click='$emit("save", tmpObj)'>
          {{ $t('Generic.save' )}}
        </v-btn>
      </template>
    </EditorHeader>
    <UserEditor :user='tmpObj' @update='update'/>
  </v-container>
</template>

<script>
import UserEditor from '@/components/Settings/Users/UserEditor.vue';
import EditorHeader from '@/components/Generics/EditorHeader.vue';

export default {
  name: 'UserCreateOrEdit',
  components: { UserEditor, EditorHeader },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: true
    },
    /** @type AppUser */
    user: {
      type: Object
    }
  },
  data: () => ({
    tmpObj: {}
  }),
  methods: {
    update(props) {
      const {
        field, fieldType, index, value
      } = props;

      switch ( fieldType ) {
        case 'Array':
          this.tmpObj[field][index] = value
          break;
        default:
          this.tmpObj[field] = value
      }
    },
  },
  mounted() {
    this.tmpObj = { ...this.user };
  }
}
</script>

<style scoped>

</style>
