<template>
  <v-container>
    <EditorHeader
      :title='$route.params.id ? $ct("EditHeader") : $ct("CreateHeader")'
      class='pb-4'>
    </EditorHeader>
    <RunEditor/>
  </v-container>
</template>

<script>
import EditorHeader from '@/components/Generics/EditorHeader.vue';
import RunEditor from '@/components/Runs/RunEditor.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'RunCreateOrEdit',
  components: { EditorHeader, RunEditor },
}
</script>

<style scoped>

</style>
