<template>
  <v-container>
    <EditorHeader
      :title='$ct($route.params.id ? "edit": "create")'
      class='pb-4'
    />
    <ScaleEditor :scale='scale' @update='update' />
  </v-container>
</template>
<script>
export default {
  name: 'ScalesCreateOrEditPage'
}
</script>
<style scoped>

</style>
