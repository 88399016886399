import { ResultsViews } from '@/libs/constants';

const emptyProject = () => ({
    id: '',
    title: '',
    password: '',
    participants: 1,
    participantType: 'ANONYMOUS',
    projectTextVariables: {
        requestSubjectText: '',
        comparisonText: '',
        scaleText: '',
        comparisonCriterium: ''
    },
    resultsView: ResultsViews.DEFAULT,
    choiceOptions: [],
    abortCriterions: {
        maxCnt: 0,
        hasMaxCR: true,
        maxCR: 0.1,
        hasMaxScaleDiff: true,
        maxScaleDiff: 0.05,
        cutOff: 7
    },
    projectStatus: 'DRAFT',
    version: 0
})
const getters = {
    getProjects: (state) => state.projects,
    getProjectById: (state) => (id) => state.projects.find((p) => p.id === id) || emptyProject()
}
export default getters;
