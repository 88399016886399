<template>
  <v-row>
    <v-col cols='12'>
      <v-select
        :label='$t("chartResult.chartView")'
        :items='[
          {
            text: $t("chartResult.bar"),
            value: "bar"
          },
          {
            text: $t("chartResult.radar"),
            value: "radar"
          }
        ]'
        :value='chartView'
        @change='(val) => chartView = val'/>
    </v-col>
    <v-col cols='12'>
      <RadarChart v-if='chartView === "radar" && radarData && radarData.length > 0' :multi='true' :data='radarData' :show-legend='true'/>
      <ResultsChart v-if='chartView === "bar"' :results='results'/>
    </v-col>
  </v-row>
</template>

<script>
import RadarChart from '@/components/Graphs/RadarChart.vue';
import ResultsChart from '@/components/Results/ResultsChart.vue';

export default {
  name: 'RiasecOverviewCharts',
  components: {
    ResultsChart,
    RadarChart
  },
  data: () => ({
    chartView: 'bar',
    radarData: [],
  }),
  props: {
    results: {
      required: true
    },
    images: {
      type: Array
    },
    isImageResult: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    generateRadarData() {
      const self = this;
      // temporarily persisted results of all participants incl. 'overall'
      const tmp = [...this.results];
      // filter to only 'overall' result
      const overall = tmp.find((t) => t.identifier === 'overall')

      // create theta for all radar traces as those should all be based on the same theta as the overall result
      let theta;
      let mapOrder;
      if (this.isImageResult) {
        overall.results.sort((a, b) => a.choiceOption.riasecProperties.chartOrder - b.choiceOption.riasecProperties.chartOrder);
        theta = [...overall.results.map((x) => self.$t(`imageHelpers.chartTicks.${x.choiceOption.choice || x.choiceOption.choiceKey}`)), self.$t(`imageHelpers.chartTicks.${overall.results[0].choiceOption.choice || overall.results[0].choiceOption.choiceKey}`)];
        mapOrder = [...overall.results.map((x) => x.choiceOption.choice || x.choiceOption.choiceKey), overall.results[0].choiceOption.choice || overall.results[0].choiceOption.choiceKey]
      } else {
        theta = [...overall.results.map((x) => x.choiceOption.choice || x.choiceOption.choiceKey), overall.results[0].choiceOption.choice || overall.results[0].choiceOption.choiceKey];
        mapOrder = [...theta];
      }
      const overallR = [...overall.results.map((x) => x.result), overall.results[0].result];
      let minR = []; let maxR = [];

      // theta is the base for the results mapping - results mapping is not based on users
      const map = mapOrder.reduce((acc, curr) => ({ ...acc, [curr]: [] }), {});
      // filter to only non 'overall' results
      const nonOverall = tmp.filter((t) => t.identifier !== 'overall')
      Object.keys(map).forEach((key) => {
        let res = nonOverall.map((r) => r.results.find((x) => (x.choiceOption.choice || x.choiceOption.choiceKey) === key).result).sort((a, b) => a - b);
        const min = res[0];
        const max = res[res.length - 1];
        minR.push(min);
        maxR.push(max);
      })
      this.radarData = [
        {
          type: 'scatterpolar',
          textinfo: 'percent',
          textposition: 'outside',
          hoverinfo: 'none',
          sort: false,
          theta,
          r: [...minR, minR[0]],
          name: self.$t('chartResult.min')
        },
        {
          type: 'scatterpolar',
          textinfo: 'percent',
          textposition: 'outside',
          hoverinfo: 'none',
          sort: false,
          theta,
          r: overallR,
          name: self.$t('chartResult.overall')
        },
        {
          type: 'scatterpolar',
          textinfo: 'percent',
          textposition: 'outside',
          hoverinfo: 'none',
          sort: false,
          theta,
          r: [...maxR, maxR[0]],
          name: self.$t('chartResult.max')
        }
      ]
    }
  },
  mounted() {
    this.generateRadarData()
  }
}
</script>

<style scoped>

</style>
