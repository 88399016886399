<template>
  <ScalesListComponent
    :loading='!scalesLoaded'
    :scales='scales'
    :id='$route.params.id'
(  />
</template>

<script>
import LoadingState from '@/api/LoadingState';
import { mapState } from 'vuex';
import ScalesListComponent from '@/components/Scales/ScalesListComponent.vue';

export default {
  name: 'ScalesListPage',
  components: { ScalesListComponent },
  computed: {
    ...mapState({
      scalesLoaded: (state) => state.scales.loadingState === LoadingState.LOADED,
      scales: (state) => state.scales.all
    })
  },
  mounted() {
    if (!this.scalesLoaded) {
      this.$api.scales.get();
    }
  }
}

</script>

<style scoped>

</style>
