import UsersRestModel from '@/api/RestModels/users';
import ACTIONS from '@/store/storeActions';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import LoadingState from '@/api/LoadingState';

export const UsersPayloadTypes = {
  SINGLE_USER: 'SINGLE_USER',
  ALL_USERS: 'ALL_USERS'
}
export default class Users {
  constructor(store, i18n) {
    this.$store = store;
    this.$i18n = i18n;
  }

  async get( id ) {
    const payload = {
      type: isStringNullOrWhitespace(id) ? UsersPayloadTypes.SINGLE_USER : UsersPayloadTypes.ALL_USERS,
      loadingState: LoadingState.INITIAL,
      payload: undefined
    }
    await this.$store.dispatch(ACTIONS.SET_USERS, Object.assign(payload, { loadingState: LoadingState.LOADING }));
    if ( isStringNullOrWhitespace(id) ) {
      try {
        const users = await UsersRestModel.get();
        await this.$store.dispatch(ACTIONS.SET_USERS, Object.assign(payload, { payload: users.payload, loadingState: LoadingState.LOADED }))
        return users.payload;
      } catch (err) {
        await this.$store.dispatch(ACTIONS.SET_USERS, Object.assign(payload, { loadingState: LoadingState.FAILED }));
        return err;
      }
    }
    try {
      const user = await UsersRestModel.get(id);
      await this.$store.dispatch(ACTIONS.SET_USERS, Object.assign(payload, { payload: user.payload, loadingState: LoadingState.LOADED }))
      return user.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.SET_USERS, Object.assign( payload, { loadingState: LoadingState.FAILED }));
      return err;
    }
  }

  async create(payload) {
    await this.$store.dispatch(ACTIONS.CREATE_USER, { loadingState: LoadingState.SAVING })
    try {
      const result = await UsersRestModel.create(payload);
      await this.$store.dispatch(ACTIONS.CREATE_USER, { loadingState: LoadingState.LOADED, payload: result.payload });
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.CREATE_USER, { loadingState: LoadingState.FAILED })
      return err;
    }
  }

  async update(payload) {
    await this.$store.dispatch(ACTIONS.UPDATE_USER, { loadingState: LoadingState.LOADING })
    try {
      const result = await UsersRestModel.update(payload);
      await this.$store.dispatch(ACTIONS.UPDATE_USER, { loadingState: LoadingState.LOADED, payload: result.payload })
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.UPDATE_USER, { loadingState: LoadingState.FAILED })
      return err;
    }
  }
}
