import BaseModel from '@/api/RestModels/baseModel';
import api from './client';

export const LoginBy = {
  RUNCODE: 'RUNCODE',
  PASSWORD: 'PASSWORD',
  RUNCODETOKEN: 'RUNCODETOKEN'
}

export const LoginPurpose = {
  ADMINISTRATION: 'ADMINISTRATION',
  PARTICIPATION: 'PARTICIPATION'
}

export const TokenPurpose = {
  RUN: 'RUN',
  LOGIN: 'LOGIN',
  PARTICIPANT: 'PARTICIPANT'
}

class LoginRestModel extends BaseModel {
  path = 'login';

  /**
   * @desc Login Request
   * @param {LoginBy} by
   * @param {LoginRequestModel | RunCodeRequestModel} payload
   * @returns {TokenResponse}
   */
  login = async (by, payload) => {
    let result = null;
    switch ( by ) {
      case LoginBy.PASSWORD:
        return api.post(`${this.path}/login`, { json: payload }).json();
      case LoginBy.RUNCODE:
        return api.post(`${this.path}/runcode`, { json: payload }).json();
      case LoginBy.RUNCODETOKEN:
        return api.post(`${this.path}/runcode/token`, { json: payload }).json();
      default:
        return null;
    }
  }

  userTokenLogin = async (payload) => api.post( `${this.path}/runcode/usertoken`, { json: payload }).json();

  userLogin = async (payload) => api.post(`${this.path}/runcode/userlogin`, { json: payload }).json();

  runPassword = async (payload) => api.post(`${this.path}/runcode/password`, { json: payload }).json();
}

export default new LoginRestModel()
