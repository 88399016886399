<template>
  <v-container>
    <v-tabs vertical v-if='results && results.length > 0'>
      <template v-for='(result, index) in results'>
        <v-tab :key='result.identifier'>
          <template v-if='result.identifier === "overall"'>
            {{ $ct('overallResult') }}
          </template>
          <template v-else>
            {{ `${$ct('participant')} ${index}` }}
            <template v-if='result.runParticipantType !== "ANONYMOUS"'>
              <br/>{{ result.identifier }}
            </template>
          </template>
        </v-tab>
        <v-tab-item :key='`result_${result.identifier}`'>
          <v-skeleton-loader v-if='!loaded' type='card'/>
          <v-card flat v-else>
            <v-card-text>
              <template v-if='view === ResultsViews.RIASEC'>
                <OverallRiasecView :results='results' :isImageResult='imageResult' :images='images' isAdmin='true' v-if='result.identifier==="overall"'/>
                <RiasecView :results='result' :isImageResult='imageResult' :images='images' isAdmin='true' v-else/>
              </template>
              <template v-else>
                <v-col cols='12'>
                  <h2>{{ $ct( result.identifier === 'overall' ? 'overallResult' : 'adminTitle', {identifier: result.identifier}) }}</h2>
                </v-col>
                <BarChart v-if='result.identifier !== "overall"' :results='result.results'/>
                <ResultsChart v-else :results='results'/>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </template>
    </v-tabs>
    <template v-if='imageResult && view !== ResultsViews.RIASEC'>
      <ImageGalery :images='images' display-choice/>
    </template>
    <template v-if='results && results.length <= 0'>
      <div>
        <v-row>
          <v-col cols='12'><h1>Keine Ergebnisse</h1></v-col>
        </v-row>
      </div>
    </template>
  </v-container>
</template>

<script>
import BarChart from '@/components/Results/BarChart.vue';
import ResultsChart from '@/components/Results/ResultsChart.vue';
import ImageGalery from '@/components/ImageGalery.vue';
import { ChoiceType } from '@/libs/pairsImageLibrary';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import { ChartColors, ResultsViews } from '@/libs/constants';
import RiasecView from '@/components/Results/RiasecView.vue';
import OverallRiasecView from '@/components/Results/OverallRiasecView.vue';

export default {
  name: 'ResultsComponent',
  components: {
    OverallRiasecView,
    RiasecView,
    ImageGalery,
    ResultsChart,
    BarChart
  },
  data: () => ({
    results: undefined,
    view: undefined,
    images: undefined,
    imageResult: undefined,
    loaded: false
  }),
  computed: {
    ResultsViews() {
      return ResultsViews
    }
  },
  methods: {
    async loadResults() {
      const adminResults = await this.$api.result.getResults(this.$route.params.id);
      this.results = adminResults.results;
      this.imageResult = adminResults.choiceType === ChoiceType.IMAGE;
      this.view = adminResults.view;
      if (!isStringNullOrWhitespace(adminResults?.imageLibrary)) {
        this.images = await this.$api.imageLibrary.get(adminResults.imageLibrary);
      }
      // check if riasecView is used, if so and not with imageLibrary, add colors to the choices
      if (!this.imageResult && this.view === ResultsViews.RIASEC) {
        // map colors to result
        this.results = this.results
          .map((r) => ({
            ...r,
            results: r.results
              .map(( result, index) => ({
                ...result,
                choiceOption: {
                  ...result.choiceOption,
                  colorCode: ChartColors[index]
                }
              }))
          }));
      }
      this.loaded = true;
    }
  },
  props: {},
  mounted() {
    if (this.$route.params?.id) this.loadResults()
  }
}

</script>

<style scoped>

</style>
