<template>
  <div>
    <NavigationAppbar :drawer='drawer' @setDrawer='setDrawer'/>
    <NavigationDrawer :drawer='drawer' @setDrawer='setDrawer' @setModal='setModal'/>
    <GenericModal :open='dialog' :outside-click='() => dialog=false' >
      <template v-slot:title>Möchten Sie sich ausloggen</template>
      <template v-slot:content>Mit Bestätigung werden alle Ihre Temporären Daten gelöscht. Sind Sie sicher das Sie sich ausloggen möchten?</template>
      <template v-slot:actions>
        <v-btn @click='() => dialog = false'>{{ $t('Generic.cancel') }}</v-btn>
        <v-btn @click='confirm()'>{{ $t('Generic.confirm')}}</v-btn>
      </template>
    </GenericModal>
  </div>
</template>

<script>
import NavigationAppbar from '@/components/Navigation/NavigationAppbar.vue';
import NavigationDrawer from '@/components/Navigation/NavigationDrawer.vue';
import GenericModal from '@/components/Modals/GenericModal.vue';

import { confirmLogout } from '@/libs/genericHelpers'

export default {
  name: 'Navigation',
  components: {
    GenericModal,
    // GenericModal,
    NavigationDrawer,
    NavigationAppbar
  },
  props: ['mobile'],
  data: () => ({
    drawer: null,
    dialog: false,
    showConfirmLogout: false,
  }),
  computed: {},
  methods: {
    confirmLogout,
    confirm() {
      this.confirmLogout((val) => {
        this.dialog = val
      }, this.$store, this.$router, { name: 'login' })
    },
    setModal(val) {
      this.dialog = val
    },
    setDrawer(val) {
      this.drawer = val;
    },
    // confirmLogout() {
    //   this.open = false
    //   this.$store.dispatch(ACTIONS.CLEAR_STATE, null);
    //   this.$router.push({ name: 'login' })
    // },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
  @import '@/ci.scss';
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-359deg);
      transform: rotate(-359deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-359deg);
      transform: rotate(-359deg);
    }
  }

</style>
