<template>
  <v-parallax light src="/kim_background.jpg" height="600" min-height="500">
    <v-row class="logo-container" align="start" align-content="start" justify="space-between">
      <v-col>
        <img src="KI-BIT_Logo_mit_Schriftzug_rechts.png" class="mx-3 my-3" alt="Logo" />
      </v-col>
    </v-row>

    <v-row align="center" align-content="start" justify="center">
      <v-col cols='12' align='center'>
        <h1 class="login-page-claim mx-12">{{$ct('ClaimHeader')}}</h1>
      </v-col>
      <v-col cols='12' align='center'>
        <p class="login-page-claim body-1 mx-12 mt-5">{{$ct('ClaimText')}}</p>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>

export default {
  name: 'LoginClaim',
}
</script>

<style scoped lang='scss'>
  .logo-container {
    max-height: 200px;
    & img {
      height: 45px;
    }
  }
  .login-page-claim {
    color: black
  }
</style>
