import Vue from 'vue';
import ComponentTranslate from '@/libs/componentTranslatePlugin';
import VueCookies from 'vue-cookies';
import FlagIcon from 'vue-flag-icon'
import { LoginPurpose } from '@/api/RestModels/login';
import App from './App.vue';
import router from './router';
import store from './store/store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import Api from './api'

Vue.config.productionTip = false;
Vue.use(VueCookies);
// set default config
Vue.$cookies.config('30d');
let api = new Api(store, i18n)

const AuthenticationLevel = function (myStore, myCookies, AuthLevel) {
  const cookie = myCookies.get(process.env.VUE_APP_GDPR_KEY);
  if (!cookie) myStore.commit('clearState');
  const bearerToken = myStore.getters.token() != null;

  switch (AuthLevel) {
    case LoginPurpose.ADMINISTRATION:
      return bearerToken && myStore.getters.purpose() === LoginPurpose.ADMINISTRATION && myStore;
    case LoginPurpose.PARTICIPATION:
      return bearerToken && [LoginPurpose.ADMINISTRATION, LoginPurpose.PARTICIPATION].includes(myStore.getters.purpose()) && myStore;
    default:
      return bearerToken && myStore;
  }
}

// Setup route guard
router.beforeEach((to, from, next) => {
  // if name is login or an auth is not required confirm directly
  if (to.name === 'login' || !to.meta?.requiresAuthentication) {
    next();
    return;
  }
  if (
    to.meta.requiresAuthentication
    && !AuthenticationLevel(store, Vue.$cookies, to.meta?.authenticationLevel)
  ) {
    if (!to.meta?.authenticationLevel) {
      next('/login');
      return
    }
    next(from.path);
  }
  next()
});

Vue.use(FlagIcon);
Vue.use(ComponentTranslate);

new Vue({
  router,
  store,
  vuetify,
  api,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
// default locale 'de' set
i18n.locale = 'de'
