<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader type="heading" class="mb-4"></v-skeleton-loader>
        <v-skeleton-loader type="heading"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-skeleton-loader max-width="300" type="card"></v-skeleton-loader>
        <v-skeleton-loader max-width="300" type="card"></v-skeleton-loader>
        <v-skeleton-loader max-width="300" type="card"></v-skeleton-loader>
      </v-col>
      <v-col cols="8" align="center" class="justify-center">
        <p class="mt-10">
          {{$i18n.t('LoadingPage.text')}}<br/>
          <spinner/>
        </p>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { LoginPurpose } from '@/api/RestModels/login';
import { mapState } from 'vuex';
import LoadingState from '@/api/LoadingState';
import Spinner from './Spinner';

export default {
  // eslint-disable vue/multi-word-component-names
  name: 'Loader',
  components: {
    Spinner,
  },
  data: () => ({
    isAdmin: false,
    timeoutId: undefined,
  }),
  computed: {
    ...mapState({
      currentRunId: (state) => state.runs.currentRunId || null,
      currentRunLoaded: (state) => Boolean(state.runs.current?.runStatus),
      projectsLoaded: (state) => state.projects.loadingState === LoadingState.LOADED,
      runsLoaded: (state) => state.runs.loadingState === LoadingState.LOADED,
      runState: (state) => state.runs.current?.runStatus,
      scalesLoaded: (state) => state.scales.loadingState === LoadingState.LOADED
    }),
  },
  watch: {
    projectsLoaded() {
      this.checkRunStateAndRedirect()
    },
    runsLoaded() {
      this.checkRunStateAndRedirect()
    },
    scalesLoaded() {
      this.checkRunStateAndRedirect()
    },
    currentRunLoaded() {
      this.checkRunStateAndRedirect()
    }
  },
  methods: {
    loadData() {
      const self = this;
      if (this.isAdmin) {
        // load all projects
        self.$api.project.get();
        // load all runs
        self.$api.run.get();
        // probably extend this if needed
        self.$api.scales.get();
      } else {
        self.$api.run.getRunInfo();
      }
    },
    checkRunStateAndRedirect() {
      if (this.isAdmin && this.projectsLoaded && this.runsLoaded) {
        this.$router.push({ name: this.$route.params.next })
        clearTimeout(this.timeoutId);
        return;
      }
      if (!this.isAdmin && this.currentRunLoaded) {
        if (this.runState !== 'RUNNING') {
          this.$router.push('blocker')
          clearTimeout(this.timeoutId)
        } else {
          this.$router.push({ name: this.$route.params.next })
          clearTimeout(this.timeoutId)
        }
      }
    }
  },
  mounted() {
    // done: Probably wait for all contents using promises
    // Initialize the webapp with the runInformation and display this loader until the first runinformation is fetched
    const self = this;

    this.isAdmin = self.$route.params.purpose === LoginPurpose.ADMINISTRATION;

    this.loadData();

    this.timeoutId = setTimeout(() => self.checkRunStateAndRedirect(), 500)
  },
};
</script>
