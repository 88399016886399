const ACTIONS = {
  // Login Actions
  LOGIN: 'Login',

  // Store Actions
  SET_TOKEN: 'setToken',
  CLEAR_STATE: 'clearState',
  SET_SNACKBAR: 'setSnackbar',

  // Run Actions
  SET_RUNS: 'setRuns',
  SET_CURRENT_RUN: 'setCurrentRun',
  SET_CURRENT_RUN_ID: 'setCurrentRunId',
  CREATE_RUN: 'createRun',
  UPDATE_RUN: 'updateRun',
  UPDATE_RUN_STATUS: 'updateRunStatus',
  UPDATE_TOKEN_STATUS: 'updateTokenStatus',
  ADD_TOKEN: 'addToken',
  DELETE_TOKEN: 'deleteToken',
  DELETE_RUN: 'deleteRun',

  // Project Actions
  GET_PROJECT: 'getProject',
  GET_PROJECTS: 'getProjects',
  SET_PROJECTS: 'setProjects',
  SET_CURRENT_PROJECT: 'setCurrentProject',
  CREATE_PROJECT: 'createProject',
  UPDATE_PROJECT: 'updateProject',
  UPDATE_PROJECT_PASSWORD: 'updateProjectPassword',
  DELETE_PROJECT: 'deleteProject',

  // Choice Actions
  CURRENT_CHOICE: 'currentChoice',
  RESULT: 'result',
  RESULTS: 'results',

  // LoadingState Actions
  SET_LOADING: 'setLoading',

  // User Actions
  CREATE_USER: 'createUser',
  UPDATE_USER: 'updateUser',
  SET_USERS: 'setUsers',

  // Scale Actions
  SET_SCALES: 'setScales',
  CREATE_SCALE: 'createScale',
  UPDATE_SCALE: 'updateScale',
  DELETE_SCALE: 'deleteScale'
}

export default ACTIONS;
