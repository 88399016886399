import BaseModel from '@/api/RestModels/baseModel';
import api from './client'

class ChoiceRestModel extends BaseModel {
  path = 'choice';

  getChoice = async () => api.get(this.path);

  setChoice = async (payload) => api.post(this.path, { json: payload }).json();

  getResult = async () => api.get(`${this.path}/result`);

  getResults = async (runId) => api.get(`${this.path}/results/${runId}`);
}

export default new ChoiceRestModel()
