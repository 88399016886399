export default Object.freeze( {
  EXERCISE: 1,
  PREPARATION: 2,
  EVALUATION: 3
})

export const ProjectStatus = Object.freeze({
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DELETED: 'DELETED'
})

export const RunStatus = Object.freeze({
  DRAFT: 'DRAFT',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED'
})

export const TokenAction = Object.freeze({
  RENEW: 'RENEW',
  DISABLE: 'DISABLE',
  REACTIVATE: 'REACTIVATE'
})

export const ResultsViews = Object.freeze({
  DEFAULT: 'DEFAULT',
  RIASEC: 'RIASEC'
})

export const ChartColors = Object.freeze({
  0: 'rgb(230,25,75)',
  1: 'rgb(245,130,48)',
  2: 'rgb(255,255,25)',
  3: 'rgb(0,0,172)',
  4: 'rgb(60,180,75)',
  5: 'rgb(70,245,245)',
  6: 'rgb(0,130,200)',
  7: 'rgb(145,30,180)',
  8: 'rgb(240,50,220)',
  9: 'rgb(128,128,128)',
  10: 'rgb(250,190,212)',
  11: 'rgb(255,215,180)',
  12: 'rgb(170,255,195)',
  13: 'rgb(220,190,255)',
  14: 'rgb(0,128,0)',
  15: 'rgb(128,0,0)',
  16: 'rgb(170,110,40)',
  17: 'rgb(128,128,0)',
  18: 'rgb(0,128,128)',
  19: 'rgb(210,245,60)',
  20: 'rgb(255,128,128)',
  21: 'rgb(128,255,128)',
  22: 'rgb(128,128,255)',
  23: 'rgb(255,64,128)',
  24: 'rgb(64,128,255)'
})
