import api from './client';

export default class BaseModel {
  path = '';

  get = async () => api.get(this.path);

  create = async (payload) => api.post( `${this.path}`, { json: payload });

  update = async (payload) => api.put( `${this.path}`, { json: payload });

}
