<template>
  <v-container>
    <div id='loginRecaptcha' class='g-recaptcha mt-5'></div>
  </v-container>

</template>

<script>
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';

let recaptchaInterval;
export default {
  name: 'Recaptcha',
  props: {
    disableRecaptcha: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA,
    }
  },
  methods: {
    recaptcha() {
      if (window.grecaptcha && window.grecaptcha.render && !isStringNullOrWhitespace(this.recaptchaSiteKey)) {
        const self = this;
        this.widgetId = window.grecaptcha.render('loginRecaptcha', {
          sitekey: self.recaptchaSiteKey,
          callback: (response) => {
            self.$emit('recaptcha', response)
          }
        })
        clearInterval(recaptchaInterval)
      } else {
        console.log('ReCaptcha not yet loaded')
      }
    },
  },
  mounted() {
    if (!this.disableRecaptcha) recaptchaInterval = setInterval(this.recaptcha, 500);
  }
}
</script>

<style scoped>

</style>
