<template>
  <v-breadcrumbs
    divider='>'
    :items='items'/>
</template>

<script>

export default {
  name: 'BreadCrumbs',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>

</style>
