var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":_vm.$ct("chartView"),"items":[
              {
                text: _vm.$t("chartResult.pie"),
                value: "pie"
              },
              {
                text: _vm.$t("chartResult.radar"),
                value: "radar"
              }
            ],"value":_vm.chartView},on:{"change":(val) => _vm.chartView = val}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.chartView === "pie")?_c('PieChart',{attrs:{"images":_vm.images,"results":_vm.results.results,"isImageResult":_vm.isImageResult}}):_c('RadarChart',{attrs:{"results":_vm.results.results,"isImageResult":_vm.isImageResult}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('FlagChart',{attrs:{"results":_vm.results,"images":_vm.images,"isImageResult":_vm.isImageResult}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }