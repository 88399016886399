import ky from 'ky';
import ACTIONS from '@/store/storeActions';
import store from '@/store/store';
import { apiUrl, authToken } from '@/libs/apiLib';
import i18n from '../../i18n';

const evalErrors = (response) => {
  if (response && response.body && response.body.errorMessage) {
    store.dispatch(ACTIONS.SET_SNACKBAR, { text: i18n.t(response.body), color: 'error' })
    throw response
  }
  if (response && (response.status === 0 || response.status === 500)) {
    store.dispatch(ACTIONS.SET_SNACKBAR, { text: i18n.t('Errors.generic500'), color: 'error' })
    throw response;
  }
  if (response && response.body && response.body.errors) {
    const keys = Object.keys(response.body.errors);
    if (keys && keys[0]) store.dispatch(ACTIONS.SET_SNACKBAR, { text: i18n.t(`Errors.Validation.${keys[0]}`), color: 'error' })
    throw response
  }
}

const clientApi = ky.extend({
  prefixUrl: apiUrl,
  retry: 0,
  throwHttpErrors: false,
  timeout: 30000,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Authorization', `Bearer ${authToken(store)}`);
      }
    ],
    afterResponse: [
      async (_request, _options, response) => {
        if (response == null) {
          // so something when the response is null
        }
        const contentType = response.headers.get('content-type');
        if (contentType?.startsWith('application/problem+json')) {
          // Housten we have a problem
          const innerJson = await response.json();
          if ( response?.status === 400 && !innerJson.metadata ) {
            let message = `${i18n.t('api.validationError' )}`
            if (typeof innerJson.errors.file !== 'undefined' ) {
              message = `${i18n.t('api.corruptFile')}`
            }
            store.dispatch(ACTIONS.SET_SNACKBAR, { text: message, color: 'error' })
          } else {
            evalErrors(response);
          }
          return new Response(null, { status: response?.status })
        }
        if (!contentType?.startsWith('application/json')) {
          return response
        }
        // return json if the status is a ok
        const json = await response.json();
        if (response.status !== 200) {
          if (response.status === 400 && !json) {
            store.dispatch(ACTIONS.SET_SNACKBAR, { text: `${i18n.t('api.validationError')}`, color: 'error' })
            throw new Response({ status: response.status });
          } else if (json) {
            console.log('ApiError: ', json.errorType)
            store.dispatch(ACTIONS.SET_SNACKBAR, { text: `${i18n.t(`api.errors.${json.errorType}`)}`, color: 'error' })
            throw new Response(JSON.stringify(json), { status: response.status });
          } else {
            evalErrors(response);
          }
          return new Response(JSON.stringify(json), { status: response.status });
        }

        if (_request.method === 'DELETE' ) {
          store.dispatch(ACTIONS.SET_SNACKBAR, { text: `${i18n.t('api.deleteSuccessful')}`, color: 'success' })
        }
        return json;
      }
    ]
  }
})

const requestCatch = (e) => {
  console.log('in request catch', e)
  if ([200, 204, 400, 401, 500].includes(e?.status)) return
  store.dispatch(ACTIONS.SET_SNACKBAR, { text: `${i18n.t('api.networkError')}`, color: 'error' });
}

export default {
  get: (url, options) => {
    const promise = clientApi.get(url, options).json();
    promise
      .catch(requestCatch)
    return promise;
  },
  post: (url, options) => {
    const promise = clientApi.post(url, options);
    promise.catch(requestCatch);
    return promise;
  },
  put: (url, options) => {
    const promise = clientApi.put(url, options);
    promise.catch(requestCatch);
    return promise;
  },
  delete: (url, options) => {
    const promise = clientApi.delete(url, options);
    promise.catch(requestCatch);
    return promise;
  },
  patch: (url, options) => {
    const promise = clientApi.patch(url, options).json();
    promise.catch(requestCatch);
    return promise;
  },
}
