<template>
  <div style='height: 100vh'>
    <LoginClaim/>
    <LoginCard
      :loading='loading'
      :code='code'
      :login='login'
      :recaptchaValue='recaptchaValue'
      :selectedTab='selectedTab'
      @onChange='val => $emit("onChange", val)'
      @reload='$emit("reload")'
      @submit='$emit("submit")'
      @recaptcha='(val) => $emit("recaptcha", val)'
      @updateTab='(val) => $emit("updateTab", val)'
      :requirement='requirement'
      :requirementForm='requirementForm'
    />
  </div>
</template>

<script>
import LoginClaim from '@/components/Login/LoginClaim.vue';
import LoginCard from '@/components/Login/LoginCard.vue';

export default {
  name: 'LoginComponent',
  components: {
    LoginClaim,
    LoginCard
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    /** @type RunCodeRequestModel*/
    code: Object,
    /** @type {LoginRequestModel}*/
    login: Object,
    recaptchaValue: String,
    selectedTab: {
      type: Number,
      default: 0
    },
    requirement: {
      type: String,
      default: 'none'
    },
    requirementForm: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
