<template>
  <v-container>
    <v-row>
      <v-col cols='12' v-if='!loading'>
        <ChoiceOptions
          :choices='choices'
          :value='current?.id'
          @selection='(val) => $emit("selection", val)'/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ChoiceOptions from '@/components/Choices/ChoiceOptions.vue';

export default {
  name: 'ChoiceSelector',
  components: {
    ChoiceOptions
  },
  computed: {
    ...mapState({
      run: (state) => state.runs.current
    })
  },
  props: {
    current: undefined,
    loading: Boolean,
    choices: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
