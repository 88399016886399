import ACTIONS from '@/store/storeActions';
import { saveOrUpdateState } from '@/libs/storeHelper';

const saveOrUpdate = (state, payload, stateObj = 'all') => saveOrUpdateState(state, stateObj, payload)
const mutations = {
  [ACTIONS.SET_SCALES]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) {
      if (Array.isArray(payload)) {
        state.all = payload;
        return;
      }
      saveOrUpdate(state, payload)
    }
  },
    [ACTIONS.CREATE_SCALE]: (state, { loadingState, payload }) => {
      state.loadingState = loadingState;
      if (payload) saveOrUpdate(state, payload )
    },
    [ACTIONS.UPDATE_SCALE]: (state, { loadingState, payload }) => {
      state.loadingState = loadingState;
      if (payload) saveOrUpdate(state, payload)
    },
    [ACTIONS.DELETE_SCALE]: (state, { loadingState, payload }) => {
      state.loadingState = loadingState;
      // TODO Actually remove if delete was successful
    }
}

export default mutations;
