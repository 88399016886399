<template>
  <v-dialog class="classes" :value="open" @click:outside='outsideClick()' :width="width">
    <v-card>
      <v-card-title>
        <slot name='title'></slot>
      </v-card-title>
      <slot name='subtitle'/>
      <v-card-text>
        <slot name='content'></slot>
      </v-card-text>
      <v-card-actions>
        <slot name='actions'>
          <v-btn @click='handleClose'>{{ $t('Generic.cancel') }}</v-btn>
          <v-btn @click='handleSave'>{{ $t('Generic.save')}}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {
  name: 'GenericModal',
  props: {
    // open props
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    outsideClick: {
      type: Function,
    },
    width: {
      type: String,
      default: '400'
    },
    classes: {
      type: String
    },
    handleClose: {
      type: Function,
    },
    handleSave: {
      type: Function,
    }
  }
}
</script>

<style scoped>

</style>
