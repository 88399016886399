<template>
  <v-container>
    <BreadCrumbs :items='[{ text: $t("breadcrumbs.dashboard"), disabled: true }]'/>
    <EditorHeader
      :title='$ct("editorTitle")'
      has-search
      :searchLabel='$ct("editorSearchLabel")'
      @search='searchHandler'
      class='pb-4'
    >
    </EditorHeader>
    <template v-if='search === ""'>
      <v-tabs v-model='tab' :value='tab'>
        <v-tab
          v-for='tab in Object.keys(ProjectStatus)'
          :tab-value='tab'
          :disabled='getProjectsForStatus(tab).length <= 0'
          :key='tab'
        >{{$t(`Generic.projectStatus.${tab}`)}}</v-tab>
      </v-tabs>
      <v-tabs-items v-model='tab' :value='tab'>
        <v-container>
          <v-tab-item v-for='item in Object.keys(ProjectStatus)' :key='item' :value='item'>
            <v-row>
              <template v-for='project in getProjectsForStatus(item)'>
                <v-col :key='project.id' class='col-sm-12 col-md-6 col-lg-6 col-xl-4'>
                  <DashboardProjectCard :project='project'/>
                </v-col>
              </template>
            </v-row>
          </v-tab-item>
        </v-container>
      </v-tabs-items>
    </template>
    <template v-else>
      <v-row>
        <v-col cols='12' v-for='result in getSearchResult()' :key='result.id'>
          <DashboardProjectCard :project='result' withStatus/>
        </v-col>
      </v-row>

    </template>

<!--    <DashboardComponent/>-->
  </v-container>

</template>

<script>
// import DashboardComponent from '@/components/DashboardComponent.vue';
import BreadCrumbs from '@/components/Generics/BreadCrumbs.vue';
import EditorHeader from '@/components/Generics/EditorHeader.vue';
import { ProjectStatus } from '@/libs/constants';
import { mapState } from 'vuex';
import LoadingState from '@/api/LoadingState';
import DashboardProjectCard from '@/components/Dashboard/DashboardProjectCard.vue';

export default {
  name: 'DashboardPage',
  computed: {
    ...mapState({
      projects: (state) => state.projects.projects,
      loading: (state) => state.projects.loadingState === LoadingState.LOADING,
      loaded: (state) => state.projects.loadingState === LoadingState.LOADED
    }),
    ProjectStatus() {
      return ProjectStatus
    }
  },
  components: { DashboardProjectCard, EditorHeader, BreadCrumbs },
  data: () => ({
    tab: 'ACTIVE',
    search: ''
  }),
  methods: {
    getSearchResult() {
      return this.projects.filter((p) => p.title.toLowerCase().includes(this.search.toLowerCase()))
    },
    getProjectsForStatus(projectStatus) {
      return this.projects.filter((p) => p.projectStatus === projectStatus)
    },
    searchHandler(search) {
      this.search = search
    }
  },
  mounted() {
    if (!this.projects || !Array.isArray(this.projects) || (!this.loading && !this.loaded)) {
      this.$api.project.get();
    }
  }
}
</script>

<style scoped>

</style>
