import ACTIONS from '@/store/storeActions';
import { saveOrUpdateState } from '@/libs/storeHelper';

const saveOrUpdate = (state, payload) => {
  saveOrUpdateState(state, 'runs', payload);
  if (state.current?.id === payload.id) {
    state.current = { ...state.current, ...payload };
  }
}
const mutations = {
  [ACTIONS.SET_RUNS]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) {
      if (Array.isArray(payload)) {
        state.runs = payload;
        return;
      }
      saveOrUpdate(state, payload)
    }
  },
  [ACTIONS.SET_CURRENT_RUN]: (state, payload) => {
    state.current = payload;
  },
  [ACTIONS.SET_CURRENT_RUN_ID]: (state, payload) => {
    state.currentRunId = payload
  },
  [ACTIONS.CREATE_RUN]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) {
      state.current = payload;
      saveOrUpdate(state, payload)
    }
  },
  [ACTIONS.UPDATE_RUN]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) saveOrUpdate(state, payload)
  },
  [ACTIONS.UPDATE_RUN_STATUS]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) saveOrUpdate(state, payload);
  },
  [ACTIONS.UPDATE_TOKEN_STATUS]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) saveOrUpdate(state, payload);
  },
  [ACTIONS.ADD_TOKEN]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) saveOrUpdate(state, payload);
  },
  [ACTIONS.DELETE_TOKEN]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) saveOrUpdate(state, payload)
  },
  [ACTIONS.DELETE_RUN]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) {
      const runIndex = state.runs.findIndex((r) => r.id === payload);
      state.runs = [...state.runs.slice(0, runIndex), ...state.runs.slice(runIndex + 1, state.runs.length)]
      state.runs = state.runs.reduce((prev, curr) => {
        if (curr.id === payload) {
          return prev;
        }
        prev.push(curr)
        return prev;
      }, [])
    }
  }
}

export default mutations;
