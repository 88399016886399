import ACTIONS from '@/store/storeActions';

const actions = {
  [ACTIONS.SET_RUNS]: (context, payload) => { context.commit(ACTIONS.SET_RUNS, payload) },
  [ACTIONS.SET_CURRENT_RUN]: (context, payload) => context.commit(ACTIONS.SET_CURRENT_RUN, payload),
  [ACTIONS.SET_CURRENT_RUN_ID]: (context, payload) => context.commit(ACTIONS.SET_CURRENT_RUN_ID, payload),
  [ACTIONS.CREATE_RUN]: (context, payload) => { context.commit(ACTIONS.CREATE_RUN, payload) },
  [ACTIONS.UPDATE_RUN]: (context, payload) => { context.commit(ACTIONS.UPDATE_RUN, payload) },
  [ACTIONS.UPDATE_RUN_STATUS]: (context, payload) => { context.commit(ACTIONS.UPDATE_RUN_STATUS, payload) },
  [ACTIONS.UPDATE_TOKEN_STATUS]: (context, payload) => context.commit(ACTIONS.UPDATE_TOKEN_STATUS, payload),
  [ACTIONS.ADD_TOKEN]: (context, payload) => context.commit(ACTIONS.ADD_TOKEN, payload),
  [ACTIONS.DELETE_TOKEN]: (context, payload) => context.commit(ACTIONS.DELETE_TOKEN, payload),
  [ACTIONS.DELETE_RUN]: (context, payload) => context.commit(ACTIONS.DELETE_RUN, payload)
}

export default actions
