import BaseModel from '@/api/RestModels/baseModel';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import api from './client';

class ProjectRestModel extends BaseModel {
  path = 'project';

  get = async (id) => (isStringNullOrWhitespace(id) ? api.get(this.path) : api.get(`${this.path}/${id}`))

  create = async (payload) => api.post(`${this.path}/create`, { json: payload }).json();

  update = async (payload) => api.post(`${this.path}/update`, { json: payload }).json();

  updatePassword = async (payload) => api.post(`${this.path}/updatePassword`, { json: payload }).json();

  updateStatus = async (id, payload) => api.post(`${this.path}/updateStatus/${id}`, { json: payload }).json();

  triggerDelete = async (id) => api.delete(`${this.path}/${id}`).json();
}

export default new ProjectRestModel()
