<template>
  <v-container>
    <!-- PageHeader -->
    <EditorHeader
      :title='$ct("title")'
      hasSearch
      :searchLabel='$ct("searchLabel")'
      @search='searchHandler()'
      class='pb-4'>
      <template v-slot:actions>
        <v-btn @click='newScale' :disabled='process.env.DISABLE_NEW_SCALE_CREATION'>
          {{$ct('create')}}
        </v-btn>
      </template>
    </EditorHeader>
    <!--  List all Scales available  -->
    <v-data-table
      :headers='scalesHeaders()'
      :items='scales'
      :search='search'
      class='elevation-1 pt-2'
      :locale='$i18n.locale'
      :items-per-page='-1'>
      <template v-slot:item.actons='{ item }'>
        <v-tooltip top >
          <template v-slot:activator='{ on, attrs }'>
            <v-icon v-bind='attrs' v-on='on' class='mr-2' @click.prevent.stop='edit(item.id)'>mdi-pen</v-icon>
          </template>
          <span>{{ $ct('edit') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator='{ on, attrs }'>
            <v-icon v-bind='attrs' v-on='on' @click.prevent.stop='tryDelete(item.id)'>mdi-delete</v-icon>
          </template>
        </v-tooltip>
      </template>
    </v-data-table>
    <GenericModal :open='open' :outside-click='closeModal' :handleClose='closeModal' :handleSave='confirmAndDelete'>
        <template v-slot:title>{{ $ct('deleteConfirmationTitle') }}</template>
        <template v-slot:content>
            <v-row>
                <v-col cols='12'>{{ $ct('deleteConfirmationContent') }}</v-col>
                <v-col cols='12'>{{ deleteItem.name }} </v-col>
            </v-row>
        </template>
    </GenericModal>
  </v-container>
</template>

<script>
import GenericModal from '@/components/Modals/GenericModal.vue';

export default {
  name: 'ScalesListComponent',
  components: { GenericModal },
  data: () => ({
      open: false
  }),
  props: {
    loading: Boolean,
    scales: Array,
  },
  methods: {
    searchHandler(val) {
      this.search = val
    },
    closeModal() {},
    confirmAndDelete() {},
    scalesHeaders() {
      return [
        {
          text: this.$ct('scaleName'), align: 'start', sortable: false, value: 'title'
        },
        {
          text: this.$ct('scaleOptions'), align: 'start', sortable: false, value: 'title'
        },
        {
          text: this.$ct('actions.title'), align: 'end', sortable: false, value: 'actions'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
