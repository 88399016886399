<template>
  <v-container>
    <BreadCrumbs
      :items='[
            {
              text: $t("breadcrumbs.projects"),
              disabled: true,
              exact: true,
              to: { name: "projectHome" }
            }
        ]'
    ></BreadCrumbs>
    <EditorHeader
      :title='$ct("editorTitle")'
      hasSearch
      :search-label='$ct("editorSearchLabel")'
      @search='searchHandler'
      class='pb-4'
    >
      <template v-slot:actions>
        <v-btn
          @click=handleNewProject()>
          {{ $t('Generic.createNew')}}
        </v-btn>
      </template>
    </EditorHeader>
    <v-skeleton-loader
      v-if='loading'
      type='table'/>
    <v-data-table
        v-if='!loading'
        :headers='[
          {
            text: $ct("projectTitle"), sortable: false, value: "title"
          },
          {
            text: $ct("createdOn"), sortable: false, value: "createdOn"
          },
          {
            text: $ct("passwordEnabled"), sortable: false, value: "password"
          },
          {
            text: $ct("projectStatus"), sortable: false, value: "projectStatus"
          },
          {
            text: $ct("projectVersion"), sortable: false, value: "version"
          },
          {
            text: $ct("actions.header"), align: "end", sortable: false, value: "actions"
          }
        ]'
        :items='projects'
        :sort-by='["date"]'
        :search='search'
        multi-sort
        class='elevation-1 pt-2'
        :locale='$i18n.locale'
        @click:row='tableClickHandler'
        :items-per-page='-1'>
        <template v-slot:item.createdOn='{ item }'>
          {{ dayjs(item.createdOn).format("DD.MM.YYYY")}}
        </template>
        <template v-slot:item.projectStatus='{ item }'>
          {{ $t(`ProjectSettings.projectStatusOptions.${item.projectStatus}`) }}
        </template>
        <template v-slot:item.password='{ item }'>
          <v-icon v-if='isStringNullOrWhitespace(item.password)'>mdi-close</v-icon>
          <v-icon v-else>mdi-check</v-icon>
        </template>
        <template v-slot:item.actions='{ item }'>
          <v-row v-if='item.projectStatus === ProjectStatus.DRAFT' justify='end'>
            <v-col class='flex-grow-0'>
              <v-tooltip top>
                <template v-slot:activator='{ on, attrs }'>
                  <v-icon v-bind='attrs' v-on='on' small @click.prevent.stop='updateStatus(item.id, ProjectStatus.ACTIVE)'>mdi-check</v-icon>
                </template>
                <span>{{ $ct('actions.activate') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-else-if='item.projectStatus === ProjectStatus.ACTIVE' justify='end'>
            <v-col class='flex-grow-0'>
              <v-tooltip top>
                <template v-slot:activator='{ on, attrs }'>
                  <v-icon v-bind='attrs' v-on='on' small @click.prevent.stop='updateStatus(item.id, ProjectStatus.ARCHIVED)'>mdi-archive-outline</v-icon>
                </template>
                <span>{{ $ct('actions.archive') }}</span>
              </v-tooltip>
            </v-col>
            <v-col class='flex-grow-0'>
              <v-tooltip top>
                <template v-slot:activator='{ on, attrs }'>
                  <v-icon v-bind='attrs' v-on='on' small @click.prevent.stop='updateStatus(item.id, ProjectStatus.DELETED)'>mdi-delete</v-icon>
                </template>
                <span>{{ $ct('actions.deactivate') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-else-if='item.projectStatus === ProjectStatus.ARCHIVED' justify='end'>
            <v-col class='flex-grow-0'>
              <v-tooltip top>
                <template v-slot:activator='{ on, attrs }'>
                  <v-icon v-bind='attrs' v-on='on' small @click.prevent.stop='updateStatus(item.id, ProjectStatus.ACTIVE)'>mdi-check</v-icon>
                </template>
                <span>{{ $ct('actions.activate') }}</span>
              </v-tooltip>
            </v-col>
            <v-col class='flex-grow-0'>
              <v-tooltip top>
                <template v-slot:activator='{ on, attrs }'>
                  <v-icon v-bind='attrs' v-on='on' small @click.prevent.stop='deleteWarning(item)'>mdi-cancel</v-icon>
                </template>
                <span>{{ $ct('actions.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-else justify='end'>
            <v-col class='flex-grow-0'>
              <v-tooltip top>
                <template v-slot:activator='{ on, attrs }'>
                  <v-icon v-bind='attrs' v-on='on' small @click.prevent.stop='deleteWarning(item)'>mdi-cancel</v-icon>
                </template>
                <span>{{ $ct('actions.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      <GenericModal :open='openDeleteWarning' :handle-close='() => openDeleteWarning = false' :handle-save='triggerDelete' :outside-click='() => openDeleteWarning = false'>
        <template v-slot:title>{{ $t('Generic.warningTitle') }}</template>
        <template v-slot:content>{{ $ct('deleteWarning', {title: potentialDelete.title} )}} {{ $t('Generic.warningContent') }}</template>
      </GenericModal>
  </v-container>
</template>

<script>
import EditorHeader from '@/components/Generics/EditorHeader.vue';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import { ProjectStatus } from '@/libs/constants';
import dayjs from 'dayjs';
import { mapGetters, mapState } from 'vuex';
import LoadingState from '@/api/LoadingState';
import ACTIONS from '@/store/storeActions';
import GenericModal from '@/components/Modals/GenericModal.vue';
import BreadCrumbs from '@/components/Generics/BreadCrumbs.vue';

export default {
  name: 'ProjectList',
  computed: {
    ...mapState({
      projects: (state) => state.projects.projects,
      loading: (state) => state.projects.loadingState === LoadingState.LOADING
    }),
    ...mapGetters(['getProjectById']),
    ProjectStatus() {
      return ProjectStatus
    },
  },
  components: { BreadCrumbs, GenericModal, EditorHeader },
  methods: {
    dayjs,
    isStringNullOrWhitespace,
    deleteWarning(item) {
      this.potentialDelete = item;
      this.openDeleteWarning = true;
    },
    searchHandler(val) {
      this.search = val;
    },
    handleNewProject() {
      this.$store.dispatch(ACTIONS.SET_CURRENT_PROJECT, this.getProjectById(null));
      this.$router.push({ name: 'projectEdit' })
    },
    tableClickHandler(val) {
      this.$store.dispatch(ACTIONS.SET_CURRENT_PROJECT, this.getProjectById(val.id) )
      this.$router.push({ name: 'projectEdit', params: { id: val?.id } })
    },
    updateStatus(itemId, newStatus) {
      this.$api.project.updateStatus(itemId, newStatus);
    },
    triggerDelete() {
      this.$api.project.triggerDelete(this.potentialDelete.id);
      this.potentialDelete = {};
      this.openDeleteWarning = false;
    },
    refresh() {
      this.$api.project.get();
    }
  },
  data: () => ({
      search: null,
      openDeleteWarning: false,
      potentialDelete: {}
    }),
  mounted() {
    this.$api.project.get()
  }
}
</script>

<style scoped>

</style>
