import { saveOrUpdateState } from '@/libs/storeHelper';
import ACTIONS from '@/store/storeActions';

const saveOrUpdate = (state, payload) => saveOrUpdateState(state, 'users', payload)
const mutations = {
  [ACTIONS.SET_USERS]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) {
      if (Array.isArray(payload)) {
        state.users = payload;
        return;
      }
      saveOrUpdate(state, payload);
    }
  },
  [ACTIONS.CREATE_USER]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) saveOrUpdate(state, payload);
  },
  [ACTIONS.UPDATE_USER]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) saveOrUpdate(state, payload);
  }
}

export default mutations;
