<template>
  <div>
    <div ref='resultsChart'></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist'
import plotlyLocaleDE from 'plotly.js-locales/de';

Plotly.register(plotlyLocaleDE);
export default {
  name: 'ResultsChart',
  props: {
    results: Array
  },
  data: () => ({
    horizontal: false
  }),
  methods: {
    generatePlot() {
      const self = this;
      const data = [];
      const sortedChoiceOptions = self.results[0].results.map((r) => r.choiceOption).sort((a, b) => a.id - b.id);
      const baseXAxis = sortedChoiceOptions.map((r) => r.choice || r.choiceKey);
      // map data to be iterated in a better way
      const choiceToChoiceResultMap = baseXAxis.reduce((acc, curr) => ({ ...acc, [curr]: [] }), {});
      const mappedValues = [
        { identifier: 'max', results: [] },
        { identifier: 'overall', results: [] },
        { identifier: 'min', results: [] },
      ]
      Object.keys(choiceToChoiceResultMap).forEach((key) => {
        let res = self.results.map((r) => ({
          identifier: r.identifier,
          result: r.results.find((x) => (x.choiceOption.choice || x.choiceOption.choiceKey) === key).result
        })).sort((a, b) => a.result - b.result)
        const nonOverallResult = res.filter((x) => x.identifier !== 'overall')
        const min = nonOverallResult[0];
        const max = nonOverallResult[nonOverallResult.length - 1]
        const overall = res.find((r) => r.identifier === 'overall')
        mappedValues[0].results.push(max.result);
        mappedValues[1].results.push(overall.result);
        mappedValues[2].results.push(min.result);
      })
      for (let i = 0; i < mappedValues.length; i++) {
        data.push({
          y: baseXAxis,
          x: mappedValues[i].results,
          name: this.$t(`chartResult.${mappedValues[i].identifier}`),
          type: 'bar',
          orientation: 'h'
        })
      }
      Plotly.newPlot(
        this.$refs.resultsChart,
        data,
        // layout
        {
          title: this.$ct('pairsOverview'),
          yaxis: {
            automargin: true
          }
        },
        // options
        {
          displayModeBar: false
        }
      )
      // generate traces per results object
    }
  },
  created() {
    Plotly.setPlotConfig({ locale: this.$i18n.locale })
  },
  mounted() {
    this.generatePlot()
  }
}

</script>

<style scoped>

</style>
