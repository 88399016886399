<template>
  <v-row>
    <template v-for='result in results.results'>
      <v-col cols='6' class='col-md-12' :key='`legend_${result.choiceOption.choice}`'>
        <v-row class='align-center'>
          <v-col cols='2' class='legend-colorfield-container' :key='`legend_color_${result.choiceOption.choice}`'>
            <!-- color field -->
            <div class='legend-colorfield' :style='{backgroundColor: `${result.choiceOption.colorCode}`}'></div>
          </v-col>
          <v-col cols='10' :key='`legend_desc_${result.choiceOption.choice}`'>
            <p class='img-label'>{{ result.choiceOption.choice }}</p>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  name: 'RiasecLegend',
  props: {
    results: {
      type: Object,
      required: true
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang='scss'>
  .legend-colorfield-container{
    width: 100px;
    height: 100px;
  }
  .legend-colorfield{
    width: 100%;
    height: 100%;
  }
</style>
