class BusinessLogicResults {
  constructor(vue) {
    this.vue = vue;
  }

  mapResults(results) {
    let result = {
      result: [],
      min: 0,
      max: 0
    };
    if (!results) return result
    const sortedChoices = results
      .map((x) => ({
        ...x.choiceOption,
        choice: x.choiceOption.choice,
        result: x.result,
      }))
      .sort((a, b) => b.result - a.result);
    result.min = sortedChoices[sortedChoices.length - 1].result;
    result.max = sortedChoices[0].result;
    result.result = sortedChoices;
    return result;
  }
}

export default BusinessLogicResults;
