<template>
  <v-card>
    <v-card-title>
      {{ $t('RiasecView.testedPerson')}}
    </v-card-title>
    <v-card-text>
      <div class='riasec-person grid'>
        <div class='riasec-person grid grid-container'>
          <div class='riasec-person item colored label'>
            {{ $t('RiasecView.name') }}
          </div>
          <div class='riasec-person item colored '>
            <v-text-field class='pa-0 inputOverride' hide-details/>
          </div>
        </div>
        <div class='riasec-person grid grid-container'>
          <div class='riasec-person item colored label'>
            {{ $t('RiasecView.birthday') }}
          </div>
          <div class='riasec-person item colored-value'>
            <v-text-field class='pa-0 text--lighten-5' hide-details/>
          </div>
        </div>
        <div class='riasec-person grid grid-container'>
          <div class='riasec-person item colored label'>
            {{ $t('RiasecView.gender') }}
          </div>
          <div class='riasec-person item colored-value'>
            <v-text-field class='pa-0' hide-details/>
          </div>
        </div>
        <div class='riasec-person grid grid-container'>
          <div class='riasec-person item colored label'>
            {{ $t('RiasecView.date') }}
          </div>
          <div class='riasec-person item colored-value'>
            <v-text-field class='pa-0' hide-details :value='lastResultUpdate ? `${dayjs(lastResultUpdate).format("DD.MM.YYYY - HH:mm")} Uhr` : ""'/>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'PersonCard',
  methods: { dayjs },
  props: {
    lastResultUpdate: {
      type: String
    }
  }
}
</script>

<style lang='scss'>
.riasec-person {
  &.grid {
    display: grid;
    grid-auto-rows: 1fr;
    gap: .2rem 0;

    &:first-child {
      padding-bottom: .3rem;
    }
    &.grid-container {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr 2fr;
      gap: 0 .5rem;
    }

    & .item {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr 2fr;
      grid-auto-rows: 1fr;
      align-items: center;
      padding: 0 1rem;

      &.label {
        padding-left: 1rem;
        font-weight: bold;
        font-size: 1rem;
        color: var(--v-text-lighten5)
      }
      &.colored {
        background: var(--v-primary-base);
      }
    }
  }
  &.grid:nth-child(odd) {
    & .item.colored-value {
      background: var(--v-primary-lighten4)
    }
  }
  &.grid:nth-child(even) {
    & .item.colored-value {
      background: var(--v-primary-lighten2)
    }
  }
}
.theme--light.v-input {
  &.inputOverride input {
    color: var(--v-text-lighten5);
    font-weight: bold;
  }
}
</style>
