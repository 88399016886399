import BaseModel from '@/api/RestModels/baseModel';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import api from './client';

class ScaleRestModel extends BaseModel {
  path = 'scale';

  get = async (id) => (isStringNullOrWhitespace(id) ? api.get(this.path) : api.get( `${this.path}/${id}`))

  create = async (payload) => api.post(`${this.path}/create`, { json: payload });

  update = async (payload) => api.post(`${this.path}/update`, { json: payload });

  delete = async (id) => api.post(`${this.path}/delete/{id}`)
}

export default new ScaleRestModel()
