import ImageLibraryRestModel from '@/api/RestModels/imageLibrary';
import LoadingState from '@/api/LoadingState';
import ACTIONS from '@/store/storeActions';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';

export default class ImageLibary {
  constructor(store, i18n) {
    this.$store = store;
    this.$i18n = i18n;
  }

  async getAll(persist = false) {
    if (persist) await this.$store.dispatch(ACTIONS.SET_IMAGE_LIBRARIES, { loadingState: LoadingState.LOADING });
    try {
      const imageLibraries = await ImageLibraryRestModel.getAll();
      if (persist) await this.$store.dispatch(ACTIONS.SET_IMAGE_LIBRARIES, { payload: imageLibraries.payload, loadingState: LoadingState.LOADED })
      return imageLibraries.payload
    } catch (err) {
      if (persist) await this.$store.dispatch(ACTIONS.SET_IMAGE_LIBRARIES, { loadingState: LoadingState.FAILED });
      return err;
    }
  }

  async get(lib, persist = false) {
    if (isStringNullOrWhitespace(lib)) return [];
    if (persist) await this.$store.dispatch(ACTIONS.SET_IMAGE_LIBRARY, { loadingState: LoadingState.LOADING });
    try {
      const imageReferences = await ImageLibraryRestModel.get(lib)
      if (persist) await this.$store.dispatch(ACTIONS.SET_IMAGE_LIBRARY, { payload: imageReferences.payload, loadingState: LoadingState.LOADED });
      return imageReferences.payload;
    } catch (err) {
      if (persist) await this.$store.dispatch(ACTIONS.SET_IMAGE_LIBRARY, { loadingState: LoadingState.FAILED })
      return err;
    }
  }
}
