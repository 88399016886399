import Vue from 'vue';
import Router from 'vue-router';
import LoginPage from '@/views/LoginPage.vue';
import Loader from '@/components/Loader';
import { LoginPurpose } from '@/api/RestModels/login';
import participantRoutes from '@/routes/participant';
import administrationRoutes from '@/routes/administration';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: { name: 'login' },
      meta: {
        requiresAuthentication: false,
        hideNavigation: true,
        hideToolbar: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      meta: {
        requiresAuthentication: false,
        hideNavigation: true,
        hideToolbar: true,
      },
    },
    {
      path: '/loader',
      name: 'loader',
      component: Loader,
      meta: {
        hideNavigation: true,
        hideToolbar: false,
        requiresAuthentication: true,
        authenticationLevel: LoginPurpose.PARTICIPATION
      },
    },
    ...participantRoutes,
    ...administrationRoutes
  ],
});
