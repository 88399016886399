<template>
  <v-container v-if='loaded'>
    <BreadCrumbs
      :items='generateItems()'
    />
    <ResultsComponent />
  </v-container>
</template>

<script>

import ResultsComponent from '@/components/Results/ResultsComponent.vue';
import { mapGetters, mapState } from 'vuex';
import { LoginPurpose } from '@/api/RestModels/login';
import ACTIONS from '@/store/storeActions';
import BreadCrumbs from '@/components/Generics/BreadCrumbs.vue';

export default {
  name: 'AdminResultsPage',
  components: { BreadCrumbs, ResultsComponent },
  data() {
    return {
      runId: null,
      prevRoute: null,
      loaded: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next( (vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.login.purpose === LoginPurpose.ADMINISTRATION,
    }),
    ...mapGetters(['getRunById']),
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : '/administration/dashboard'
    },
  },
  methods: {
    generateItems() {
      let result = [];
      const run = this.getRunById(this.runId);
      const title = run?.title || run?.runToken || ''
      // if prevRoutePath exists and is not '/'
      if (this.prevRoutePath?.length > 1) {
        const split = this.prevRoutePath.split('/');
        let breadcrumb = split[split.length - 1];
        if (this.prevRoutePath.endsWith('/')) {
          breadcrumb = split[split.length - 2]
        }
        result.push({
          text: this.$t(`breadcrumbs.${breadcrumb}`),
          exact: true,
          to: { path: this.prevRoutePath }
        })
      } else {
        // this.prevRoutePath is of length 1 or doesnt exist
        // default to dashboard
        result.push({
          text: this.$t('breadcrumbs.dashboard'),
          exact: true,
          to: { path: '/administration/dashboard' }
        })
      }
      result.push({
        text: this.$t('breadcrumbs.results', { title: title ? ` ${this.$t('Generic.for')} ${title}` : '' }),
        exact: true,
        disabled: true
      })
      return result;
    }
  },
  mounted() {
    if (this.isAdmin) {
      this.runId = this.$route.params?.id;
    } else {
      this.$store.dispatch(ACTIONS.SET_SNACKBAR, { color: 'error', text: this.$t('errors.NO_PERMISSION') })
      this.$router.back();
    }
    this.loaded = true;
  }
}
</script>

<style scoped>

</style>
