<template>
  <div>
    <v-app-bar app color='primary' dark dense
               scroll-threshold='150'>
      <v-app-bar-nav-icon @click.stop='() => dialog = true'>
        <v-icon>mdi-logout</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title class='flex-grow-1'>{{ $t('App.ParticipantNavigationTitle') }}</v-app-bar-title>
    </v-app-bar>
    <GenericModal :open='dialog' :outside-click='() => dialog=false'>
      <template v-slot:title>Möchten Sie sich ausloggen</template>
      <template v-slot:content>Mit Bestätigung werden alle Ihre Temporären Daten gelöscht. Sind Sie sicher das Sie sich ausloggen möchten?</template>
      <template v-slot:actions>
        <v-btn @click='() => dialog = false'>{{ $t('Generic.cancel') }}</v-btn>
        <v-btn @click='confirm()'>{{ $t('Generic.confirm')}}</v-btn>
      </template>
    </GenericModal>
  </div>

</template>

<script>
import GenericModal from '@/components/Modals/GenericModal.vue';
import { confirmLogout } from '@/libs/genericHelpers';

export default {
  name: 'NavToolbar',
  methods: {
    confirmLogout,
    confirm() {
      this.confirmLogout((val) => {
        this.dialog = val
      }, this.$store, this.$router, { name: 'login' })
    }
  },
  components: {
    GenericModal
  },
  data: () => ({
    dialog: false
  })
}
</script>

<style scoped lang='scss'>
::v-deep .v-app-bar-title__content {
    width: auto !important;
  }
</style>
