import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import LoadingState from '@/api/LoadingState';
import ACTIONS from './storeActions';
import choice from './choice';
import projects from './projects';
import runs from './runs';
import users from './users';
import scales from './scales';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

const getDefaultState = () => ({
  login: {
    loadingState: LoadingState.INITIAL,
    token: null,
    expires: undefined,
    purpose: undefined
  },
  snackbar: {
    text: null,
    color: null
  },
  language: navigator.language.substring(0, 2)
})

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: getDefaultState(),
  modules: {
    choice,
    projects,
    runs,
    users,
    scales,
    //login
  },
  getters: {
    token(state) {
      return () => state.login.token;
    },
    purpose(state) {
      return () => state.login.purpose;
    },
    snackbar(state) {
      return () => state.snackbar;
    },
    language(state) {
      return () => state.language;
    },
  },
  mutations: {
    [ACTIONS.LOGIN]: (state, { loadingState, payload }) => {
      const tmpLogin = { ...state.login, loadingState };
      if (payload) {
        const { token, expires, loginPurpose } = payload;
        tmpLogin.token = token;
        tmpLogin.expires = expires;
        tmpLogin.purpose = loginPurpose;
      }
      Object.assign(state.login, tmpLogin)
    },
    [ACTIONS.SET_SNACKBAR]: (state, { color, text }) => {
      state.snackbar.color = color;
      state.snackbar.text = text;
    },
    [ACTIONS.CLEAR_STATE]: (state) => {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    [ACTIONS.LOGIN]: (context, payload) => { context.commit(ACTIONS.LOGIN, payload) },
    [ACTIONS.SET_SNACKBAR]: (context, payload) => {
      context.commit(ACTIONS.SET_SNACKBAR, payload);
    },
    [ACTIONS.CLEAR_STATE]: (context) => {
      context.commit(ACTIONS.CLEAR_STATE);
    }
  },
})
