<template>
  <ProjectFormWrapper :title='`${$ct("title")} (${choiceOptions.length} von 25)`' :valid='valid' @validate='(val) => $emit("validate", val)'>
    <v-row>
      <v-col cols='6'>
        <v-switch :value='useImages' @change='handleUseImageSwitch' :label='$ct("useImages")'/>

      </v-col>
    </v-row>
    <v-row v-if='!useImages'>
      <v-col cols='3' v-for='(option, oIndex) in choiceOptions' :key='`choices_${oIndex}`'>
        <v-text-field
          type='text'
          v-model='option.choice'
          append-icon='mdi-delete'
          @click:append='$emit("removeChoice", oIndex)'
        />
      </v-col>
      <v-col cols='3'>
        <v-text-field
          v-if='choiceOptions.length < 25'
          :label='$ct("newChoice")'
          v-model='newChoice.choice'
          append-icon='mdi-plus'
          @keyup.enter.prevent='handleAddChoice'
          @click:append.prevent='handleAddChoice'
        />
      </v-col>
    </v-row>
    <template v-else-if='useImages'>
      <v-row>
        <template v-if='imageLibraries.length > 1'>
          <v-col cols='6' v-for='lib in imageLibraries' :key='lib'>
            <v-btn @click='(lib) => library = lib'>{{ $t(`imageLibrary.${lib}`) }}</v-btn>
          </v-col>
        </template>
      </v-row>
      <v-row v-if='library !== "" && imagesLoaded'>
        <v-col cols='4' v-for='ref in imageReferences' :key='ref.reference'>
          <v-img max-height='400' max-width='400' class='shrink mt-2' contain :src='ref.reference'/>
        </v-col>
      </v-row>
    </template>
  </ProjectFormWrapper>
</template>

<script>
import ProjectFormWrapper from '@/components/Projects/Form/ProjectFormWrapper.vue';
import {
  ChoiceType
} from '@/libs/pairsImageLibrary';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';

export default {
  name: 'ProjectChoiceOptions',
  components: { ProjectFormWrapper },
  props: {
    valid: {
      required: true
    },
    choiceOptions: {
      type: Array,
      default: () => []
    }
  },
  computed: { },
  data: () => ({
    newChoice: {
      type: ChoiceType.TEXT,
      choice: ''
    },
    useImages: false,
    library: 'PAIRS',
    imageReferences: [],
    imagesLoaded: false,
    imageLibraries: [],
  }),
  watch: {
    choiceOptions() {
      if (this?.choiceOptions?.some((s) => s.type === ChoiceType.IMAGE) && !this.useImages) {
        this.loadImageReferences();
        this.useImages = true;
        if (isStringNullOrWhitespace(this.imageLibrary)) this.imageLibrary = 'db'
      }
    }
  },
  methods: {
    async loadImageLibraries() {
      this.imageLibraries = await this.$api.imageLibrary.getAll();
    },
    async loadImageReferences() {
      const ref = await this.$api.imageLibrary.get(this.library)
      this.imageReferences = ref;
      this.imagesLoaded = true;
    },
    async handleAddImageLibrary() {
      await this.loadImageReferences();
      this.$emit('removeImages');
      this.$emit('addImages', this.imageReferences);
    },
    handleAddChoice() {
      this.$emit('addChoice', { ...this.newChoice, identifier: this.choiceOptions.length + 1 })
      this.newChoice = { choice: '', type: ChoiceType.TEXT };
    },
    handleUseImageSwitch() {
      this.useImages = !this.useImages;
      if (this.useImages) {
        this.handleAddImageLibrary();
      } else {
        this.$emit('removeImages')
      }
    }
  },
  mounted() {
    this.loadImageLibraries();
    if (this.choiceOptions && this.choiceOptions.some((s) => s.type === ChoiceType.IMAGE)) {
      this.loadImageReferences();
      this.useImages = this.choiceOptions.some((s) => s.type === ChoiceType.IMAGE)
    }
  }
}
</script>

<style scoped>

</style>
