<template>
  <div>
    <p class='font-weight-bold'>{{ $t('RiasecDiagnostics.flag') }}:</p>
    <div class='flag--container' v-if='Array.isArray(formattedResult) && formattedResult.length === 3'>
      <div v-for='r in formattedResult' :key='`flag_${(r.choiceOption.choice || r.choiceOption.choiceKey)}`' :style='`background-color: ${r.colorCode}`' class='flag--component'>
      </div>
    </div>
    <p class='ma-0'><span class='font-weight-bold'>{{ $t('RiasecDiagnostics.stability') }}:</span> {{ results.stabilityValue }}</p>
    <p><span class='font-weight-bold'>{{ $t('RiasecDiagnostics.dominance') }}:</span> {{ results.dominanceValue }}</p>
  </div>
</template>


<script>
export default {
  name: 'FlagChart',
  props: {
    results: Object,
    images: Array,
    isImageResult: Boolean
  },
  data: () => ({
  }),
  computed: {
    formattedResult() {
      const tmp = [...this.results.results];
      const reduced = tmp.sort((a, b) => b.result - a.result).slice(0, 3);
      let result;
      if (this.isImageResult) {
        result = reduced.map((a) => ({ ...a, ...this.images.find((i) => i.identifier === a.choiceOption.identifier) }));
      } else {
        result = reduced.map((a) => ({ ...a, colorCode: a.choiceOption.colorCode }))
      }
      return result
    }
  }
}
</script>

<style scoped lang='scss'>
.flag{
  &--container, &--component {
    width: 180px;
  }
  &--container {
    height: 90px;
    margin-bottom: 1rem;
  }
  &--component {
    height: 30px;
  }
}
</style>
