import LoadingState from '@/api/LoadingState';
import ACTIONS from '@/store/storeActions';
import ScaleRestModel from '../RestModels/scale';


export default class Scale {
  constructor(store, i18n) {
    this.$store = store;
    this.$i18n = i18n;
  }

  async get(id) {
    const payload = {
      loadingState: LoadingState.INITIAL,
      payload: undefined
    }
    await this.$store.dispatch(ACTIONS.SET_SCALES, Object.assign(payload, { loadingState: LoadingState.LOADING }))
    try {
      const scales = await ScaleRestModel.get(id);
      await this.$store.dispatch(ACTIONS.SET_SCALES, Object.assign(payload, { payload: scales.payload, loadingState: LoadingState.LOADED }))
      return scales.payload;
    } catch ( err ) {
      await this.$store.dispatch(ACTIONS.SET_SCALES, Object.assign(payload, { loadingState: LoadingState.FAILED }))
      return err;
    }
  }
}
