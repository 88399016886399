export const saveOrUpdateState = (state, stateObj, payload) => {
  if (Array.isArray(payload)) throw new Error('Not meant for payload arrays');
  const index = state[stateObj].findIndex((obj) => obj.id === payload.id)
  if (index > -1) {
    state[stateObj][index] = payload
    return;
  }
  if (Array.isArray(state[stateObj])) state[stateObj].push(payload)
  else state[stateObj] = payload
}

export default () => {}
