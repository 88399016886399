<template>
  <v-container>
    <v-row>
      <v-col cols='12'>
        <h2>{{ $ct('title') }}</h2>
        <p>{{ $ct('subtitle') }}</p>
      </v-col>
      <v-col cols='6' class='col-12 col-md-6'>
        <RiasecOverviewCharts :results='results' :images='images' :isImageResult='isImageResult'/>
      </v-col>
      <v-col cols='6' class='col-12 col-md-6'>
        <RiasecImageLegend :images='images' v-if='isImageResult'/>
        <RiasecLegend :results='results[0]' v-else/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RiasecLegend from '@/components/Results/Riasec/RiasecLegend.vue';
import RiasecImageLegend from '@/components/Results/Riasec/RiasecImageLegend.vue';
import RiasecOverviewCharts from '@/components/Results/Riasec/RiasecOverviewCharts.vue';

export default {
  name: 'OverallRiasecView',
  components: {
    RiasecOverviewCharts,
    RiasecImageLegend,
    RiasecLegend
  },
  props: {
    results: {

    },
    images: {
      type: Array
    },
    isImageResult: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array
    }
  }
}
</script>

<style scoped>

</style>
