<template>
  <v-text-field
    :type='show ? "text" : "password"'
    :label='label'
    :value='value'
    @change='onChange'
    :placeholder='placeholder'
    :rules='rules'
    :prepend-icon='prependIcon ? "mdi-lock" : null'
    :append-icon='show ? "mdi-eye-outline" : "mdi-eye-off-outline"'
    @click:append='show = !show'
    :ref='reference'
    :disabled='disabled'
    @keyup.enter='keyUpAction'
  />
</template>

<script>
export default {
  name: 'PasswordField',
  data() {
    return {
      show: false,
    }
  },
  props: {
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      default: () => '',
      required: true
    },
    prependIcon: {
      type: Boolean,
      default: true
    },
    onChange: {
      type: Function,
      required: true
    },
    keyUpAction: {
      type: Function,
      required: false,
      default: () => {}
    },
    placeholder: {
      type: String,
      required: false,
      default: () => ''
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    reference: {
      default: ''
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
