<template>
  <ProjectFormWrapper :title='$ct("title")' :valid='valid' @validate='(val) => $emit("validate", val)'>
    <v-row v-if='typeof criterions === "object"'>
      <v-col cols='6'>
        <v-text-field
          :label='$ct("maxRequests")'
          type='number'
          step='1'
          :value='isEdit
            ? minMax > criterions.maxCnt ? minMax : criterions.maxCnt
            : minMaxCount'
          @change='(val) => $emit("change", { field: "abortCriterions", value: { ...criterions, maxCnt: parseFloat(val) } })'
        />
      </v-col>
      <v-col cols='6'>
        <v-text-field
          :label='$ct("cutOff")'
          type='number'
          step='1'
          min='1'
          :value='criterions.cutOff'
          @change='(val) => $emit("change", { field: "abortCriterions", value: { ...criterions, cutOff: parseInt(val)}})'
        />
      </v-col>
      <v-col cols='12'>
        <v-row>
          <v-col>
            <v-checkbox
              :label='$ct("hasMaxConsistency")'
              :model-value='criterions.hasMaxCR'
              :input-value='criterions.hasMaxCR'
              :value='criterions.hasMaxCR'
              :aria-checked='criterions.hasMaxCR'
              :multiple='false'
              @change='(val) => $emit("change", {field: "abortCriterions", value: { ...criterions, hasMaxCR: Boolean(val) }})'
            />
          </v-col>
          <v-col>
            <v-text-field
              :label='$ct("maxConsistency")'
              :disabled='!criterions.hasMaxCR'
              type='number'
              step='0.01'
              :value='criterions.maxCR'
              @change='(val) => $emit("change", { field: "abortCriterions", value: { ...criterions, maxCR: parseFloat(val) } })'
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols='12'>
        <v-row>
          <v-col>
            <v-checkbox
              :label='$ct("hasMaxScaleDiff")'
              :model-value='criterions.hasMaxScaleDiff'
              :input-value='criterions.hasMaxScaleDiff'
              :value='criterions.hasMaxScaleDiff'
              :multiple='false'
              @change='(val) => $emit("change", { field: "abortCriterions", value: { ...criterions, hasMaxScaleDiff: Boolean(val) }})'/>
          </v-col>
          <v-col>
            <v-text-field
              :label='$ct("maxTotalScaleChange")'
              :disabled='!criterions.hasMaxScaleDiff'
              type='number'
              step='0.01'
              :value='criterions.maxScaleDiff'
              @change='(val) => $emit("change", { field: "abortCriterions", value: { ...criterions, maxScaleDiff: parseFloat(val) } })'
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </ProjectFormWrapper>
</template>

<script>
import ProjectFormWrapper from '@/components/Projects/Form/ProjectFormWrapper.vue';

export default {
  name: 'ProjectAbortCriterions',
  components: { ProjectFormWrapper },
  props: {
    valid: {
      required: true
    },
    /** @type AbortCriterionsObject */
    criterions: {
      type: Object,
      default: () => ({
        maxCnt: 0,
        hasMaxCR: true,
        maxCR: 0.1,
        hasMaxScaleDiff: true,
        maxScaleDiff: 0.05,
        cutOff: 7
      })
    },
    order: {
      type: Number,
      default: () => 0
    },
    isEdit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      minMaxCount: 0,
      minMax: 0,
      criterionsMaxCount: 0,
    }
  },
  watch: {
    order() {
      // min for max should be at least the order amount -> needed to calculate all other values
      // minMaxCount for full evaluation
      this.minMaxCount = (this.order * (this.order - 1)) / 2;
      this.minMax = this.order;
      if (this.isEdit && this.minMax > this.criterions.MaxCnt) {
        this.$emit('change', { field: 'abortCriterions', value: { ...this.criterions, maxCnt: parseFloat(this.minMax) } });
      }
      if (!this.isEdit) {
        this.$emit('change', { field: 'abortCriterions', value: { ...this.criterions, maxCnt: parseFloat(this.minMaxCount) } });
      }
    },
  }
}
</script>

<style scoped>

</style>
