<template>
  <v-container>
    <v-row>
        <v-col cols='12'>
          <h2>{{ $ct( isAdmin ? 'adminTitle' : 'pageTitle', {identifier: results.identifier}) }}</h2>
          <p>{{ $ct( 'pageSubtitle') }}</p>
        </v-col>
        <template v-if='!isAdmin'>
          <v-col class='col-lg-6 col-md-8 col-sm-12'>
            <PersonCard :lastResultUpdate='results.lastUpdate'/>
          </v-col>
          <v-col cols='12'>
            <h4>{{ $ct('pageResultHeadline') }}</h4>
            <p>{{ $ct('pageResultContent') }}</p>
          </v-col>
        </template>
        <!-- Resultcharts  -->
        <v-col cols='6' class='col-12 col-md-6'>
          <RiasecResultsCharts :results='results' :images='images' :isImageResult='isImageResult'/>
        </v-col>
      <template v-if='isImageResult'>
        <!-- Image Legend -->
        <v-col cols='6' class='col-12 col-md-6'>
          <RiasecImageLegend :images='images'/>
        </v-col>
        <v-col cols='12'>
          <RiasecJobRecommendations :recommendations='results.jobRecommendations' :isAdmin='isAdmin'/>
        </v-col>
        <v-col cols='12' v-if='!isAdmin'>
          <RiasecDiagnostics :images='images' />
        </v-col>
      </template>
      <template v-else>
        <v-col cols='6' class='col-12 col-md-6'>
          <RiasecLegend :results='results'/>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import BusinessLogicResults from '@/libs/businessLogicResults';
import PersonCard from '@/components/Generics/PersonCard.vue';
import RiasecImageLegend from '@/components/Results/Riasec/RiasecImageLegend.vue';
import RiasecResultsCharts from '@/components/Results/Riasec/RiasecResultsCharts.vue';
import RiasecJobRecommendations from '@/components/Results/Riasec/RiasecJobRecommendations.vue';
import RiasecDiagnostics from '@/components/Results/Riasec/RiasecDiagnostics.vue';
import RiasecLegend from '@/components/Results/Riasec/RiasecLegend.vue';

export default {
  name: 'RiasecView',
  methods: {},
  components: {
    RiasecDiagnostics,
    RiasecJobRecommendations,
    RiasecResultsCharts,
    RiasecImageLegend,
    RiasecLegend,
    PersonCard,
  },
  data: () => ({
    logic: undefined,
  }),
  props: {
    isImageResult: {
      type: Boolean,
      required: true,
      default: () => false
    },
    images: {
      type: Array,
      required: false
    },
    results: {
      type: Object,
      required: true
    },
    isAdmin: {
      default: false
    }
  },
  mounted() {
    this.logic = new BusinessLogicResults(this);
  }
}
</script>

<style scoped>
</style>
