<template>
  <v-card>
    <v-card-title>
      <div class='d-flex flex-grow-1'>{{ project.title }} {{ withStatus ? `(${$t(`Generic.projectStatus.${project.projectStatus}`)})` : ''}}</div>
      <div class='align-content-end'>{{ $t('Generic.createdOn')}}{{ dayjs(project.createdOn).format('DD.MM.YYYY') }}</div>
    </v-card-title>
    <v-card-text>
      <DonutChart valueType='project' :values='getRunsForProjects(project.id)' :options='{"annotations.text": getRunsForProjects(project.id).length}'/>
    </v-card-text>
    <v-card-title>
      <div class='d-flex'>{{ 'Durchführungen: '}} {{ getRunsForProjects(project.id).length }}</div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols='12' class='col-sm-12 col-lg-6' v-for='run in getRunsForProjects(project.id)' :key='run.id'>
          <DonutChart valueType='run' :value='run' @click='handleClick(run.id)'/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import DonutChart from '@/components/Graphs/DonutChart.vue';
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import { ProjectStatus } from '@/libs/constants';

export default {
  name: 'DashboardProjectCard',
  components: { DonutChart },
  props: {
    project: {
      type: Object
    },
    withStatus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ProjectStatus() {
      return ProjectStatus
    },
    ...mapState({
      runs: (state) => state.runs.runs,
    })
  },
  methods: {
    dayjs,
    handleClick(run) {
      this.$router.push({ name: 'results', params: { id: run } });
    },
    getRunsForProjects(projectId) {
      return this.runs.filter((r) => r.projectId === projectId);
    },
  }
}

</script>

<style scoped>

</style>
