<template>
  <v-container v-if='loaded'>

    <template v-if='result.view === ResultsViews.RIASEC'>
      <RiasecView :results='result' :isImageResult='imageResult' :images='images'/>
    </template>
    <!--  Always fall back to default view  -->
    <template v-else>
      <v-col cols='12'>
        <h2>{{ $ct( 'pageTitle') }}</h2>
        <p class='i18n-with-linebreak'>{{ $ct( result?.runParticipantType ? `pageSubtitle.${result.runParticipantType.toLowerCase()}` : 'pageSubtitle.anonymous') }}</p>
      </v-col>
      <BarChart :results='result.results'/>
      <template v-if='imageResult'>
        <!--      <h4>{{$ct('imageSelection')}}</h4>-->
        <ImageGalery
          :images='images'
          display-choice
        />
      </template>
      <v-col cols='12'>
        <p class='i18n-with-linebreak'>{{ $ct( result?.runParticipantType ? `pageAddendum.${result.runParticipantType.toLowerCase()}` : 'pageAddendum.anonymous') }}</p>
      </v-col>
    </template>
  </v-container>
</template>

<script>
import BarChart from '@/components/Results/BarChart.vue';
import { ChoiceType } from '@/libs/pairsImageLibrary';
import ImageGalery from '@/components/ImageGalery.vue';
import RiasecView from '@/components/Results/RiasecView.vue';
import { ChartColors, ResultsViews } from '@/libs/constants';

export default {
  name: 'ResultsPage',
  components: {
    RiasecView,
    ImageGalery,
    BarChart
  },
  data: () => ({
    loaded: false,
    result: undefined,
    images: [],
    imageResult: false
  }),
  computed: {
    ResultsViews() {
      return ResultsViews
    }
  },
  methods: {
    async loadResult() {
      this.result = await this.$api.result.get();
      // set if image result based on backend supplied choiceType
      this.imageResult = this.result.choiceType === ChoiceType.IMAGE;
      // check if imageLibrary was used, if so, fetch images
      if (this.result.imageLibrary) {
        this.images = await this.$api.imageLibrary.get(this.result.imageLibrary);
      }
      // check if riasecView is used, if so and not with imageLibrary, add colors to the choices
      if (!this.imageResult && this.result.view === ResultsViews.RIASEC) {
        // map colors to result
        this.result.results = this.result.results.map((r, index) => ({ ...r, choiceOption: { ...r.choiceOption, colorCode: ChartColors[index] } } ))
      }
      this.loaded = true;
    },
  },
  mounted() {
    this.loadResult();
  }
}
</script>

<style lang='scss' scoped>
  .i18n-with-linebreak {
    white-space: pre-line
  }
</style>
