<template>
  <v-footer app>
    <div>
      <ki-bit-logo class="mx-2" style="max-height:45px"/>
    </div>
    <div class="py-2">
      <!-- <router-link class="px-5" to="/imprint">{{$t('Footer.imprint')}}</router-link> -->
      <a href="https://www.ki-bit.com/impressum/" target="_blank" class="px-5">{{$ct('imprint')}}</a>
      <a href="https://www.ki-bit.com/dschutz/" target="_blank">{{$ct('privacy')}}</a>
    </div>

    <v-spacer></v-spacer>
    <v-btn icon @click="switchLanguage('de')">
      <flag iso="de"/>
    </v-btn>
    <v-btn icon @click="switchLanguage('en')">
      <flag iso="gb"/>
    </v-btn>
    <small class="pr-3">version {{version}}</small>
    <div>&copy; {{ new Date().getFullYear() }} KI.BIT</div>
  </v-footer>
</template>

<script>
import KiBitLogo from '@/components/KiBitLogo';

export default {
  name: 'FooterBar', // Don't call it Schnitzel! Footer collides with default html tag
  components: {
    KiBitLogo,
  },
  data: () => ({
    version: process.env.VUE_APP_VERSION,
  }),
  methods: {
    switchLanguage(lang) {
      this.$i18n.locale = lang;
      console.log(this.$i18n.locale);
    }
  }
};
</script>

<style scoped>

</style>
