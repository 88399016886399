import ACTIONS from '@/store/storeActions';

const actions = {
  [ACTIONS.SET_SCALES]: (context, payload) => { context.commit(ACTIONS.SET_SCALES, payload) },
  [ACTIONS.CREATE_SCALE]: (context, payload) => { context.commit(ACTIONS.CREATE_SCALE, payload) },
  [ACTIONS.UPDATE_SCALE]: (context, payload) => { context.commit(ACTIONS.UPDATE_RUN, payload) },
  [ACTIONS.DELETE_SCALE]: (context, payload) => { context.commit(ACTIONS.DELETE_SCALE, payload) }
}

export default actions
