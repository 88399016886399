<template>
  <UserList v-if='componentType === "list"'
            :loading='loading'
            :users='users'
            />
  <UserCreateOrEdit v-else-if='componentType === "createOrEdit"'
                    :loading='loading'
                    :user='user()'
                    @save='save'/>
</template>

<script>
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import UserList from '@/components/Settings/Users/UserList.vue';
import UserCreateOrEdit from '@/components/Settings/Users/UserCreateOrEdit.vue';

export default {
  name: 'UsersComponent',
  components: { UserList, UserCreateOrEdit },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: true
    },
    users: {
      type: Array,
      default: () => []
    },
    componentType: {
      type: String,
      default: 'list'
    }
  },
  data: () => ({
    search: null,
  }),
  computed: {
    defaultUser: () => ({
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      role: 'PARTICIPANT'
    })
  },
  methods: {
    user() {
      if (!this.$route?.params?.id) return this.defaultUser;
      return this.users.find((p) => p.id === this.$route.params.id) || this.defaultUser
    },
    save(user) {
      if (!isStringNullOrWhitespace(user.id)) {
        this.$api.users.update(user);
        return;
      }
      this.$api.users.create(user);
    }
  }
}
</script>

<style scoped>

</style>
