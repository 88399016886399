<template>
  <v-app-bar
    app color='primary' dark
    shrink-on-scroll prominent
    src='/kim_splash_web.jpg'
    fade-img-on-scroll
    scroll-threshold='150'>
    <template v-slot:img='{ props }'>
      <v-img v-bind='props' gradient='to top right, rgba(212, 8, 44, .6), rgba(148, 8, 37, .6)'/>
    </template>
    <v-app-bar-nav-icon @click.stop='$emit("setDrawer", !drawer)'/>
    <v-toolbar-title>
      <v-img
        alt='Logo' class='shrink mt-2'
        contain src='/header_logo.png'
        transition='scale-transition'
        width='85'/>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: 'NavigationAppbar',
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
