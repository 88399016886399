import { LoginPurpose } from '@/api/RestModels/login';
import DashboardPage from '@/views/administration/DashboardPage.vue';
import AdminResultsPage from '@/views/administration/AdminResultsPage.vue';
import UserAdministrationPage from '@/views/administration/settings/UserAdministrationPage.vue';
import ScalesCreateOrEditPage from '@/views/administration/ScalesCreateOrEditPage.vue';
import ScalesListPage from '@/views/administration/ScalesListPage.vue';
import ProjectList from '@/views/administration/Project/ProjectList.vue';
import PageBase from '@/views/PageBase.vue';
import ProjectEdit from '@/views/administration/Project/ProjectEdit.vue';
import RunList from '@/views/administration/Run/RunList.vue';
import RunEdit from '@/views/administration/Run/RunEdit.vue';

const meta = Object.freeze({
  hideNavigation: false,
  hideToolbar: true,
  requiresAuthentication: true,
  authenticationLevel: LoginPurpose.ADMINISTRATION
})

const routes = () => {
  const route = [
    {
      path: '/administration/dashboard',
      name: 'dashboard',
      component: DashboardPage,
      meta
    },
    {
      path: '/administration/projects',
      meta,
      component: PageBase,
      children: [
        {
          path: '',
          name: 'projectHome',
          component: ProjectList,
        },
        {
          path: 'edit/:id?',
          name: 'projectEdit',
          component: ProjectEdit
        }

      ]
    },
    {
      path: '/administration/runs',
      component: PageBase,
      meta,
      children: [
        {
          path: '',
          name: 'runHome',
          component: RunList
        },
        {
          path: 'edit/:id?',
          name: 'runEdit',
          component: RunEdit
        }
      ]
    },
    {
      path: '/administration/results/:id',
      name: 'results',
      component: AdminResultsPage,
      meta
    },
    {
      path: '/administration/scales',
      component: ScalesListPage,
      meta,
      children: [
        {
          path: '',
          name: 'scales',
        },
        {
          path: '/scale/:id?',
          name: 'createOrEdit',
          component: ScalesCreateOrEditPage
        }
      ]
    }
  ]
  if (process.env.VUE_APP_FEATURE_USERADMINISTRATION === 'true') {
    route.push({
      path: '/administration/settings/users',
      component: UserAdministrationPage,
      meta,
      children: [
        {
          path: '',
          name: 'users'
        },
        {
          path: 'new',
          name: 'newUser'
        },
        {
          path: 'edit/:id',
          name: 'editUser'
        }
      ]
    });
  }
  if (process.env.VUE_APP_FEATURE_THEMECONFIGURATOR === 'true') {
    route.push({
        path: '/administration/theme',
        name: 'themeConfigurator',
        meta
    });
  }
  return route
}

export default routes();
