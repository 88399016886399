<template>
  <v-radio-group class='v-radio' :value='value' row @change='(val) => $emit("selection", val)' active-class="isHighlighted">
    <v-row align='center' justify='center' class='v-row--custom' width='100%' style='max-width: 100%' >
      <v-col
        :cols='Math.round(12 / 3)'
        :class='`v-radio-group-col--custom ${choices?.[0]?.type === ChoiceType.IMAGE ? "custom-img": ""}`'>
        <span class='d-none'>{{choices?.[0]?.choice}}</span>
        <v-radio
          :label='choices?.[0]?.choice'
          :value='choices?.[0]?.identifier'
          :class='`justify-center v-radio-item--custom ${choices?.[0]?.type === ChoiceType.IMAGE ? "custom-img": ""}`'
        >
          <template v-slot:label>
            <div :class='`v-radio-label--custom ${choices?.[0]?.type === ChoiceType.IMAGE ? "custom-img": ""}`'>
              <v-img
                contain
                v-if='choices?.[0]?.type === ChoiceType.IMAGE'
                :src=choices?.[0]?.reference
                alt=''
                class='shrink flex-shrink-1 v-label--image-sizes'/>
              <span v-if='choices?.[0]?.type === ChoiceType.TEXT'>{{choices?.[0]?.choice}}</span>
            </div>
          </template>
        </v-radio>
      </v-col>
      <v-col
        :cols='Math.round(12 / 3)'
        class='v-radio-group-col--custom'>
        <span class='d-none'>Keine</span>
        <v-radio
          label='Keine'
          value='NONE'
          class='justify-center v-radio-item--custom'
        >
          <template v-slot:label>
            <div class='v-radio-label--custom'>{{ choices?.some(c => c.type === ChoiceType.IMAGE) ? $ct('equalSign') : $ct('indifferent') }}</div>
          </template>
        </v-radio>
      </v-col>
      <v-col
        :cols='Math.round(12 / 3)'
        :class='`v-radio-group-col--custom ${choices?.[1]?.type === ChoiceType.IMAGE ? "custom-img": ""}`'>
        <span class='d-none'>{{choices?.[1]?.choice}}</span>
        <v-radio
          :label='choices?.[1]?.choice'
          :value='choices?.[1]?.identifier'
          :class='`justify-center v-radio-item--custom ${choices?.[1]?.type === ChoiceType.IMAGE ? "custom-img": ""}`'
        >
          <template v-slot:label>
            <div class='v-radio-label--custom'>
              <v-img
                contain
                v-if='choices?.[1]?.type === ChoiceType.IMAGE'
                :src='choices?.[1]?.reference'
                alt=''
                class='shrink flex-shrink-1 v-label--image-sizes'/>
              <span v-else>{{choices?.[1]?.choice}}</span>
            </div>
          </template>
        </v-radio>
      </v-col>
    </v-row>
  </v-radio-group>
</template>

<script>
import { ChoiceType } from '@/libs/pairsImageLibrary';

export default {
  name: 'ChoiceOptions',
  computed: {
    ChoiceType() {
      return ChoiceType
    }
  },
  props: ['choices', 'value']
}
</script>

<style scoped lang='scss'>
  @import '~vuetify/src/styles/settings/_variables';
  ::v-deep .v-input--selection-controls__input {
    display: none !important
  }
  .v-radio-group-col--custom {
    display: flex;
    min-height: 4rem;
    padding: 0 .5rem;
    flex: 1 1 auto;
    &.custom-img {
      justify-content: center;
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: .5rem 0;
    }
  }
  .v-label--image-sizes {
    border: 4px solid transparent;
    max-height: 400px;
    max-width: 400px;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      max-height: 250px;
      max-width: 250px;
    }
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      max-height: 250px;
      max-width: 250px;
    }
    @media #{map-get($display-breakpoints, 'lg-and-down')} {
      max-height: 250px;
      max-width: 250px;
    }
    .isHighlighted & {
      border: 4px solid lawngreen;
    }
  }
  .v-radio-item--custom {
    width: 100%;
    border: 1px solid #000;
    &.isHighlighted {
      background-color: #ccc;
      & ::v-deep.v-label {
        color: #000
      }
      //border: 4px solid lawngreen;
    }
    &.custom-img {
      width: fit-content;
      border: none;
      background-color: #fff;
    }

    .v-radio-label--custom {
      margin: 0 auto;
      padding: 0 1.5rem;
    }
    .no-margin {
      margin: 0 !important;
    }
    .v-row--custom {
      flex-wrap: nowrap;
      padding: 0 1rem;
      flex-direction: row;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .v-row--custom {
      flex-direction: column !important;
    }
  }
</style>
