import _mutations from './mutations';
import _actions from './actions';
import _getters from './getters';
import _state from './state';

const getDefaultState = () => ({ ..._state });
const state = getDefaultState();
const mutations = {
  ..._mutations,
  // eslint-disable-next-line no-shadow
  clearState(state) {
    Object.assign(state, getDefaultState())
  }
};
const getters = { ..._getters };
const actions = { ..._actions };
export default {
  state, mutations, getters, actions
}
