<template>
  <v-container>
    <EditorHeader
      :title='$ct("editorTitle")'
      hasSearch
      :search-label='$ct("editorSearchLabel")'
      @search='searchHandler'
      class='pb-4'>
      <template v-slot:actions>
        <v-btn @click='newUserHandler'>{{ $ct("Generic.createNew") }}</v-btn>
      </template>
    </EditorHeader>
    <v-skeleton-loader v-if='loading' type='table'/>
    <v-container v-if='!loading'>
      <v-data-table
        :headers='tableHeaders()'
        :items='users'
        :sort-by='["lastLogin"]'
        :search='search'
        multi-sort
        class='elevation-1 pt-2'
        :locale='$i18n.locale'
        :items-per-page='-1'>
        <template v-slot:item.createdOn='{item}'>
          {{ dayjs(item.createdOn).format("DD.MM.YYYY") }}
        </template>
        <template v-slot:item.lastLogin='{item}'>
          {{ dayjs(item.lastLogin).format("DD.MM.YYYY") }}
        </template>
        <template v-slot:item.actions='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-icon v-bind='attrs' v-on='on' small class='mr-2' @click.prevent.stop='editUser(item.id)'>mdi-pencil-outline</v-icon>
            </template>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-icon v-bind='attrs' v-on='on' small @click.prevent.stop='deleteUser(item.id)'>mdi-delete</v-icon>
            </template>
            <span>{{ $ct('actions.delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <GenericModal :open='warning' :outside-click='() => warning=false' :handleClose='closeAndReset' :handleSave='confirmAndDelete'>
        <template v-slot:title>{{$t("Generic.warning")}}</template>
        <template v-slot:content>{{$t('WarningCards.DeleteUser')}}</template>
      </GenericModal>
    </v-container>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import EditorHeader from '@/components/Generics/EditorHeader.vue';
import GenericModal from '@/components/Modals/GenericModal.vue';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';

export default {
  name: 'UserList',
  components: {
    GenericModal,
    EditorHeader
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    dayjs,
    isStringNullOrWhitespace,
    searchHandler(val) {
      this.search = val;
    },
    newUserHandler() {
      this.$router.push({ name: 'newUser' })
    },
    editUser(id) {
      this.$router.push({ name: 'editUser', params: { id } })
    },
    deleteUser(id) {
      this.warning = true;
      this.tmpId = id;
    },
    closeAndReset() {
      this.tmpId = undefined;
      this.warning = false;
    },
    confirmAndDelete() {
      this.$api.users.delete(this.tmpId);
      this.tmpId = undefined;
      this.warning = false;
    },
    tableHeaders() {
      return [
        {
          text: this.$ct('createdOn'), align: 'start', sortable: false, value: 'createdOn'
        },
        {
          text: this.$ct('lastLogin'), align: 'start', sortable: false, value: 'lastLogin'
        },
        {
          text: this.$ct('firstName'), align: 'start', sortable: false, value: 'firstName'
        },
        {
          text: this.$ct('lastName'), align: 'start', sortable: false, value: 'lastName'
        },
        {
          text: this.$ct('email'), align: 'start', sortable: false, value: 'email'
        },
        {
          text: this.$ct('actions.header'), align: 'end', sortable: false, value: 'actions'
        }
      ]
    }
  },
  data: () => ({
    search: null,
    tmpId: undefined,
    warning: false
  })
}
</script>

<style scoped>

</style>
