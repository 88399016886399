import RunRestModel from '@/api/RestModels/run'
import LoadingState from '@/api/LoadingState';
import ACTIONS from '@/store/storeActions';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';

export default class Run {
  constructor(store, i18n) {
    this.$store = store;
    this.$i18n = i18n;
  }

  async get(id) {
    const payload = {
      loadingState: LoadingState.INITIAL,
      payload: undefined
    }

    await this.$store.dispatch(ACTIONS.SET_RUNS, Object.assign(payload, { loadingState: LoadingState.LOADING }))
    try {
      const runs = await RunRestModel.get(id)
      await this.$store.dispatch(ACTIONS.SET_RUNS, Object.assign(payload, { payload: runs.payload, loadingState: LoadingState.LOADED }))
      return runs.payload;
    } catch ( err ) {
      await this.$store.dispatch(ACTIONS.SET_RUNS, Object.assign(payload, { loadingState: LoadingState.FAILED }))
      return err;
    }
  }

  async getRunInfo() {
    try {
      const { payload } = await RunRestModel.getRunInfo();
      await this.$store.dispatch(ACTIONS.SET_CURRENT_RUN, payload)
      return payload;
    } catch ( err ) {
      return err
    }
  }

  async create(payload) {
    await this.$store.dispatch(ACTIONS.CREATE_RUN, { loadingState: LoadingState.SAVING });
    try {
      const result = await RunRestModel.create(payload);
      await this.$store.dispatch(ACTIONS.CREATE_RUN, { loadingState: LoadingState.LOADED, payload: result.payload })
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.CREATE_RUN, { loadingState: LoadingState.FAILED })
      return err;
    }
  }

  async update(payload) {
    await this.$store.dispatch(ACTIONS.UPDATE_RUN, { loadingState: LoadingState.SAVING })
    try {
      const result = await RunRestModel.update( payload );
      await this.$store.dispatch(ACTIONS.UPDATE_RUN, { loadingState: LoadingState.LOADED, payload: result.payload })
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.UPDATE_RUN, { loadingState: LoadingState.FAILED })
      return err;
    }
  }

  async updateStatus(runId, newStatus) {
    await this.$store.dispatch(ACTIONS.UPDATE_RUN_STATUS, { loadingState: LoadingState.UPDATING })
    try {
      const result = await RunRestModel.updateStatus(runId, newStatus);
      await this.$store.dispatch(ACTIONS.UPDATE_RUN_STATUS, { loadingState: LoadingState.LOADED, payload: result.payload })
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.UPDATE_RUN_STATUS, { loadingState: LoadingState.FAILED })
      return err;
    }
  }

  async updatePassword(payload) {
    await this.$store.dispatch(ACTIONS.UPDATE_RUN, { loadingState: LoadingState.UPDATING });
    try {
      const result = await RunRestModel.updatePassword(payload);
      await this.$store.dispatch(ACTIONS.UPDATE_RUN, { loadingState: LoadingState.LOADED, payload: result.payload });
      return result.payload
    } catch (err) {
      await this.$store.dispatch(ACTIONS.UPDATE_RUN, { loadingState: LoadingState.FAILED });
      return err;
    }
  }

  async changeTokenStatus(payload) {
    await this.$store.dispatch(ACTIONS.UPDATE_TOKEN_STATUS, { loadingState: LoadingState.UPDATING })
    try {
      const result = await RunRestModel.changeTokenStatus(payload);
      await this.$store.dispatch(ACTIONS.UPDATE_TOKEN_STATUS, { loadingState: LoadingState.LOADED, payload: result.payload })
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.UPDATE_TOKEN_STATUS, { loadingState: LoadingState.FAILED })
      return err;
    }
  }

  async addToken(payload) {
    await this.$store.dispatch(ACTIONS.ADD_TOKEN, { loadingState: LoadingState.UPDATING })
    try {
      const result = await RunRestModel.addToken(payload);
      await this.$store.dispatch(ACTIONS.ADD_TOKEN, { loadingState: LoadingState.LOADED, payload: result.payload })
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.ADD_TOKEN, { loadingState: LoadingState.FAILED })
      return err
    }
  }

  async deleteToken(payload) {
    await this.$store.dispatch(ACTIONS.DELETE_TOKEN, { loadingState: LoadingState.UPDATING })
    try {
      const result = await RunRestModel.deleteToken(payload);
      await this.$store.dispatch(ACTIONS.DELETE_TOKEN, { loadingState: LoadingState.LOADED, payload: result.payload })
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.DELETE_TOKEN, { loadingState: LoadingState.FAILED })
      return err
    }
  }

  async triggerDelete(id) {
    await this.$store.dispatch(ACTIONS.DELETE_RUN, { loadingState: LoadingState.UPDATING })
    try {
      const result = await RunRestModel.triggerDelete(id);
      await this.$store.dispatch(ACTIONS.DELETE_RUN, { loadingState: LoadingState.LOADED, payload: result.payload });
      return result.payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.DELETE_RUN, { loadingState: LoadingState.FAILED })
      return err;
    }
  }

  async reinviteUser(userId) {
    try {
      const result = await RunRestModel.reinviteUser(userId);
      return result.payload;
    } catch (err) {
      return err;
    }
  }
}
