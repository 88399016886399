<template>
  <v-snackbar v-model="show" :timeout='this.color === "error" ? -1 : 5000' top :color="color" class=''>
    <span>{{text}}</span>
    <v-btn outlined fab color="white" @click="show=false"><v-icon>mdi-close</v-icon></v-btn>
  </v-snackbar>
</template>

<script>
import ACTIONS from '@/store/storeActions';
import { mapState } from 'vuex';

export default {
  name: 'MessageBar',
  data() {
    return {
      show: false,
      text: null,
      color: null,
    };
  },
  methods: {
  },
  computed: {
    ...mapState({
      snackbar: (state) => state.snackbar
    })
  },
  created() {
    this.$store.watch((state) => state.snackbar.text, () => {
      const msg = this.$store.state.snackbar.text;
      const { color } = this.$store.state.snackbar;

      if (msg && msg !== '') {
        this.color = color || 'info';
        this.show = true;
        this.text = this.$store.state.snackbar.text;
        this.$store.dispatch(ACTIONS.SET_SNACKBAR, { text: '', color: 'info' });
      }
    });
  },
};
</script>

<style scoped lang='scss'>
  ::v-deep .v-snack__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
