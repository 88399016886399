<template>
  <v-parallax dark src="/kim_splash_login.jpg" height='800'>
    <div class="kipat-login-background-blur"></div>
    <v-container class="kipat-login-container">
      <v-row align="center" align-content="center" justify="center">
        <v-card width='600'>
          <v-tabs
            :value='selectedTab'
            @change='(val) => $emit("updateTab", val)'
            centered
          >
            <v-tab v-for='item in tabItems' :key='item'>{{ item }}</v-tab>
          </v-tabs>
            <v-tabs-items :value='selectedTab'>
              <v-tab-item v-for='(item) in tabItems' :key='item'>
                <v-card flat>
                  <v-form v-model='codeValid' ref='code' @submit.prevent='$emit("submit")'>
                    <LoginCode
                      v-if='selectedTab === 0 && requirement === "none"'
                      :code='code'
                      :recaptchaValue='recaptchaValue'
                      :cookiesAccepted='isCookiesAccepted'
                      :disableRecaptcha='disableRecaptcha'
                      :rules='inputRules'
                      :valid='codeValid'
                      @onChange='val => $emit("onChange", val)'
                      @reload='$emit("reload")'
                      @submit='(evt) => handleLoginCodeSubmit(evt)'
                    />
                    <div v-else-if='requirement==="runPassword"'>
                      <v-card-title class='justify-center'>{{$ct('runPasswordTitle')}}</v-card-title>
                      <v-card-text class='px-10'><p>{{$ct('runPasswordDescription')}}</p></v-card-text>
                      <v-card-text align='center'>
                        <v-text-field
                          :type='runPasswordShow ? "text" : "password"'
                          :value='requirementForm.password'
                          @change='val => $emit("onChange", {type: "requirementForm", password: val})'
                          :rules='[inputRules.required]'
                          :label='$ct("runPassword")'
                          prepend-icon='mdi-lock'
                          :append-icon='runPasswordShow ? "mdi-eye-outlined" : "mdi-eye-off-outline"'
                          @click:append='runPasswordShow = !runPasswordShow'
                          required
                          @keyup.enter.prevent='handleRequirementSubmit'></v-text-field>
                      </v-card-text>
                    </div>
                    <div v-else-if='requirement==="userLogin"'>
                      <v-card-title class='justify-center'>{{$ct('userLoginTitle')}}</v-card-title>
                      <v-card-text class='px-10'>
                        <p>{{$ct('userLoginDescription')}}</p>
                      </v-card-text>
                      <v-card-text align='center'>
                        <v-text-field
                          :label='$ct("userEmail")'
                          :value='requirementForm.email'
                          @change='val => $emit("onChange", {type: "requirementForm", email: val})'
                          :rules='[inputRules.required, inputRules.email]'
                          prepent-icon='mdi-email'
                          @keyup.enter.prevent='$refs.userLoginPassword'/>
                        <v-text-field
                          :type='userPasswordShow ? "text" : "password"'
                          :label='$ct("userPassword")'
                          :value='requirementForm.password'
                          @change='val => $emit("onChange", {type: "requirementForm", password: val})'
                          :rules='[inputRules.required]'
                          prepend-icon='mdi-lock'
                          :append-icon='userPasswordShow ? "mdi-eye-outlined" : "mdi-eye-off-outline"'
                          @click:append='userPasswordShow = !userPasswordShow'
                          ref='userLoginPassword'
                          @keyup.enter.prevent='handleRequirementSubmit()'/>
                      </v-card-text>
                    </div>
                    <div v-else-if='requirement==="userToken"'>
                      <v-card-title class='justify-center'>{{$ct('userTokenTitle')}}</v-card-title>
                      <v-card-text class='px-10'><p>{{$ct('userTokenDescription')}}</p></v-card-text>
                      <v-card-text align='center'>
                        <v-text-field
                          :value='requirementForm.token'
                          @change='val => $emit("onChange", {type: "requirementForm", token: val})'
                          :rules='[inputRules.required]'
                          :label='$ct("userToken")'
                          required
                          @keyup.enter.prevent='handleRequirementSubmit'></v-text-field>
                      </v-card-text>
                    </div>
                  </v-form>
                  <v-form v-model='loginValid' ref='login'>
                    <LoginForm
                      v-if='selectedTab === 1'
                      :login='login'
                      :recaptchaValue='recaptchaValue'
                      :cookiesAccepted='isCookiesAccepted'
                      :rules='inputRules'
                      :valid='loginValid'
                      @onChange='val => $emit("onChange", val)'
                      @reload='$emit("reload")'
                      @submit='handleLoginFormSubmit'
                    />
                  </v-form>
                  <v-form v-model='requirementsValid' ref='requirements'>
                  </v-form>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
            <v-card-text align='center'>
              <Recaptcha :disableRecaptcha="disableRecaptcha" @recaptcha='(val) => $emit("recaptcha", val)'></Recaptcha>
              <v-btn
                v-if="isCookiesAccepted"
                :disabled="(selectedTab === 0 ? !codeValid : !loginValid) || (!recaptchaValue && !disableRecaptcha)"
                color="primary"
                class="my-5"
                @click="handleSubmit"
              >
                {{$t('Generic.confirm')}}
              </v-btn>
              <v-btn v-else color="primary" class="my-5" @click="$emit('reload')">
                {{$t('Generic.acceptCookies')}}
              </v-btn>
            </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
import LoginCode from '@/components/Login/LoginCode.vue';
import LoginForm from '@/components/Login/LoginForm.vue';
import inputRules from '@/libs/inputRules';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import Recaptcha from '@/components/Recaptcha.vue';

let recaptchaInterval
export default {
  name: 'LoginCard',
  components: {
    Recaptcha,
    LoginForm,
    LoginCode
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: true
    },
    /** @type RunCodeRequestModel */
    code: Object,
    /** @type LoginRequestModel */
    login: Object,
    recaptchaValue: String,
    selectedTab: {
      type: Number,
      required: true
    },
    requirement: {
      type: String,
      default: 'none'
    },
    requirementForm: {
      type: Object
    }
  },
  watch: {
    /*login: {
      deep: true,
      handler: () => {
        this.loginValid = this.$refs.login.validate();
      }
    },
    code: {
      deep: true,
      handler: () => {
        this.codeValid = this.$refs.code.validate();
      }
    }*/
  },
  computed: {
    isCookiesAccepted() {
      return this.$cookies.get(process.env.VUE_APP_GDPR_KEY) === 'true'
    }
  },
  data() {
    return {
      inputRules: inputRules(this),
      tabItems: ['Durchführung', 'Login'],
      disableRecaptcha: process.env.VUE_APP_DISABLE_RECAPTCHA === 'true',
      loginValid: false,
      codeValid: false,
      requirementsValid: false,
      userPasswordShow: false,
      runPasswordShow: false,
    }
  },
  methods: {
    handleLoginFormSubmit() {
      this.$emit('submit');
    },
    handleLoginCodeSubmit() {
      this.$emit('submit');
    },
    handleRequirementSubmit() {
      this.$emit('submit')
    },
    handleSubmit() {
      this.$emit('submit');
    },
    codeformsubmit() {
      this.$emit('submit')
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
