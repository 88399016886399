import BaseModel from '@/api/RestModels/baseModel';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import api from './client';

class UsersRestModel extends BaseModel {
  path = 'user';

  get = async (id) => (isStringNullOrWhitespace(id) ? api.get(this.path).json() : api.get(`${this.path}/${id}`).json());

  create = async (payload) => api.post(`${this.path}/create`, { json: payload }).json();

  update = async (payload) => api.post(`${this.path}/update`, { json: payload }).json();
}

export default new UsersRestModel()
