<template>
  <v-app>
    <NavToolbar v-if='$route.meta.hideNavigation && !$route.meta.hideToolbar' :mobile='$vuetify.breakpoint.mdAndDown'/>
    <Navigation v-if="!$route.meta.hideNavigation" :mobile="$vuetify.breakpoint.mdAndDown" />
    <v-main>
      <router-view/>
    </v-main>
    <message-bar />
    <cookie-consent />
    <footer-bar />
  </v-app>
</template>

<script>
import NavToolbar from '@/components/Navigation/NavToolbar.vue';
import CookieConsent from './components/CookieConsent';
import Navigation from './components/Navigation';
import MessageBar from './components/MessageBar';
import FooterBar from './components/FooterBar';

export default {
  name: 'App',
  data: () => ({
  }),
  methods: {
  },
  components: {
    NavToolbar,
    CookieConsent,
    Navigation,
    MessageBar,
    FooterBar,
  },
  computed: {}
};
</script>
