<template>
  <div>
    <p>{{ $ct( isAdmin ? 'adminTitle' : 'jobRecommendations') }}</p>
    <ul role='list'>
      <template v-for='recommendation in recommendations'>
        <li :key='recommendation'>
          {{ $t(`jobs.${recommendation}`)}}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RiasecJobRecommendations',
  props: {
    recommendations: {
      required: true
    },
    isAdmin: {
      default: false
    }
  }
}
</script>

<style scoped>

</style>
