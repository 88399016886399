import Vue from 'vue';

const EventBus = new Vue();

const EVENTS = {
  SET_COOKIES: 'SET_COOKIES',
  MCE_INSERT_TEXT: 'MCE_INSERT_TEXT',
  ASSESSMENT_CHANGED: 'ASSESSMENT_CHANGED'
}

export { EventBus, EVENTS };
