<template>
  <!-- Modal -->
  <v-dialog class="cookie-consent-dialog" v-model="dialog" persistent width="600">
    <v-card>
      <v-card-title class="headline">{{$t('CookieConsent.title')}}</v-card-title>
      <v-card-text v-html="$t('CookieConsent.description')" />
      <v-card-actions class="d-none d-sm-flex">
        <v-spacer></v-spacer>
        <v-btn text @click="denyCookies">{{$t('CookieConsent.decline')}}</v-btn>
        <v-btn color="primary" text @click="acceptAllCookies">{{$t('CookieConsent.accept')}}</v-btn>
      </v-card-actions>
      <v-card-actions class="d-flex d-sm-none">
        <v-spacer></v-spacer>
        <v-btn text @click="denyCookies">{{$t('CookieConsent.decline_small')}}</v-btn>
        <v-btn color="primary" text @click="acceptAllCookies">{{$t('CookieConsent.accept_small')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus, EVENTS } from '@/libs/eventBus';

export default {
  name: 'CookieConsent',
  data() {
    return {
      dialog: false,
      basicCookies: true,
      trackingCookies: false,
      disableRecaptcha: process.env.VUE_APP_DISABLE_RECAPTCHA === 'true'
    };
  },
  methods: {
    acceptAllCookies() {
      this.$cookies.set(process.env.VUE_APP_GDPR_KEY, true);
      this.loadExternalStuff();
      this.dialog = false;
      EventBus.$emit(EVENTS.SET_COOKIES, true);
    },
    denyCookies() {
      // this.$router.reload();
      // this.$cookies.set(process.env.VUE_APP_GDPR_KEY, true);
      this.dialog = false;
    },
    loadExternalStuff() {
      if (!this.disableRecaptcha) {
        const recaptchaScript = document.createElement('script');
        const language = this.$store.getters.language();
        recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?hl=${language}&render=explicit`);
        recaptchaScript.async = true;
        recaptchaScript.defer = true;
        document.head.appendChild(recaptchaScript);
      }
    },
  },
  mounted() {
    const self = this;
    const cookie = this.$cookies.get(process.env.VUE_APP_GDPR_KEY);

    if (!cookie) {
      // Clear the current state, if the cookie is not allowed
      this.$store.commit('clearState');

      this.$nextTick(() => {
        self.dialog = true;
      });
    } else {
      this.loadExternalStuff();
    }
  },
};
</script>

<style scoped type="text/scss">
  .cookie-consent-dialog{
    z-index: 1200;
    max-width: 400px;
  }
</style>
