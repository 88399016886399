<template>
  <div>
    <v-card-title class="justify-center">{{$ct('runCodeTitle')}}</v-card-title>
    <v-card-text class="px-10"><p>
      {{$ct('runCodeDescription')}}
    </p></v-card-text>
    <v-card-text align="center">
      <v-text-field
        v-model="code.Token"
        :counter="getCounter()"
        :rules="[(v) => !!v || $t('Rules.required'), (v) => (v && v.length >= 10 && v.length <= 12) || $t('Rules.codeLength')]"
        :label="$ct('runCode')"
        required
        @keyup.enter='valid && (recaptchaValue || !disableRecaptcha) ? submit() : ""'
      ></v-text-field>
    </v-card-text>
  </div>
</template>

<script>
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';

export default {
  name: 'LoginCode',
  props: {
    /** @type RunCodeRequestModel */
    code: Object,
    cookiesAccepted: {
      type: Boolean,
      default: false,
    },
    recaptchaValue: {
      type: String
    },
    disableRecaptcha: {
      type: Boolean
    },
    /** @type InputRules */
    rules: Object,
    valid: Boolean
  },
  data: () => ({
  }),
  methods: {
    getCounter() {
      if (!this.code.Code || ![12].includes(this.code.Code.length)) {
        return 12;
      }
      return this.code.Code.length
    },
    submit() {
      this.$emit('submit');
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
