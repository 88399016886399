<template>
  <v-container>
    <v-card>
      <v-card-title>{{ $ct('title') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols='6'>
            <v-text-field :label='$ct("firstName")' :value='user.firstName' @change='val => $emit("change", {field: "firstName", value: val})'/>
          </v-col>
          <v-col cols='6'>
            <v-text-field :label='$ct("lastName")' :value='user.lastName' @change='val => $emit("change", { field: "lastName", value: val})'/>
          </v-col>
          <v-col cols='6'>
            <v-text-field :label='$ct("email")' :value='user.email' @change='val => $emit("change", {field: "email", val})'/>
          </v-col>
          <v-col cols='6'>
            <v-select :label='$ct("userROle")' :items='userRoleOptions()' :value='user.role' @change='val => $emit("change", {field: "role", value: val})'/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols='6'>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'UserEditor',
  props: {
    /** @type AppUserRequest */
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    userRoleOptions() {
      return [
        {
          text: this.$ct('userRole.ADMINISTRATOR'),
          value: 'SYSADMIN'
        },
        {
          text: this.$ct('userRole.PARTICIPANT'),
          value: 'PARTICIPANT'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
