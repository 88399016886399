<template>
  <v-container>
    <EditorHeader
      :title='$route.params.id ? $ct("EditHeader") : $ct("CreateHeader")'
      class='pb-4'
    >
      <template v-slot:actions>
        <v-btn @click='saveProject' :disabled='!valid'>
          {{$t('Generic.save')}}
        </v-btn>
      </template>
    </EditorHeader>
    <ProjectEditor :project='tmp' @passwordDelete='tmp.password = ""' :validation='validations' @validate='validate' :isEdit='Boolean($route.params.id)'/>
  </v-container>
</template>

<script>
import EditorHeader from '@/components/Generics/EditorHeader.vue';
import ProjectEditor from '@/components/Projects/ProjectEditor.vue';
import { mapState } from 'vuex';
import LoadingState from '@/api/LoadingState';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';

export default {
  name: 'ProjectCreateOrEdit',
  components: { ProjectEditor, EditorHeader },
  computed: {
    ...mapState({
      project: (state) => state.projects.current,
    }),
  },
  data: () => ({
    tmp: {},
    valid: false,
    validations: {
      settings: false,
      criterions: false,
      choices: false,
      preview: false,
    }
  }),
  methods: {
    async saveProject() {
      // remove id from choiceoptions
      this.tmp.choiceOptions = this.tmp.choiceOptions.map((option) => {
        const tmpOption = { ...option };
        if (tmpOption.id) {
          delete tmpOption.id
        }
        return tmpOption;
      })
      // prevent password override
      if (!isStringNullOrWhitespace(this.tmp.id)) {
        if (this.tmp.password === 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx') delete this.tmp.password
        await this.$api.project.update(this.tmp);
        this.$router.push({ name: 'projectHome' })
        return;
      }
      await this.$api.project.create(this.tmp);
      this.$router.push({ name: 'projectHome' })
    },
    validate(val) {
      this.validations = { ...this.validations, ...val };
      this.valid = !Object.values(this.validations).includes(false);
    }
  },
  mounted() {
    this.tmp = { ...this.project }
  }
}
</script>

<style scoped>

</style>
