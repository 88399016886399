import BaseModel from '@/api/RestModels/baseModel';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import api from './client'

class RunRestModel extends BaseModel {
  path = 'run';

  get = async (id) => (isStringNullOrWhitespace(id) ? api.get(this.path) : api.get(`${this.path}/${id}`))

  getRunInfo = async () => api.get(`${this.path}/info`);

  create = async (payload) => api.post(`${this.path}/create`, { json: payload }).json();

  update = async (payload) => api.post(`${this.path}/update`, { json: payload }).json();

  updateStatus = async (id, status) => api.post(`${this.path}/updateStatus/${id}`, { json: status }).json();

  updatePassword = async (payload) => api.post(`${this.path}/updatePassword`, { json: payload }).json();

  changeTokenStatus = async (payload) => api.post(`${this.path}/changeTokenStatus`, { json: payload }).json();

  addToken = async (payload) => api.post(`${this.path}/addToken`, { json: payload }).json();

  deleteToken = async (payload) => api.delete(`${this.path}/deleteToken`, { json: payload }).json();

  triggerDelete = async (id) => api.delete(`${this.path}/${id}`).json();

  reinviteUser = async (userId) => api.post(`${this.path}/reinviteUser/${userId}`).json();
}

export default new RunRestModel()
