import ACTIONS from '@/store/storeActions';

const mutations = {
  [ACTIONS.CURRENT_CHOICE]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState
    if (payload) state.current = payload;
  },
  [ACTIONS.RESULT]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) state.result = payload;
  }
}

export default mutations;
