<template>
  <v-row>
    <template v-for='img in images'>
      <v-col cols='6' class='col-md-12' :key='`legend_${img.choiceKey}`'>
        <v-row>
          <v-col cols='2' class='pa-0' :key='`legend_img_${img.choiceKey}`'>
            <img class='flex-img' :src='img.reference' :alt='img.choiceKey'/>
          </v-col>
          <v-col cols='10' :key='`legend_desc_${img.choiceKey}`'>
            <p class='img-label'>{{ $t(`imageHelpers.labels.${img.choiceKey}`) }}</p>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>

export default {
  name: 'RiasecImageLegend',
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  methods: {}
}
</script>

<style scoped lang='scss'>
  .flex-img {
    max-width: 100%;
    max-height: 100%;
  }
  .img-label::first-letter {
    font-weight: bold
  }
</style>
