<template>
  <div>
    <ProjectFormWrapper title='Vorschau - Auswahl' :valid='valid' @validate='(val) => $emit("validate", val)'>
      <v-row>
        <v-col cols='12'>
          <v-text-field
              flat
              solo
              single-line
            :label='$ct("textVars.requestSubjectText")'
            :placeholder='$ct("textVars.requestSubjectText")'
            v-model='textVars.requestSubjectText'
          />
          <v-text-field
              flat
              solo
              single-line
              type='text'
            :label='$ct("textVars.comparisonCriterium")'
            :placeholder='$ct("textVars.comparisonCriterium")'
            v-model='textVars.comparisonCriterium'
          />
        </v-col>
        <ChoiceSelector :choices='choiceOptions'/>
      </v-row>
    </ProjectFormWrapper>
    <ProjectFormWrapper title='Vorschau - Bewertung' :valid='valid' @validate='(val) => $emit("validate", val)'>
      <v-row>
        <v-col cols='12'>
          <v-text-field
              flat
              solo
              single-line
              :label='$ct("textVars.requestSubjectText")'
              :placeholder='$ct("textVars.requestSubjectText")'
              v-model='textVars.requestSubjectText'
          />
          <v-text-field
            flat
            solo
            single-line
            :label='$ct("textVars.comparisonText")'
            :placeholder='$ct("textVars.comparisonText")'
            v-model='textVars.comparisonText'
          />
          <v-text-field
            flat
            solo
            single-line
            :label='$ct("textVars.scaleText")'
            :placeholder='$ct("textVars.scaleText")'
            v-model='textVars.scaleText'/>
          <RatingSelector :scaleText='textVars.scaleText'/>
        </v-col>
      </v-row>
    </ProjectFormWrapper>
  </div>
</template>

<script>
  import ChoiceSelector from '@/components/Choices/ChoiceSelector.vue';
  import ProjectFormWrapper from '@/components/Projects/Form/ProjectFormWrapper.vue';
  import RatingSelector from '@/components/Choices/RatingSelector.vue';

  export default {
    name: 'ProjectChoiceSettingsPreview',
    components: {
      RatingSelector,
      ChoiceSelector,
      ProjectFormWrapper
    },
    props: {
      valid: {
        required: true
      },
      textVars: {
        type: Object,
        default: () => ({
          requestSubjectText: '',
          comparisonText: '',
          comparisonCriterium: '',
          scaleText: ''
        })
      },
      choiceOptions: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      getComparisonText() {
        let result = this.textVars.comparisonText;
        if (this.textVars.comparisonText.includes('{A}')) {
          result = result.replace('{A}', this.choiceOptions[0].choice)
        }
        if (this.textVars.comparisonText.includes('{B}')) {
          result = result.replace('{B}', this.choiceOptions[1].choice)
        }
        return result;
      }
    }
  }
</script>

<style scoped>

</style>
