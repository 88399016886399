<template>
  <div @click='$emit("click")'>
    <div ref='donut'></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist';
import plotlyLocaleDE from 'plotly.js-locales/de';
import { ProjectStatus, RunStatus } from '@/libs/constants';

Plotly.register(plotlyLocaleDE)

export default {
  name: 'DonutChart',
  components: {},
  computed: {},
  props: {
    valueType: {
      type: String
    },
    value: {
      type: Object,
      default: () => {}
    },
    values: {
      type: Array,
      default: () => []
    },
    options: Object
  },
  watch: {},
  methods: {
    formatDataForProject() {
      const values = [...this.values];
      const activeRuns = values.filter((v) => v.status === RunStatus.RUNNING);
      const finishedRuns = values.filter((v) => v.status === RunStatus.FINISHED);
      const draftRuns = values.filter((v) => v.status === RunStatus.DRAFT);
      return [
        {
          values: [draftRuns.length, activeRuns.length, finishedRuns.length],
          labels: [
            this.$t(`Run.${RunStatus.DRAFT}`),
            this.$t(`Run.${RunStatus.RUNNING}`),
            this.$t(`Run.${RunStatus.FINISHED}`),
          ],
          marker: {
            colors: ['rgb(255, 82, 82)', 'rgb(251, 140, 0)', 'rgb(76, 175, 80)'],
          },
          hoverinfo: 'label+percent',
          textinfo: 'none',
          hole: 0.4,
          type: 'pie',
          automargin: true
        }
      ]
    },
    formatDataForRun() {
      const value = { ...this.value };
      let notStarted = 0;
      if (value.project.participantType !== 'ANONYMOUS') notStarted = ((value.overrides.participants || 0) - (value.openInstances || 0) - (value.completedInstances || 0))
      const d = {
        values: [notStarted, value.openInstances || 0, value.completedInstances || 0],
        labels: [this.$t('Instances.NotStarted'), this.$t('Instances.Open'), this.$t('Instances.Completed')],
        marker: {
          colors: ['rgb(255, 82, 82)', 'rgb(251, 140, 0)', 'rgb(76, 175, 80)']
        },
        hoverinfo: 'label+value',
        textinfo: 'none',
        hole: 0.5,
        type: 'pie'
      }

      return [d]
    },
    generatePlot() {
      const self = this;
      let data = [];
      let opts = [];
      switch (this.valueType) {
        case 'project':
          data = this.formatDataForProject()
          opts = {
            title: 'Aufteilung Durchführungen',
            annotations: [
              {
                font: {
                  size: 20
                },
                showarrow: false,
                text: this.values.length,
              }
            ],
            height: 300,
            width: 300,
            showlegend: false,
          }
          break;
        case 'run':
          data = this.formatDataForRun()
          opts = {
            title: this.value.title || this.value.runToken,
            //height: 250,
            //width: 250,
            height: 250,
            showlegend: false,
            annotations: [{
              font: {
                size: 20
              },
              showarrow: false,
              text: this.value.overrides.participantType !== 'ANONYMOUS' ? this.value.overrides.participants : Math.round(this.value.openInstances + this.value.completedInstances) || 0,
            }]
          }
          break;
        default:
          data = [];
      }
      Plotly.newPlot(this.$refs.donut, data, opts, { displayModeBar: false, responsive: true })
    },
    bindEvent() {
      this.$refs.donut.on('plotly_click', () => this.$emit('click'))
    }
  },
  created() {
    Plotly.setPlotConfig({ locale: this.$i18n.locale })
  },
  mounted() {
    this.generatePlot();
    this.bindEvent();
  }
}
</script>

<style scoped>

</style>
