<template>
  <UsersComponent
    :loading='!usersLoaded'
    :users='users'
    :componentType='componentType'/>
</template>

<script>
import LoadingState from '@/api/LoadingState';
import { mapState } from 'vuex';
import UsersComponent from '@/components/Settings/Users/UsersComponent.vue';

export default {
  name: 'UserAdministrationPage',
  components: { UsersComponent },
  data: () => ({
    componentType: 'list'
  }),
  watch: {
    $route(to, from) {
      this.componentType = ['newUser', 'editUser'].includes(this.$route.name) ? 'createOrEdit' : 'list'
    }
  },
  computed: {
    ...mapState({
      usersLoaded: (state) => state.projects.loadingState === LoadingState.LOADED,
      users: (state) => state.users.users
    })
  },
  mounted() {
    const self = this;
    this.componentType = ['newUser', 'editUser'].includes(this.$route.name) ? 'createOrEdit' : 'list'
    this.$api.users.get();
  }
}
</script>

<style scoped>

</style>
