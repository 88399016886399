<template>
  <div>
    <h4>{{ $ct('diagnosticGoal') }}</h4>
    <p>{{ $ct('diagnosticExplanation') }}</p>
    <v-row>
      <template v-for='img in images'>
        <v-col cols='12' class='col-md-6' :key='`diagnostic_${img.choiceKey}`'>
          <v-row>
            <v-col cols='3'>
              <img class='flex-img' :src='img.reference' :alt='img.choiceKey'/>
            </v-col>
            <v-col cols='9'>
              <p class=''>{{ $ct( `diagnostic.${img.choiceKey}`) }}</p>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col cols='12'>
        <p><span class='font-weight-bold'>{{ $ct(`stability`) }}:</span> {{ $ct('diagnostic.stability') }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='12'>
        <p><span class='font-weight-bold'>{{ $ct(`dominance`) }}:</span> {{ $ct('diagnostic.dominance') }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='12'>
        <p><span class='font-weight-bold'>{{ $ct(`flag`) }}:</span> {{ $ct('diagnostic.flag') }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'RiasecDiagnostics',
  props: {
    images: {
      type: Array,
      required: true
    }
  },
}
</script>

<style scoped lang='scss'>
.flex-img {
  max-width: 100%;
  max-height: 100%;
}
.img-label::first-letter {
  font-weight: bold
}
</style>
