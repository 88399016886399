import BaseModel from '@/api/RestModels/baseModel';
import api from './client'

class ImageLibraryRestModel extends BaseModel {
  path = 'imageLibrary';

  getAll = async () => api.get(this.path);

  get = async (lib) => api.get(`${this.path}/${lib}`);

}

export default new ImageLibraryRestModel();
