const ChoiceType = Object.freeze({
  IMAGE: 'IMAGE',
  TEXT: 'TEXT'
})
const imageLibraries = Object.freeze(
  ['pairs']
)
const foods = Object.freeze( [
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/foods/anh-nguyen-kcA-c3f_3FE-unsplash.jpg',
    id: 1,
    choice: 1
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/foods/chad-montano-eeqbbemH9-c-unsplash.jpg',
    id: 2,
    choice: 2
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/foods/chad-montano-MqT0asuoIcU-unsplash.jpg',
    id: 3,
    choice: 3
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/foods/lidye-1Shk_PkNkNw-unsplash.jpg',
    id: 4,
    choice: 4
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/foods/ian-dooley-TLD6iCOlyb0-unsplash.jpg',
    id: 5,
    choice: 5
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/foods/brooke-lark--F_5g8EEHYE-unsplash.jpg',
    id: 6,
    choice: 6
  },
])
const animals = Object.freeze([
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/animals/wexor-tmg-L-2p8fapOA8-unsplash.jpg',
    id: 1,
    choice: 1
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/animals/sid-balachandran-_9a-3NO5KJE-unsplash.jpg',
    id: 2,
    choice: 2
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/animals/laura-college-K_Na5gCmh38-unsplash.jpg',
    id: 3,
    choice: 3
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/animals/hans-jurgen-mager-qQWV91TTBrE-unsplash.jpg',
    id: 4,
    choice: 4
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/animals/hans-jurgen-mager-Fbdr6gXJBVY-unsplash.jpg',
    id: 5,
    choice: 5
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/animals/edgar-nKC772R_qog-unsplash.jpg',
    id: 6,
    choice: 6
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/animals/deepak-nautiyal-Nbv7PkL_rvI-unsplash.jpg',
    id: 7,
    choice: 7
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/animals/andy-holmes-D6TqIa-tWRY-unsplash.jpg',
    id: 8,
    choice: 8
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/animals/alexandru-rotariu-o_QTeyGVWjQ-unsplash.jpg',
    id: 9,
    choice: 9
  },
])

const pairs = Object.freeze([
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/bildvergleich/DO.JPG',
    id: 2,
    color: 'rgb(155,187,88)',
    descriptionKey: 'do',
    choice: 'do'
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/bildvergleich/ORGANIZE.JPG',
    id: 4,
    color: 'rgb(255, 192, 0)',
    descriptionKey: 'organize',
    choice: 'organize'
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/bildvergleich/SELL.JPG',
    id: 5,
    color: 'rgb(255, 102, 0)',
    descriptionKey: 'sell',
    choice: 'sell'
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/bildvergleich/HELP.JPG',
    id: 3,
    color: 'rgb(204, 0, 1)',
    descriptionKey: 'help',
    choice: 'help'
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/bildvergleich/CREATE.JPG',
    id: 1,
    color: 'rgb(0, 51, 204)',
    descriptionKey: 'create',
    choice: 'create'
  },
  {
    type: ChoiceType.IMAGE,
    reference: '/imageLibraries/bildvergleich/THINK.JPG',
    id: 6,
    color: 'rgb(1,176,241)',
    descriptionKey: 'think',
    choice: 'think'
  },
])

export {
  ChoiceType, imageLibraries, foods, animals, pairs
}
