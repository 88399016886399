<template>
  <v-container>
    <BreadCrumbs
      :items='[
        {
          text: $t("breadcrumbs.runs"),
          disabled: false,
          exact: true,
          to: { name: "runHome" }
        },
        {
          text: $t("breadcrumbs.runEdit"),
          disabled: true,
          to: "runEdit"
        }
      ]'/>
    <RunCreateOrEdit/>
  </v-container>
</template>

<script>
import RunCreateOrEdit from '@/components/Runs/RunCreateOrEdit.vue';
import BreadCrumbs from '@/components/Generics/BreadCrumbs.vue';

export default {
  name: 'RunEdit',
  components: { BreadCrumbs, RunCreateOrEdit }
}
</script>

<style scoped>

</style>
