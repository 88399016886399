<template>
  <v-dialog class="loading-dialog" v-model="loading" width="400" persistent>
    <v-card>
      <v-card-text>
        <!--  Loader  -->
        <v-container>
          <v-row>
            <v-col cols='12' align='center' class='justify-center'>
              <h2>{{$ct('loading')}}</h2>
              <p>{{ $ct('handlingLoading') }}</p>
              <spinner />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'LoadingDialog',
  components: {
    Spinner,
  },
  props: ['loading']
}
</script>
