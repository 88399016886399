<template>
  <v-container>
    <BreadCrumbs
      :items='[
            {
              text: $t("breadcrumbs.projects"),
              disabled: false,
              exact: true,
              to: { name: "projectHome" }
            },
            {
              text: $t("breadcrumbs.projectEdit"),
              disabled: true,
              to: "projectEdit"
            }
        ]'
      />
    <!--  <PageHeader />-->
    <ProjectCreateOrEdit/>
  </v-container>

</template>

<script>
import ProjectCreateOrEdit from '@/components/Projects/ProjectCreateOrEdit.vue';
import BreadCrumbs from '@/components/Generics/BreadCrumbs.vue';

export default {
  name: 'ProjectEdit',
  components: { BreadCrumbs, ProjectCreateOrEdit }
}
</script>

<style scoped>

</style>
