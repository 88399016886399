<template>
  <v-icon v-if='enabled'>mdi-check</v-icon>
  <v-icon v-else>mdi-close</v-icon>
</template>

<script>
export default {
  name: 'BooleanIconTableField',
  props: {
    enabled: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
