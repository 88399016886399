<template>
  <v-container class='forced-center'>
    <EditorHeader
      :title='run.projectTextVariables.requestSubjectText'
      :subtitle='step === "choice" ? run.projectTextVariables.comparisonCriterium : run.projectTextVariables.comparisonText'
    />
    <ChoiceSelector
      v-if='step === "choice"'
      :choices='currentChoice'
      :current='selectedChoice'
      @selection='selection'
      :loading='loading || updating'
    ></ChoiceSelector>
    <RatingSelector
      v-if='step === "rating"'
      :selectedChoice='selectedChoice'
      :currentChoiceOptions='currentChoice'
      :nonDefaultRunType='run.resultsView!=="DEFAULT"'
      @rating='rating'
      :value='rating'
      :loading='loading'/>
    <v-container>
      <v-row>
        <v-col cols='12' :class='`d-flex ${step === "choice" ? "justify-end" : "justify-space-between"}`'>
          <template v-if='step === "rating" || (selectedChoice && selectedChoice.choice === "NONE")'>
              <v-btn v-if='selectedChoice.choice !== "NONE"' secondary @click='step = "choice"'>Zurück zur Auswahl</v-btn>
              <v-btn :disabled='updating' primary @click='setChoice()'>Bewerten</v-btn>
          </template>
          <template v-if='step === "choice" && (selectedChoice && selectedChoice.choice !== "NONE") || !selectedChoice'>
            <v-btn primary @click='step = "rating"'>Zur Bewertung</v-btn>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <LoadingDialog :loading='updating' />
    <!-- Add Loading Modal-->
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import LoadingState from '@/api/LoadingState';
import RatingSelector from '@/components/Choices/RatingSelector.vue';
import ChoiceSelector from '@/components/Choices/ChoiceSelector.vue';
import EditorHeader from '@/components/Generics/EditorHeader.vue';
import LoadingDialog from '@/components/LoadingDialog.vue';

export default {
  name: 'ChoiceComponent',
  components: {
    LoadingDialog,
    EditorHeader,
    ChoiceSelector,
    RatingSelector
  },
  data: () => ({
    state: 'choice',
    step: 'choice',
    selectedChoice: undefined,
    selectedRating: undefined,
    current: undefined,
    updating: false,
  }),
  computed: {
    ...mapState({
      run: (state) => state.runs.current,
      currentChoice: (state) => state.choice.current || [],
      loading: (state) => state.choice.loadingState === LoadingState.LOADING,
    }),
  },
  methods: {
    selection(val) {
      const self = this;
      if (val === 'NONE') {
        this.current = { choices: [...this.currentChoice], rating: 1 }
        this.selectedChoice = { choice: 'NONE', identifier: val };
        this.selectedRating = 1;
        console.log('Selection: ', this.selectedChoice, 'Current: ', this.currentChoice)
        return;
      }
      if (this.selectedChoice?.choice === 'NONE' && val !== 'NONE') {
        this.selectedRating = undefined
      }
      this.selectedChoice = { ...this.currentChoice.find((x) => x.identifier === val) }
      console.log('Selection: ', this.selectedChoice, 'Current: ', this.currentChoice)
    },
    rating(val) {
      this.selectedRating = val;
      let choiceA
      let choiceB;
      if (this.selectedChoice.identifier === 'NONE') {
        choiceA = { ...this.currentChoice[0] };
        choiceB = { ...this.currentChoice[1] };
        this.current = { choices: [choiceA, choiceB], rating: 1 }
      } else {
        choiceA = this.selectedChoice;
        choiceB = { ...this.currentChoice.find((x) => x.identifier !== this.selectedChoice.identifier) };
      }

      // choices are already in correct order
      this.current = { choices: [choiceA, choiceB], rating: val };
    },
    async setChoice() {
      const self = this;
      // for now just get a new choice
      this.updating = true;
      const result = await self.$api.choice.setChoice(this.current);
      if (result.type === 'RESULT') {
        self.$router.push('result');
      }
      // await self.$api.choice.setChoice(this.current);
      this.step = 'choice';
      this.selectedChoice = { choice: undefined, value: null };
      this.current = undefined;
      this.updating = false;
    },
    async loadChoice() {
      const self = this;
      const result = await self.$api.choice.getChoice();
      if (result.type === 'RESULT') await self.$router.push('result');
    }
  },
  mounted() {
    const self = this;
    self.loadChoice();
  }
}
</script>

<style scoped lang='scss'>
  .forced-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 100vh;
  }
</style>
