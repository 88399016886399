<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <v-form :value='valid' @input='handle' @blur='handle' @lazy='handle' @submit.prevent>
        <slot></slot>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ProjectFormWrapper',
  props: {
    title: {
      type: String,
      default: () => ''
    },
    valid: {
      required: true
    }
  },
  methods: {
    handle(val) {
      this.$emit('validate', val)
    }
  }
}
</script>

<style scoped>

</style>
