<template>
  <ChoiceComponent/>
</template>

<script>
import ChoiceComponent from '@/components/ChoiceComponent.vue';

export default {
  name: 'ChoicePage',
  components: { ChoiceComponent },
}
</script>

<style scoped>

</style>
