<template>
  <v-container>
    <v-skeleton-loader v-if='typeof project !== "object"'></v-skeleton-loader>
    <v-row v-else>
      <v-col cols='6'>
        <ProjectSettings
          :valid='validation.settings'
          @validate='(val) => $emit("validate", {settings: val})'
          :project='project'
          :title='project.title'
          :password='project.password'
          :passwordSet='typeof copy.password === "string" && copy.password.trim().length > 0'
          :participants='project.participants'
          :participantType='project.participantType'
          :projectStatus='project.projectStatus'
          :projectVersion='project.version'
          :resultsView='project.resultsView'
          @change='update'
          @passwordDelete='$emit("passwordDelete")'
        />
      </v-col>
      <v-col cols='6'>
        <ProjectAbortCriterions
          :valid='validation.criterions'
          @validate='(val) => $emit("validate", { criterions: val })'
          :criterions='project.abortCriterions' @change='update' :order='order'
          :isEdit='isEdit'
        />
      </v-col>
      <v-col cols='12'>
        <ProjectChoiceOptions
          :valid='validation.choices'
          @validate='(val) => $emit("validate", { choices: val })'
          :choiceOptions='project.choiceOptions' @change='update' @addChoice='addChoice' @removeChoice='removeChoice' @addImages='addImages' @removeImages='removeImages'/>
      </v-col>
      <v-col cols='12'>
        <ProjectChoiceSettingsPreview
          :valid='validation.preview'
          @validate='(val) => $emit("validate", { preview: val })'
          :textVars='project.projectTextVariables'
          :choiceOptions='getRandomChoices()'
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProjectAbortCriterions from '@/components/Projects/Form/ProjectAbortCriterions.vue';
import ProjectSettings from '@/components/Projects/Form/ProjectSettings.vue';
import ProjectChoiceOptions from '@/components/Projects/Form/ProjectChoiceOptions.vue';
import ProjectChoiceSettings from '@/components/Projects/Form/ProjectChoiceSettings.vue';
import ProjectFormWrapper from '@/components/Projects/Form/ProjectFormWrapper.vue';
import ChoiceOptions from '@/components/Choices/ChoiceOptions.vue';
import ChoiceSelector from '@/components/Choices/ChoiceSelector.vue';
import RatingSelector from '@/components/Choices/RatingSelector.vue';
import ProjectChoiceSettingsPreview from '@/components/Projects/ProjectChoiceSettingsPreview.vue';
import { mapState } from 'vuex';
import LoadingState from '@/api/LoadingState';

export default {
  name: 'ProjectEditor',
  components: {
    ProjectChoiceSettingsPreview,
    ProjectChoiceOptions,
    ProjectSettings,
    ProjectAbortCriterions
  },
  props: {
    validation: {
      type: Object,
      default: () => ({
        settings: false,
        criterions: false,
        choices: false,
        preview: false
      }),
      required: true
    },
    project: {
      type: Object
    },
    isEdit: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.projects.loadingState === LoadingState.LOADING
    }),
  },
  data() {
    return {
      choiceOptionsLength: 0,
      randomChoice: [],
      copy: {},
      order: 0,
    }
  },
  watch: {
    'project.choiceOptions': function (val) {
      this.order = val.length
    }
  },
  methods: {
    getRandomChoices() {
      if (!this.project.choiceOptions) return [];
      return [...this?.project?.choiceOptions].sort(() => 0.5 - Math.random()).slice(0, 2)
    },
    update(props) {
      const {
        field, fieldType, index, value
      } = props;
      switch ( fieldType ) {
        case 'Array':
          this.project[field][index] = value
          break;
        default:
          this.project[field] = value
      }
    },
    addImages(props) {
      this.project.choiceOptions = [...props];
    },
    addChoice(props) {
      this.project.choiceOptions.push(props);
    },
    removeImages() {
      this.project.choiceOptions = this.copy.choiceOptions || [];
    },
    removeChoice(props) {
      this.project.choiceOptions.splice(props, 1);
    },
  },
  mounted() {
    this.copy = { ...this.project }
  }
}
</script>

<style scoped>

</style>
