import { LoginPurpose } from '@/api/RestModels/login';
import ChoicePage from '@/views/participants/ChoicePage.vue';
import ResultsPage from '@/views/participants/ResultsPage.vue';

const meta = Object.freeze({
  hideNavigation: true,
  hideToolbar: false,
  requiresAuthentication: true,
  authenticationLevel: LoginPurpose.PARTICIPATION
})

export default [
  {
    path: '/participant/choice',
    name: 'choice',
    component: ChoicePage,
    meta
  },
  {
    path: '/participant/result',
    name: 'result',
    component: ResultsPage,
    meta
  }
]
