<template>
  <div>
    <v-row>
      <v-col cols='12'><h1>{{title}}</h1></v-col>
      <v-col cols='12' v-if='!isStringNullOrWhitespace(subtitle)'><p class='subtitle-1'>{{subtitle}}</p></v-col>
    </v-row>
    <v-row>
      <v-col cols='12' class='col-lg-8 col-md-8 col-sm-12 justify-center align-center d-flex'>
        <v-row>
          <v-col class='col-auto'>
            <slot name='actions'></slot>
<!--            <template v-for='(btn, bIndex) in btns'>-->
<!--              <v-btn-->
<!--                v-if='!btn.disabled'-->
<!--                :key='`${ bIndex }_${ btn.label }`'-->
<!--                :class='btn.classes'-->
<!--                @click='btn.action'>-->
<!--                <v-icon v-if='btn.icon'>{{ btn.icon }}</v-icon>-->
<!--                {{btn.label}}-->
<!--              </v-btn>-->
<!--            </template>-->
          </v-col>

        </v-row>
      </v-col>
      <v-col v-show='hasSearch' cols='12' class='col-lg-4 col-md-4 col-sm-12 justify-center'>
        <v-text-field
          class='mt-0 pt-0'
          v-model='search'
          append-icon='mdi-magnify'
          :label='searchLabel'
          single-line
          hide-details
          @change='changeSearch(search)'
          @keyup='changeSearch(search)'/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';

export default {
  name: 'EditorHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: ''
    },
    /** @type {import('vue').PropOptions<EditorBtn[]>} */
    // btns: {
    //   type: Array,
    //   default: () => []
    // },
    hasSearch: {
      type: Boolean,
      default: false
    },
    searchLabel: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    search: ''
  }),
  methods: {
    isStringNullOrWhitespace,
    changeSearch(search) {
      this.$emit('search', search);
    },
    clearSearch(search) {
      this.search = '';
      this.$emit('search', search);
    }
  }
}
</script>

<style scoped>

</style>
