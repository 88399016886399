var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('BreadCrumbs',{attrs:{"items":[
      {
        text: _vm.$t("breadcrumbs.runs"),
        disabled: false,
        exact: true,
        to: { name: "runHome" }
      },
      {
        text: _vm.$t("breadcrumbs.runEdit"),
        disabled: true,
        to: "runEdit"
      }
    ]}}),_c('RunCreateOrEdit')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }