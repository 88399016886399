<template>
  <v-container>
    <BreadCrumbs :items='[{text: $t("breadcrumbs.runs"), disabled: true}]'/>
    <EditorHeader
      :title='$ct("editorTitle")'
      hasSearch
      :searchLabel='$ct("editorSearchLabel")'
      @search='searchHandler'
      class='pb-4'>
      <template v-slot:actions>
        <v-btn @click='newRunHandler' :disabled='selectableProjects.length <= 0'>
          Neue Durchführung
        </v-btn>
      </template>
    </EditorHeader>
    <v-skeleton-loader v-if='loading' type='table'/>
    <v-container v-if='!loading'>
      <v-data-table
        :headers='tableHeaders()'
        :items='runs'
        :sort-by='["date"]'
        :search='search'
        class='elevation-1 pt-2'
        :locale='$i18n.locale'
        @click:row='tableClickHandler'
        :items-per-page='-1'>
        <template v-slot:item.status='{ item }'>
          <v-icon :color='item.status === RunStatus.DRAFT ? "error" : item.status === RunStatus.FINISHED ? "success" : "warning"'>mdi-circle</v-icon>
        </template>
        <template v-slot:item.createdOn='{ item }'>
          {{ dayjs(item.createdOn).format("DD.MM.YYYY") }}
        </template>
        <template v-slot:item.actions='{ item }'>
          <v-row v-if='item.status === RunStatus.DRAFT' justify='end'>
            <v-col class='flex-grow-0'>
              <v-tooltip top>
                <template v-slot:activator='{on, attrs}'>
                  <v-icon v-bind='attrs' v-on='on' @click.prevent.stop='updateStatus(item.id, RunStatus.RUNNING)'>mdi-play</v-icon>
                </template>
                <span>{{ $ct('actions.start') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if='item.status === RunStatus.RUNNING' justify='end'>
            <v-col class='flex-grow-0'>
              <v-tooltip top>
                <template v-slot:activator='{ on, attrs }'>
                  <v-icon v-bind='attrs' v-on='on' class='mr-2' @click.prevent.stop='updateStatusWithWarning(item.id, RunStatus.FINISHED)'>mdi-check</v-icon>
                </template>
                <span>{{ $ct('actions.finish') }}</span>
              </v-tooltip>
            </v-col>
            <v-col class='flex-grow-0'>
              <v-tooltip top>
                <template v-slot:activator='{ on, attrs }'>
                  <v-icon v-bind='attrs' v-on='on' class='mr-2' @click.prevent.stop='resultsPage(item.id)'>mdi-chart-gantt</v-icon>
                </template>
                <span>{{ $ct('actions.results') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if='item.status === RunStatus.FINISHED' justify='end'>
            <v-col class='flex-grow-0'>
              <v-tooltip top>
                <template v-slot:activator='{ on, attrs }'>
                  <v-icon v-bind='attrs' v-on='on' class='mr-2' @click.prevent.stop='deleteWarn(item)'>mdi-cancel</v-icon>
                </template>
                <span>{{ $ct('actions.delete') }}</span>
              </v-tooltip>
            </v-col>
            <v-col class='flex-grow-0'>
              <v-tooltip top>
                <template v-slot:activator='{ on, attrs }'>
                  <v-icon v-bind='attrs' v-on='on' class='mr-2' @click.prevent.stop='resultsPage(item.id)'>mdi-chart-gantt</v-icon>
                </template>
                <span>{{ $ct('actions.results') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      <GenericModal :open='warning' :outside-click='() => warning=false' :handleClose='closeAndReset' :handleSave='confirmAndUpdate'>
        <template v-slot:title>ACHTUNG</template>
        <!--        <template v-slot:content>{{ $ct() }}</template>-->
        <template v-slot:content>Beendete Durchführungen können nicht wieder eröffnet werden. Wollen Sie die Durchführung jetzt wirklich beenden?</template>
      </GenericModal>
      <GenericModal :open='deleteWarning' :outside-click='handleDeleteClose' :handle-close='handleDeleteClose' :handle-save='triggerDelete'>
        <template v-slot:title>{{ $t('Generic.warningTitle')}}</template>
        <template v-slot:content>{{ $ct('deleteContent', {title: potentialDelete.title }) }} {{ $t('Generic.warningContent') }}</template>
      </GenericModal>
    </v-container>
    <ProjectSelector :open='projectSelector' :projects='selectableProjects' @confirm='handleConfirm' @close='handleClose'/>
  </v-container>
</template>

<script>
import GenericModal from '@/components/Modals/GenericModal.vue';
import { mapGetters, mapState } from 'vuex';
import LoadingState from '@/api/LoadingState';
import dayjs from 'dayjs';
import { isStringNullOrWhitespace } from '@/libs/stringHelpers';
import ACTIONS from '@/store/storeActions';
import EditorHeader from '@/components/Generics/EditorHeader.vue';
import ProjectSelector from '@/components/Runs/ProjectSelector.vue';
import { ProjectStatus, RunStatus } from '@/libs/constants'
import BreadCrumbs from '@/components/Generics/BreadCrumbs.vue';

export default {
  name: 'RunList',
  components: {
    BreadCrumbs,
    ProjectSelector,
    EditorHeader,
    GenericModal
  },
  computed: {
    RunStatus() {
      return RunStatus
    },
    ...mapState({
      runs: (state) => state.runs.runs,
      loadingState: (state) => state.runs.loadingState,
      loading: (state) => state.runs.loadingState === LoadingState.LOADING,
      selectableProjects: (state) => state.projects.projects.filter((project) => project.projectStatus === ProjectStatus.ACTIVE)
    }),
    ...mapGetters(['getRunById'])
  },
  data: () => ({
    search: null,
    projectSelector: false,
    tmpId: undefined,
    tmpStatus: undefined,
    warning: false,
    deleteWarning: false,
    potentialDelete: {}
  }),
  methods: {
    dayjs,
    isStringNullOrWhitespace,
    deleteWarn(item) {
      this.potentialDelete = item;
      this.deleteWarning = true;
    },
    handleDeleteClose() {
      this.potentialDelete = {};
      this.deleteWarning = false;
    },
    triggerDelete() {
      this.$api.run.triggerDelete(this.potentialDelete.id);
      this.handleDeleteClose();
    },
    resultsPage(itemId) {
      this.$router.push({ name: 'results', params: { id: itemId } })
    },
    searchHandler(val) {
      this.search = val;
    },
    tableClickHandler(val) {
      this.$store.dispatch(ACTIONS.SET_CURRENT_RUN, this.getRunById(val.id))
      this.$router.push({ name: 'runEdit', params: { id: val.id } })
    },
    newRunHandler() {
      // open a modal with active projects
      this.projectSelector = true;
    },
    async handleConfirm(val) {
      this.projectSelector = false;
      const newRun = await this.$api.run.create(val)
      await this.$store.dispatch(ACTIONS.SET_CURRENT_RUN, newRun)
      this.$router.push({ name: 'runEdit', params: { id: newRun.id } })
    },
    handleClose() {
      this.projectSelector = false;
    },
    tableHeaders() {
      return [
        {
          text: this.$ct('runStatus'), align: 'start', sortable: false, value: 'status'
        },
        {
          text: this.$ct('createdOn'), align: 'start', sortable: false, value: 'createdOn'
        },
        {
          text: this.$ct('project'), align: 'start', sortable: false, value: 'project.title'
        },
        {
          text: this.$ct('title'), align: 'start', sortable: false, value: 'title'
        },
        {
          text: this.$ct('actions.title'), align: 'end', sortable: false, value: 'actions'
        }

      ]
    },
    updateStatus(runId, status) {
      this.$api.run.updateStatus(runId, status);
    },
    closeAndReset() {
      this.tmpId = undefined;
      this.tmpStatus = undefined;
      this.warning = false;
    },
    confirmAndUpdate() {
      this.updateStatus(this.tmpId, this.tmpStatus);
      this.tmpId = undefined;
      this.tmpStatus = undefined;
      this.warning = false;
    },
    updateStatusWithWarning(runId, status) {
      this.warning = true;
      this.tmpId = runId;
      this.tmpStatus = status;
    }
  },
  mounted() {
    /*if ((!this.runs || !Array.isArray(this.runs)) && this.loadingState !== LoadingState.Loading && this.loadingState !== LoadingState.LOADED) */this.$api.run.get();
  }
}
</script>

<style scoped>

</style>
