export default Object.freeze(
  {
    INITIAL: 'INITIAL',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    FAILED: 'FAILED',
    SAVING: 'SAVING',
    UPDATING: 'UPDATING'
  }
)
