var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('BreadCrumbs',{attrs:{"items":[
          {
            text: _vm.$t("breadcrumbs.projects"),
            disabled: false,
            exact: true,
            to: { name: "projectHome" }
          },
          {
            text: _vm.$t("breadcrumbs.projectEdit"),
            disabled: true,
            to: "projectEdit"
          }
      ]}}),_c('ProjectCreateOrEdit')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }