import ChoiceRestModel from '@/api/RestModels/choice';
import LoadingState from '@/api/LoadingState';
import ACTIONS from '@/store/storeActions';

export default class Choice {
  constructor(store, i18n) {
    this.$store = store;
    this.$i18n = i18n;
  }

  async getChoice() {
    await this.$store.dispatch(ACTIONS.CURRENT_CHOICE, { loadingState: LoadingState.LOADING });
    try {
      const { payload, type } = await ChoiceRestModel.getChoice();
      if (type === 'RESULT') {
        await this.$store.dispatch(ACTIONS.RESULT, { payload, loadingState: LoadingState.LOADED });
      } else {
        await this.$store.dispatch(ACTIONS.CURRENT_CHOICE, { payload, loadingState: LoadingState.LOADED });
      }
      return { payload, type }
    } catch (err) {
      await this.$store.dispatch(ACTIONS.CURRENT_CHOICE, { loadingState: LoadingState.FAILED });
      return err;
    }
  }

  async setChoice(pload) {
    await this.$store.dispatch(ACTIONS.CURRENT_CHOICE, { loadingState: LoadingState.LOADING });
    try {
      const { payload, type } = await ChoiceRestModel.setChoice( pload );
      if (type === 'RESULT') {
        await this.$store.dispatch(ACTIONS.RESULT, { payload, loadingState: LoadingState.LOADED });
      } else {
        await this.$store.dispatch(ACTIONS.CURRENT_CHOICE, { payload, loadingState: LoadingState.LOADED });
      }
      return { payload, type };
    } catch (err) {
      await this.$store.dispatch(ACTIONS.CURRENT_CHOICE, { loadingState: LoadingState.FAILED });
      return err;
    }
  }

  async getResult() {
    await this.$store.dispatch(ACTIONS.RESULT, { loadingState: LoadingState.LOADING });
    try {
      const { payload } = await ChoiceRestModel.getResult();
      await this.$store.dispatch(ACTIONS.RESULT, { payload, loadingState: LoadingState.LOADED });
      return payload;
    } catch (err) {
      await this.$store.dispatch(ACTIONS.RESULT, { loadingState: LoadingState.FAILED });
      return err;
    }
  }

  async getResults(runId) {
    await this.$store.dispatch(ACTIONS.RESULT, { payload: undefined, loadingState: LoadingState.LOADING });
    try {
      const { payload } = await ChoiceRestModel.getResults(runId);
      await this.$store.dispatch(ACTIONS.RESULT, { payload, loadingState: LoadingState.LOADED });
      return payload;
    } catch ( err ) {
      await this.$store.dispatch(ACTIONS.RESULT, { loadingState: LoadingState.FAILED });
      return err;
    }
  }
}
