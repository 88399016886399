<template>
  <v-list-item v-if='show' router :to='getRoute'>
    <v-list-item-icon v-if='value.iconPosition !== "right"'>
      <v-icon :class='value.classes'>{{ value.icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-title>{{ $t(`Navigation.Menu.${value.key}`) }}</v-list-item-title>

    <v-list-item-icon v-if='value.iconPosition === "right"'>
      <v-icon :class='value.classes'>{{ value.icon }}</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
export default {
  name: 'NavigationItem',
  props: {
    /** @type {import('vue').PropOptions<NavigationItem>} */
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    show() {
      // might later be changed to include permissions
      return true
    },
    getRoute() {
      return { name: this.value.route }
    }
  }
}
</script>

<style scoped>

</style>
