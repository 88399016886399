<template>
  <ProjectFormWrapper :title='$ct("title")'>
    <v-row>
      <v-col cols='12'>
        <v-text-field
          :label='$ct("comparisonCriterium")'
          :value='textVars.comparisonCriterium'
          @change='(val) => $emit("change", { field: "projectTextVariables", value: { ...textVars, comparisonCriterium: val } })'
        />
      </v-col>
      <v-col cols='12'>
        <v-text-field
          :label='$ct("comparisonText")'
          :value='textVars.comparisonText'
          @change='(val) => $emit("change", { field: "projectTextVariables", value: { ...textVars, comparisonText: val } })'
        />
      </v-col>
      <v-col cols='12'>
        <v-text-field
          :label='$ct("requestSubjectText")'
          :value='textVars.requestSubjectText'
          @change='(val) => $emit("change", { field: "projectTextVariables", value: { ...textVars, requestSubjectText: val } })'
        />
      </v-col>
      <v-col cols='12'>
        <v-text-field
          :label='$ct("scaleText")'
          :value='textVars.scaleText'
          @change='(val) => $emit("change", { field: "projectTextVariables", value: { ...textVars, scaleText: val } })'
        />
      </v-col>
    </v-row>
  </ProjectFormWrapper>
</template>

<script>
import ProjectFormWrapper from '@/components/Projects/Form/ProjectFormWrapper.vue';

export default {
  name: 'ProjectChoiceSettings',
  components: { ProjectFormWrapper },
  props: {
    /** @type ProjectTextVariablesObj */
    textVars: {
      type: Object,
      default: () => ({
        comparisonCriterium: '',
        comparisonText: '',
        requestSubjectText: '',
        scaleText: ''
      })
    }
  }
}
</script>

<style scoped>

</style>
