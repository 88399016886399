import ACTIONS from '@/store/storeActions';
import { saveOrUpdateState } from '@/libs/storeHelper';

const saveOrUpdate = (state, payload) => saveOrUpdateState(state, 'projects', payload);

const mutations = {
  [ACTIONS.SET_PROJECTS]: (state, { type, loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) state.projects = payload;
  },
  [ACTIONS.SET_CURRENT_PROJECT]: (state, payload) => {
    state.current = payload;
  },
  [ACTIONS.CREATE_PROJECT]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) {
      state.current = payload;
      saveOrUpdate(state, payload)
    }
  },
  [ACTIONS.UPDATE_PROJECT]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) saveOrUpdate(state, payload)
  },
  [ACTIONS.DELETE_PROJECT]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
  },
  [ACTIONS.UPDATE_PROJECT_PASSWORD]: (state, { loadingState, payload }) => {
    state.loadingState = loadingState;
    if (payload) {
      state.current = { ...state.current, password: payload.password }
      state.projects.map((p) => {
        if (p.id === payload.id) {
          return { ...p, password: payload.password }
        }
        return p
      })
    }
  },
}

export default mutations;
