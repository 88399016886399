import BaseModel from '@/api/RestModels/baseModel';
import api from './client';

class ResultRestModel extends BaseModel {
  path = 'result';

  get = async () => api.get(this.path);

  getResults = async (id) => api.get(`${this.path}/${id}`);


}

export default new ResultRestModel();
