<template>
  <div>
    <div ref='piechart'></div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import Plotly from 'plotly.js-dist';
// eslint-disable-next-line import/no-extraneous-dependencies
import plotlyLocaleDE from 'plotly.js-locales/de';
import BusinessLogicResults from '@/libs/businessLogicResults';
import { pairs } from '@/libs/pairsImageLibrary';

Plotly.register(plotlyLocaleDE);

export default {
  name: 'PieChart',
  components: {},
  props: {
    values: Array,
    results: {
      type: Array,
      required: true
    },
    images: {
      type: Array,
    },
    isImageResult: {
      required: true
    }
  },
  data: () => ({
    logic: undefined
  }),
  methods: {
    generatePlot() {
      const self = this;
      const data = [];
      const { result } = this.logic.mapResults(this.results);
      let labels; let values; let marker = {};
      if (this.isImageResult) {
        result.sort((a, b) => a.riasecProperties.chartOrder - b.riasecProperties.chartOrder)
        labels = result.map((x) => x.choice);
        values = result.map((x) => x.result);
        marker.colors = result.map((x) => x.colorCode);
      } else {
        labels = result.map((x) => x.choice);
        values = result.map((x) => x.result);
        marker.colors = result.map((x) => x.colorCode);
      }

      data.push({
        type: 'pie',
        textinfo: 'percent',
        textposition: 'outside',
        hoverinfo: 'none',
        sort: false,
        labels,
        values,
        marker,
      });

      Plotly.newPlot(
        this.$refs.piechart,
        data,
        {
          showlegend: false,
          plot_bgcolor: 'rgba(255,255,255,0)',
          paper_bgcolor: 'rgba(255,255,255,0)',
          autosize: true
        },
        {
          displayModeBar: false,
          responsive: true,
        }
      )
    }
  },
  created() {
    Plotly.setPlotConfig({ locale: this.$i18n.locale })
  },
  mounted() {
    this.logic = new BusinessLogicResults(this);
    this.generatePlot();
  }
}
</script>

<style scoped>

</style>
