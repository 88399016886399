import ResultRestModel from '@/api/RestModels/result';
import LoadingState from '@/api/LoadingState';
import ACTIONS from '@/store/storeActions';

export default class Result {
  constructor(store, i18n) {
    this.$store = store;
    this.$i18n = i18n;
  }

  async get(persist = false) {
    if (persist) await this.$store.dispatch(ACTIONS.RESULT, { loadingState: LoadingState.LOADING });
    try {
      const { payload } = await ResultRestModel.get();
      return payload
    } catch (err) {
      return err;
    }
  }

  async getResults(id, persist = false) {
    if (persist) await this.$store.dispatch(ACTIONS.RESULT, { loadingState: LoadingState.LOADING });
    try {
      const { payload } = await ResultRestModel.getResults(id);
      return payload
    } catch (err) {
      return err;
    }
  }
}
