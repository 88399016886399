<template>
  <ProjectFormWrapper :title='$ct("title")' :valid='valid' @validate='(val) => $emit("validate", val)'>
    <v-row>
      <v-col cols='6'>
        <v-text-field
            :label='$ct("projectTitle")'
            :value='title'
            @change='(val) => $emit("change", {field: "title", value: val})'
            :placeholder='$ct("projectTitle")'/>
      </v-col>
      <v-col cols='3'>
        <v-select
          :disabled='StatusDisabled'
            :label='$ct("projectStatus")'
            :items='getStatusElements()'
            item-value='value'
            :value='projectStatus'
            @change='(val) => $emit("change", {field: "projectStatus", value: val})'
        />
      </v-col>
      <v-col cols='3'>
        <v-text-field :label='$ct("projectVersion")' :value='projectVersion' disabled/>
      </v-col>
      <v-col cols='12'>
        <v-row>
          <v-col class='grow'>
            <PasswordField
                :on-change='(val) => $emit("change", {field: "password", value: val})'
                :value='password'
                :label='$ct("password")'
                :prepend-icon=false
                :rules='hasPasswordRule ? [inputRules.passwordSafety] : []'
                :disabled='PasswordSet'
            />
          </v-col>
          <v-col v-if='PasswordSet'>
            <v-row align='end' justify='end'>
              <v-col class='flex-grow-0'>
                <v-tooltip top>
                  <template v-slot:activator='{on, attrs}'>
                    <v-btn
                        color='primary'
                        v-bind='attrs'
                        v-on='on'
                        :disabled='!password || password.length <= 0'
                        @click='togglePasswordEdit'>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <GenericModal :open='passwordEdit' :outside-click='togglePasswordEdit' :handle-close='togglePasswordEdit' :handle-save='handlePasswordEdit' width='500'>
                      <template v-slot:title>{{$ct('editPassword')}}</template>
                      <template v-slot:content>
                        <v-form ref='password'>
                          <password-field
                              :on-change='(val) => newPassword.old = val'
                              :value='newPassword.old'
                              :label='$ct("oldPassword")'
                              :rules='[inputRules.required]'
                              required/>
                          <password-field
                              :label='$ct("newPassword")'
                              :on-change='(val) => newPassword.new = val'
                              :value='newPassword.new'
                              :rules='[inputRules.required, inputRules.passwordSafety]'
                              required/>
                          <password-field
                              :label='$ct("confirm")'
                              :value='newPassword.confirm'
                              :on-change='(val) => newPassword.confirm = val'
                              :rules='[inputRules.required, additionalRules.passwordIdentical]'
                              required
                              :key-up-action='handlePasswordEdit'
                          />
                        </v-form>
                      </template>
                    </GenericModal>
                  </template>
                  <span>{{$ct('changePassword')}}</span>
                </v-tooltip>
              </v-col>
              <v-col class='flex-grow-0'>
                <v-tooltip top>
                  <template v-slot:activator='{on, attrs}'>
                    <v-btn
                        color='secondary'
                        v-bind='attrs'
                        v-on='on'
                        :disabled='!password || password.length <= 0'
                        @click='togglePasswordDelete()'
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <GenericModal :open='passwordDelete' :outside-click='togglePasswordDelete' :handle-close='togglePasswordDelete' :handle-save='handlePasswordDelete' width='500'>
                      <template v-slot:title>{{ $ct('deletePassword') }}</template>
                      <template v-slot:content>
                        <v-form ref='deletePassword'>
                          <password-field
                              :value='deletePassword.old'
                              :on-change='(val) => deletePassword.old = val'
                              :label='$ct("oldPassword")'
                              :rules='[inputRules.required]'
                              required/>
                          <password-field
                              :label='$ct("confirm")'
                              :value='deletePassword.confirm'
                              :on-change='(val) => deletePassword.confirm = val'
                              :rules='[inputRules.required, additionalRules.deletePasswordIdentical]'
                              required
                              :key-up-action='handlePasswordDelete'
                          />
                        </v-form>
                      </template>
                    </GenericModal>
                  </template>
                  <span>{{$ct('removePassword')}}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols='6'>
        <v-select :label='$ct("participantType")' :items='participantTypeOptions()' :value='participantType' @change='(val) => $emit("change", {field: "participantType", value: val})'/>
      </v-col>
      <v-col cols='6' class='d-flex flex-row'>
        <v-text-field
          :disabled='participantType === "ANONYMOUS"'
          type='number'
          step='1'
          :max='maxParticipants'
          :label='$ct("participants")'
          :rules='[additionalRules.maxParticipants]'
          :value='participantType === "ANONYMOUS" ? "" : participants'
          @change='(val) => val <= 100 ? $emit("change", {field: "participants", value: parseInt(val)}) : ""'/>
      </v-col>
      <v-col cols='12'>
        <v-select :label='$ct("resultsViewTitle")' :items='resultsViewOptions()' :value='resultsView' @change='(val) => $emit("change", { field: "resultsView", value: val})'/>
      </v-col>
    </v-row>
  </ProjectFormWrapper>
</template>

<script>
import ProjectFormWrapper from '@/components/Projects/Form/ProjectFormWrapper.vue';
import inputRules from '@/libs/inputRules';
import PasswordField from '@/components/Generics/PasswordField.vue';
import GenericModal from '@/components/Modals/GenericModal.vue';
import { ProjectStatus, ResultsViews } from '@/libs/constants';
import { mapState } from 'vuex';

export default {
  name: 'ProjectSettings',
  components: {
    PasswordField,
    GenericModal,
    ProjectFormWrapper
  },
  props: {
    valid: {
      required: true
    },
    project: {
      type: Object
    },
    title: {
      type: String
    },
    password: {
      type: String,
      default: ''
    },
    passwordSet: {
      type: Boolean,
      default: false
    },
    participants: {
      type: Number
    },
    participantType: {
      type: String
    },
    projectStatus: {
      type: String,
      default: ProjectStatus.DRAFT
    },
    projectVersion: {
      type: Number
    },
    resultsView: {
      type: String
    }
  },
  data() {
    return {
      inputRules: inputRules(this),
      showPassword: false,
      passwordEdit: false,
      passwordDelete: false,
      newPassword: {
        old: undefined,
        new: undefined,
        confirm: undefined
      },
      maxParticipants: 100,
      deletePassword: {
        old: undefined,
        confirm: undefined
      },
      additionalRules: {
        passwordIdentical: (value) => value === this.newPassword.new || this.$t('Rules.password_notIdentical'),
        deletePasswordIdentical: (value) => value === this.deletePassword.old || this.$t('Rules.password_notIdentical'),
        maxParticipants: (value) => value <= this.maxParticipants || this.$t('Rules.max_participants_exceeded', { max: this.maxParticipants }),
      },
    }
  },
  computed: {
    ...mapState({
      current: (state) => state.projects.current
    }),
    hasPasswordRule() {
      const trimmed = this.password.trim().length
      const matched = this.password.trim().match(/x/gm);
      return trimmed > 0 && (matched && trimmed !== matched.length)
    },
    PasswordSet() {
      return typeof this.current.password === 'string' && this.current.password.trim().length > 0
    },
    StatusDisabled() {
      return this.current.projectStatus === ProjectStatus.DELETED
    },
    ProjectStatus() {
      return ProjectStatus
    },
    emptyNewPassword() {
      return {
        old: undefined,
        new: undefined,
        confirm: undefined
      }
    },
    emptyDeletePassword() {
      return {
        old: undefined,
        confirm: undefined
      }
    }
  },
  methods: {
    getStatusElements() {
      let elements = [
        {
          value: ProjectStatus.DRAFT,
          text: this.$ct('projectStatusOptions.DRAFT'),
          disabled: this.StatusDisabled
        },
        {
          value: ProjectStatus.ACTIVE,
          text: this.$ct('projectStatusOptions.ACTIVE'),
          disabled: this.StatusDisabled
        },
        {
          value: ProjectStatus.ARCHIVED,
          text: this.$ct('projectStatusOptions.ARCHIVED'),
          disabled: this.StatusDisabled
        }
      ]
      if ([ProjectStatus.DELETED].includes(this.current.projectStatus)) {
        elements.push(
          {
            value: ProjectStatus.DELETED,
            text: this.$ct('projectStatusOptions.DELETED'),
            disabled: true
          }
        )
      }
      return elements;
    },
    togglePasswordEdit() {
      this.passwordEdit = !this.passwordEdit;
      if (!this.passwordEdit) this.newPassword = Object.assign(this.newPassword, this.emptyNewPassword)
    },
    togglePasswordDelete() {
      this.passwordDelete = !this.passwordDelete;
      if (!this.passwordDelete) this.deletePassword = Object.assign(this.deletePassword, this.emptyDeletePassword)
    },
    async handlePasswordEdit() {
      const self = this;
      if (this.$refs.password.validate()) {
        await self.$api.project.updatePassword({ ProjectId: this.$route.params.id, OldPassword: this.newPassword.old, NewPassword: this.newPassword.new })
        this.newPassword = Object.assign(self.newPassword, self.emptyNewPassword)
        this.togglePasswordEdit()
      }
    },
    async handlePasswordDelete() {
      const self = this;
      if (this.$refs.deletePassword.validate()) {
        await self.$api.project.updatePassword({ ProjectId: this.$route.params.id, OldPassword: this.deletePassword.old })
        this.$emit('passwordDelete')
        this.deletePassword = Object.assign(self.deletePassword, self.emptyDeletePassword)
        this.togglePasswordDelete()
      }
    },
    projectStatusOptions() {
      return [
        {
          text: this.$ct('projectStatusOptions.DRAFT'),
          value: 'DRAFT'
        },
        {
          text: this.$ct('projectStatusOptions.ACTIVE'),
          value: 'ACTIVE'
        },
        {
          text: this.$ct('projectStatusOptions.ARCHIVED'),
          value: 'ARCHIVED'
        },
        {
          text: this.$ct('projectStatusOptions.DELETED'),
          value: 'DELETED'
        }
      ]
    },
    participantTypeOptions() {
      return [
        {
          text: this.$ct('participantTypeOptions.ANONYMOUS'),
          value: 'ANONYMOUS'
        },
        {
          text: this.$ct('participantTypeOptions.TOKEN'),
          value: 'TOKEN'
        },
        {
          text: this.$ct('participantTypeOptions.USER'),
          value: 'USER',
          disabled: true
        }
      ]
    },
    resultsViewOptions() {
      return [
        {
          text: this.$ct(`resultsViewOptions.${ResultsViews.DEFAULT}`),
          value: ResultsViews.DEFAULT,
        },
        {
          text: this.$ct(`resultsViewOptions.${ResultsViews.RIASEC}`),
          value: ResultsViews.RIASEC
        }
      ]
    }
  },
}
</script>

<style scoped>

</style>
