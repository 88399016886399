<template>
  <div>
    <div ref='radarchart'></div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import Plotly from 'plotly.js-dist';
// eslint-disable-next-line import/no-extraneous-dependencies
import plotlyLocaleDE from 'plotly.js-locales/de';
import BusinessLogicResults from '@/libs/businessLogicResults';
import { pairs } from '@/libs/pairsImageLibrary';

Plotly.register(plotlyLocaleDE);

export default {
  name: 'RadarChart',
  components: {},
  props: {
    values: Array,
    results: Array,
    isImageResult: Boolean,
    multi: Boolean,
    data: Array,
    showLegend: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    logic: undefined
  }),
  methods: {
    generatePlot() {
      const self = this;
      let data = [];
      if (!this.data || !Array.isArray(this.data)) {
        const { result, max } = this.logic.mapResults(this.results);
        let r;
        let theta;
        if (this.isImageResult) {
          // in v0.0.10 the results contain the order for the radar chart thus making the pairs.map irrelevant
          // todo -> change pairs to new imageLibrary
          result.sort((a, b) => a.riasecProperties.chartOrder - b.riasecProperties.chartOrder);
          theta = [...result.map((x) => this.$t(`imageHelpers.chartTicks.${(x.choice || x.choiceKey)}`)), this.$t(`imageHelpers.chartTicks.${(result[0].choice || result[0].choiceKey)}`)]
          r = [...result.map((x) => x.result), result[0].result];
          // const order = pairs.map((i) => ({
          //   ...result.find((x) => x.choice === i.choice),
          //   colorCode: i.colorCode
          // }))
          // theta = [...order.map((x) => this.$t(`imageHelpers.chartTicks.${x.choice}`)), this.$t(`imageHelpers.chartTicks.${order[0].choice}`)];
          // r = [...order.map((x) => x.result), order[0].result]
        } else {
          theta = [...result.map((x) => x.choice), result[0].choice];
          r = [...result.map((x) => x.result), result[0].result]
        }

        data.push({
          type: 'scatterpolar',
          textinfo: 'percent',
          textposition: 'outside',
          hoverinfo: 'none',
          sort: false,
          theta,
          r,
          fill: 'toself',
        });
      } else {
        data = this.data;
      }
      Plotly.newPlot(
        this.$refs.radarchart,
        data,
        {
          polar: {
            radialaxis: {
              visible: true,
              //range: [0, Math.ceil(max * 10) / 10]
            }
          },
          showlegend: this.showLegend,
          // autosize: true
        },
        {
          displayModeBar: false,
          staticPlot: true
        }
      )
    }
  },
  created() {
    Plotly.setPlotConfig({ locale: this.$i18n.locale })
  },
  mounted() {
    this.logic = new BusinessLogicResults(this);
    this.generatePlot();
  }
}
</script>

<style scoped>

</style>
