<template>
  <v-navigation-drawer app :value='drawer' @change='(val) => $emit("setDrawer", val)' width='400'>
    <v-list-item class='py-2'>
      <v-list-item-avatar>
        <v-icon class='grey lighten-1 white--text'>mdi-account</v-icon>
      </v-list-item-avatar>
      <v-icon class='primary--text' @click='$emit("setModal", true)'>mdi-logout</v-icon>
    </v-list-item>
    <template v-if='isAdministrator'>
      <v-divider/>
      <NavigationMenu/>
    </template>
  </v-navigation-drawer>
</template>

<script>
import NavigationMenu from '@/components/Navigation/NavigationMenu.vue';
import { mapState } from 'vuex';
import { LoginPurpose } from '@/api/RestModels/login';

export default {
  name: 'NavigationDrawer',
  components: {
    NavigationMenu
  },
  computed: {
    ...mapState({
      purpose: (state) => state.login.purpose
    }),
    LoginPurpose() {
      return LoginPurpose
    },
    isAdministrator() {
      return this.purpose === LoginPurpose.ADMINISTRATION
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
