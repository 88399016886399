<template>
  <v-list-group
    :prepend-icon='value.icon'
    :sub-group='nested'
    :no-action='nested'>
    <template v-slot:activator>
      <v-list-item-content v-if='nested'>
        <v-list-item-title>{{ $t(`Navigation.Menu.${value.key}`) }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-title v-else>{{ $t(`Navigation.Menu.${value.key}`) }}</v-list-item-title>
    </template>
    <v-list>
      <template v-for='item in value.items'>
        <NavigationList v-if='item.items' :key='item.key' :value='item'/>
        <NavigationItem v-else :key='item.key' :value='item'/>
      </template>
    </v-list>
  </v-list-group>
</template>

<script>
import NavigationItem from '@/components/Navigation/NavigationItem.vue';

export default {
  name: 'NavigationList',
  components: {
    NavigationItem
  },
  props: {
    /** @type NavigationItem */
    value: {
      type: Object,
      required: true
    },
    nested: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
