import Vue from 'vue'
import Login from '@/api/actions/login'
import Project from '@/api/actions/project';
import Run from '@/api/actions/run';
import Choice from '@/api/actions/choice'
import Users from '@/api/actions/users';
import Scale from '@/api/actions/scale';
import ImageLibrary from '@/api/actions/imageLibrary';
import Result from '@/api/actions/result';

Vue.mixin({
  beforeCreate() {
    const options = this.$options;
    if (options.api) this.$api = options.api;
    else if (options.parent && options.parent.$api) this.$api = options.parent.$api
  }
})

class Api {
  constructor(store, i18n) {
    this.store = store;
    this.i18n = i18n;
    // add RestModels here for all Requests
    this.login = new Login(store, i18n);
    this.project = new Project(store, i18n);
    this.run = new Run(store, i18n);
    this.imageLibrary = new ImageLibrary(store, i18n)
    this.result = new Result(store, i18n);
    this.choice = new Choice(store, i18n)
    this.users = new Users(store, i18n);
    this.scales = new Scale(store, i18n);
  }
}

export default Api
